import React, { useState } from 'react';
import { format } from 'date-fns';
import { InviteType } from '../../../store/clients/clients.types';
import Button from '../../../components/Button';
import * as S from './invite-item';
import accepted from '../../../images/accepted.svg';
import plane from '../../../images/plane-red.svg';
import warning from '../../../images/warning-orange.svg';
import undo from '../../../images/undo.svg'
import Modal from '../../../components/Modal';
import paperPlane from '../../../images/paper-plane.svg';
import useGlobal from '../../../store';
import { resendInvitation, revokeInvitation } from '../../../store/members/members.actions';
import acceptedWhite from '../../../images/accepted-white.svg';
import { toastUtil } from '../../../utils/toast.util';
import { ReactSVG } from 'react-svg';
import SimpleModal from '../../../components/Modal/SimpleModal/SimpleModal';

interface Props {
  invite: InviteType;
  fetchProjects: () => void;
}

const status = {
  ACCEPTED: {
    label: 'Accepted',
    icon: accepted
  },
  NOT_CONFIRMED: {
    label: 'No confirmation',
    icon: plane
  },
  EXPIRED: {
    label: 'Expired',
    icon: warning
  },
  REVOKED: {
    label: 'Revoked',
    icon: undo
  }
};

const InviteItem = ({
  invite: { sentDate, state, client, company, project, invitationEmailId }, fetchProjects
}: Props) => {
  const [userName] = useGlobal((state) => state.auth.currentUser?.firstName);
  const [isResended, setIsResended] = useState<boolean>(false);

  const [showResendModal, setShowResendModal] = useState<boolean>(false);
  const [showRevokeModal, setShowRevokeModal] = useState<boolean>(false);

  const open = (action: string) => {
    if (action === 'RESEND') setShowResendModal(true);
    if (action === 'REVOKE') setShowRevokeModal(true);
  }

  const close = () => {
    if (showResendModal) setShowResendModal(false);
    if (showRevokeModal) setShowRevokeModal(false);
  };

  const resendInv = async () => {
    try {
      await resendInvitation(invitationEmailId);
      setIsResended(true);
      close();
      toastUtil(
        'success',
        `Great! Invitation has been successfully sent to ${client.firstName} ${client.lastName}`,
        'extra_small'
      );
    } catch (error) {
      console.warn(error);
    }
  };

  const revokeInv = async () => {
    try {
      await revokeInvitation(invitationEmailId);
      fetchProjects();
      close();
      toastUtil(
        'success',
        `Invitation has been successfully revoked`,
        'extra_small'
      );
    } catch (error) {
      console.warn(error);
    }
  }

  const displayRevoke = state === 'NOT_CONFIRMED' || state === 'ACCEPTED';

  return (
    <S.wrapper isResended={isResended} >
      <S.header>
        <span>
          <img src={status[state].icon} alt="" /> {status[state].label}
        </span>
        {format(new Date(sentDate.split(' ')[0]), 'dd.MM.yyyy')}
      </S.header>
      <S.body>
        {client.firstName} {client.lastName} - {company.name}
      </S.body>
      <S.footer resent={isResended} revoke={displayRevoke}>
        {project?.name && <p>{project.name}</p>}
        {state === 'EXPIRED' && (
          isResended ?
            (
              <span>
                <ReactSVG src={acceptedWhite} />
                Resent
              </span>
            ) : (
              <Button
                small
                onClick={isResended ? null : () => { open('RESEND') }}
                secondary={isResended}
              >
                Resend
              </Button>
            )
        )}
        {displayRevoke &&
          <Button
            small
            onClick={() => { open('REVOKE') }}
          >
            Revoke
          </Button>
        }
      </S.footer>
      <Modal isOpen={showResendModal} onDismiss={close}>
        <S.modalWrapper>
          <S.title>
            <ReactSVG src={paperPlane} />
            Hi {userName}, do you want to resend invitation to this client?
          </S.title>
          <S.modalContent>
            <S.modalItem>
              <p>Company Name</p>
              <p>{company?.name}</p>
            </S.modalItem>
            {project && (
              <S.modalItem>
                <p>Project Name</p>
                <p>{project?.name}</p>
              </S.modalItem>
            )}
            <S.modalItem>
              <div>
                <p>Client</p>
                <p>
                  {client?.firstName} {client?.lastName}
                </p>
              </div>
              <div>
                <p>Email address</p>
                <p>{client?.email}</p>
              </div>
            </S.modalItem>
          </S.modalContent>
          <S.buttonContainer>
            <Button onClick={close} secondary>
              No, next time
            </Button>
            <Button onClick={resendInv}>Yes, send invitation</Button>
          </S.buttonContainer>
        </S.modalWrapper>
      </Modal>
      <SimpleModal
        isOpen={showRevokeModal}
        onDismiss={close}
        confirm={revokeInv}
      >
        <p>Are you sure you want to revoke client invitation from {project.name}?</p>
      </SimpleModal>
    </S.wrapper>
  );
};

export default InviteItem;
