import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    ${tw`mb-24 sm:mb-60 mx-37`};

    h2 {
      ${tw`text-30 text-green leading-none mb-25`}
    }

    h3 {
      ${tw`text-textBlack text-24 leading-none mb-18 font-bold`};
    }

    @media print {
      page-break-inside: auto;
      -webkit-page-break-inside: auto;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`mx-16`}

      h2 {
        ${tw`text-18 leading-24 mb-19 font-semibold`}
      }

      h3 {
        ${tw`text-14 mb-8`}
      }
    }
  `
]);

export const phaseList = styled.div(() => [
  css`
    ${tw`py-5`}

    > div {
      padding-top: 10px;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`grid grid-cols-2 gap-16`}

      div {
        padding-top: 0;
      }
    }
  `
]);

export const phaseItem = styled.div(() => [
  css`
    ${tw`flex flex-col py-4 px-10 mb-15 bg-white`}

    @media print {
      page-break-inside: avoid;
      -webkit-page-break-inside: avoid;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`mb-0 pt-0`}
    }
  `
]);

export const serviceTitle = styled.div(() => [
  css`
    ${tw`grid grid-cols-2 gap-0 border-b border-gray9 w-full`}
  `
]);

export const phaseTitle = styled.h4(() => [
  css`
    ${tw`font-semibold text-18 text-textBlack  
      `}
    margin-bottom: 1px;

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`text-12 -mb-5`}
    }
  `
]);

export const deliverableTitle = styled.h4(() => [
  css`
    ${tw`font-semibold text-16 text-textBlack  
      `}
    margin-bottom: 1px;

    @media screen and (max-width: 575px) and (min-width: 320px) {
      display: none;
    }
  `
]);

export const serviceItem = styled.div(() => [
  css`
    ${tw`relative flex flex-col sm:flex-row items-start py-5`}

    p {
      ${tw`relative pr-70 pt-3 sm:pr-0 sm:static text-10 sm:text-18 text-textBlack font-semibold w-full sm:max-w-1/2 sm:min-w-1/2 break-words leading-20`}
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      p {
        ${tw`leading-14`}
      }
    }
  `
]);

export const methodColors = styled.span(() => [
  css`
    ${tw`absolute w-px2 block
         bg-darkBlue`}
    left: -1rem;
    height: 2.5rem;
    top: 5px;

    @media screen and (max-width: 575px) and (min-width: 320px) {
      height: 100%;
      top: 0;
    }
  `
]);

export const deliverableItem = styled.div(() => [
  css`
    ${tw`flex items-center text-10 text-textBlack font-normal sm:pr-70 sm:text-14`}
    min-width: 50%;
  `
]);

export const personDayItem = styled.span(() => [
  css`
    ${tw`ml-auto text-right text-14 text-textBlack opacity-50 font-semibold absolute`}
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    max-width: 7rem;
    min-width: 6rem;

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`text-10`}
    }
  `
]);

export const itemFooter = styled.div(() => [
  css`
    ${tw`flex justify-end items-center border-t border-gray9
        w-full
        font-semibold text-16 text-textBlack mt-auto`}

    svg {
      height: auto;
      width: 1.6rem;
      margin-right: 5px;

      path {
        ${tw`fill-black`}
      }
      }
    }
          @media screen and (max-width: 575px) and (min-width: 320px) {
        ${tw`text-12`}

        svg {
          width: 1.4rem;
          margin-right: 5px;
        }
  `
]);
