import React, { useState, useEffect } from 'react';
// import TopBar from '../../components/TopBar';
import { HeaderWrapper, HeaderTop } from '../../components/Header/StyledHeader';
import * as S from './create-method-page';
import { DropdownCbOption } from '../../types/util.types';
import { MemberRole, Complexity } from '../../store/method-service/types';
import { createMethod } from '../../store/method-service/actions';
import { toastUtil } from '../../utils/toast.util';
import Button from '../../components/Button';
import NameField from './NameField';
import MethodLevel from './MethodLevel';
import DisciplineField from './DisciplineField';
import PhaseField from './PhaseField';
import MemberField from './MemberField';
import NumberField from './NumberField';
import ComplexityField from './ComplexityField';
import CommentField from './CommentField';
import useGlobal from '../../store';
import { routePaths } from '../../utils/routes.util';

const CreateMethod = () => {
  const [, getDisciplinesRolesPhases] = useGlobal(
    () => null,
    (actions) => actions.getDisciplinesRolesPhases
  );

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [selectedLevel, setSelectedLevel] = useState<DropdownCbOption[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<DropdownCbOption[]>([]);
  const [selectedDisciplines, setSelectedDisciplines] = useState<
    DropdownCbOption[]
  >([]);
  const [selectedRoles, setSelectedRoles] = useState<MemberRole[]>([]);
  const [personDaysPmQm, setPersonDaysPmQm] = useState<string>('');
  const [duration, setDuration] = useState<string>('');
  const [standard, setStandard] = useState<Complexity>({
    type: 'STANDARD',
    personDays: '',
    price: ''
  });
  const [high, setHigh] = useState<Complexity>({
    type: 'HIGH',
    personDays: '',
    price: ''
  });
  const [extreme, setExtreme] = useState<Complexity>({
    type: 'EXTREME',
    personDays: '',
    price: ''
  });
  const [responsiveDesign, setResponsiveDesign] = useState<Complexity>({
    type: 'RESPONSIVE_DESIGN',
    personDays: '',
    price: ''
  });
  const [deliverables, setDeliverables] = useState<string>('');
  const [qualityMeasurements, setQualityMeasurements] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    getDisciplinesRolesPhases();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!personDaysPmQm || !selectedRoles.length) {
      setStandard({ ...standard, personDays: '' });
      setHigh({ ...high, personDays: '' });
      setExtreme({ ...extreme, personDays: '' });
      return;
    }

    let totalPd = selectedRoles.reduce((acc: number, role: MemberRole) => {
      return acc + Number(role.personDays);
    }, 0);
    totalPd += Number(personDaysPmQm);

    setStandard({ ...standard, personDays: totalPd + '' });
    setHigh({ ...high, personDays: (totalPd * 1.25).toFixed(2) + '' });
    setExtreme({ ...extreme, personDays: (totalPd * 1.5).toFixed(2) + '' });
    // eslint-disable-next-line
  }, [selectedRoles, personDaysPmQm]);

  const create = async () => {
    try {
      setSubmitting(true);

      const res = await createMethod(
        name,
        selectedLevel,
        selectedDisciplines,
        selectedPhase,
        selectedRoles,
        personDaysPmQm,
        duration,
        [standard, high, extreme, responsiveDesign],
        description,
        deliverables,
        qualityMeasurements
      );
      setSubmitting(false);
      toastUtil('success', res.data.meta.message);
      setName('');
      setSelectedLevel([]);
      setSelectedPhase([]);
      setSelectedDisciplines([]);
      setSelectedRoles([]);
      setPersonDaysPmQm('');
      setDuration('');
      setDeliverables('');
      setQualityMeasurements('');
      setDescription('');
      setStandard({
        type: 'STANDARD',
        personDays: '',
        price: ''
      });
      setHigh({
        type: 'HIGH',
        personDays: '',
        price: ''
      });
      setExtreme({
        type: 'EXTREME',
        personDays: '',
        price: ''
      });
      setResponsiveDesign({
        type: 'RESPONSIVE_DESIGN',
        personDays: '',
        price: ''
      });
    } catch (error) {
      setSubmitting(false);
    }
  };

  const isInvalid =
    !name ||
    !selectedLevel.length ||
    !selectedDisciplines.length ||
    !selectedPhase.length ||
    !selectedRoles.length ||
    !personDaysPmQm ||
    +personDaysPmQm === 0 ||
    !duration ||
    +duration === 0 ||
    !standard.personDays ||
    !high.personDays ||
    !extreme.personDays ||
    +standard.personDays === 0 ||
    +high.personDays === 0 ||
    +extreme.personDays === 0 ;
    

  return (
    <>
      {/* <TopBar /> */}
      <HeaderWrapper>
        <HeaderTop>
          <h2>Creating Method</h2>
          <Button small to={routePaths.SERVICES} secondary>
            Back to services
          </Button>
        </HeaderTop>
      </HeaderWrapper>
      <S.wrapper>
        <S.section>
          <S.title>Method ID</S.title>
          <NameField value={name} setValue={setName} label="Method name" />
          <MethodLevel
            selected={selectedLevel}
            onAdd={setSelectedLevel}
            label="Method level"
          />
          <DisciplineField
            selected={selectedDisciplines}
            onAdd={setSelectedDisciplines}
          />
          <PhaseField selected={selectedPhase} onAdd={setSelectedPhase} />
        </S.section>
        <S.section>
          <MemberField
            selectedRoles={selectedRoles}
            setSelectedRoles={setSelectedRoles}
            label="Member role and person days"
            title="Member"
          />
          <NumberField
            title="PM & QM"
            label="Person days"
            value={personDaysPmQm}
            setValue={setPersonDaysPmQm}
            minValidNumber={0.5}
          />
          <NumberField
            title="Method duration"
            label="Weeks"
            value={duration}
            setValue={setDuration}
            minValidNumber={0.1}
          />
        </S.section>
        <S.section>
          <S.title>Complexity</S.title>
          <ComplexityField
            complexity={standard}
            setComplexity={setStandard}
            title="Standard"
            disabled={!personDaysPmQm && standard.personDays === ''}
          />
          <ComplexityField
            complexity={high}
            setComplexity={setHigh}
            title="High"
            disabled={!personDaysPmQm && high.personDays === ''}
          />
          <ComplexityField
            complexity={extreme}
            setComplexity={setExtreme}
            title="Extreme"
            disabled={!personDaysPmQm && extreme.personDays === ''}
          />
          <ComplexityField
            complexity={responsiveDesign}
            setComplexity={setResponsiveDesign}
            title="Responsive design"
            disabled={!personDaysPmQm && extreme.personDays === ''}
          />
        </S.section>
        <S.section>
          <S.title>Comment field</S.title>
          <CommentField
            setValue={setDescription}
            value={description}
            placeholder="Description"
          />
          <CommentField
            setValue={setDeliverables}
            value={deliverables}
            placeholder="• Deliverables"
            bulletList
          />
          <CommentField
            setValue={setQualityMeasurements}
            value={qualityMeasurements}
            placeholder="• Quality measurements"
            bulletList
          />
        </S.section>
        <S.actions>
          <Button secondary disabled={submitting || isInvalid} onClick={create}>
            Create method
          </Button>
        </S.actions>
      </S.wrapper>
    </>
  );
};

export default CreateMethod;
