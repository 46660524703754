import React, { useState, useEffect } from 'react';
import { useFormik, FormikHelpers, FormikValues } from 'formik';
import useIsMounted from 'react-is-mounted-hook';
import { FormFieldType } from '../../../types/form.types';
import { handleFieldErrors } from '../../../utils/error.utils';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';
import useGlobal from '../../../store';
import validationSchema from './validation-schema';
import { formModel } from './form-model';
import PorfileAvatar from '../PorfileAvatar';
import { toastUtil } from '../../../utils/toast.util';
import {
  Form,
  InputTitle,
  InputWrapper,
  FormInputs
} from '../../../components/Form/StyledForm';
import * as S from './profile-form';

const ProfileForm = () => {
  const isMounted = useIsMounted();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [user, updateProfile] = useGlobal(
    (state) => state.auth.currentUser,
    (actions) => actions.updateProfile
  );

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email
    },
    validationSchema,
    onSubmit: async (
      values,
      formikHelpers: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        await updateProfile(
          values.firstName,
          values.lastName,
          values.email,
          user!.accountId,
          true
        );
        toastUtil(
          'success',
          'Your account information was successfully changed.'
        );
        if (isMounted()) setSubmitting(false);
      } catch (error) {
        if (isMounted()) setSubmitting(false);
        handleFieldErrors(error, formikHelpers.setFieldError);
      }
    }
  });

  useEffect(() => {
    if (user) {
      formik.setFieldValue('firstName', user.firstName);
      formik.setFieldValue('lastName', user.lastName);
      formik.setFieldValue('email', user.email);
    }
    // eslint-disable-next-line
  }, [user]);

  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <h3>Account Information</h3>

      {formModel.map((field: FormFieldType) => (
        <FormInputs line={field.line} key={field.name}>
          <InputTitle>{field.title}</InputTitle>
          <InputWrapper>
            <TextInput
              profile
              type={field.type}
              small
              touched={formik.touched[field.name]}
              error={formik.errors[field.name]}
              {...formik.getFieldProps(field.name)}
            />
          </InputWrapper>
        </FormInputs>
      ))}
      <FormInputs>
        <InputTitle alignTop>Your avatar</InputTitle>
        <InputWrapper>
          <PorfileAvatar />
        </InputWrapper>
      </FormInputs>
      <S.buttonContainer>
        <Button type="submit" disabled={!isValidForm || submitting}>
          Save changes
        </Button>
      </S.buttonContainer>
    </Form>
  );
};

export default ProfileForm;
