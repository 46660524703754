import React, {
  useState,
  useContext,
  ChangeEvent,
  useEffect,
  useRef
} from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import {
  ProjectServicePackages,
  ProjectMethodQualities,
  ProjectProgressCategories
} from '../../../store/projects/projects.types';
import {
  editMethod,
  saveMethodComment
} from '../../../store/projects/projects.actions';
import { colorPicker } from '../../../utils/general.utils';
import CbRadio from '../../../components/Inputs/CbRadio';
import { ProgressContext } from '../Progress';
import MethodDate from './MethodDate';
import MethodComplexity from './MethodComplexity';
// eslint-disable-next-line
import DeliverableField from '../Deliverables';
import MethodDays from './MethodDays';
import deleteIcon from '../../../images/delete.svg';
import saveIcon from '../../../images/icon-save.svg';
import { validatePd } from '../../../utils/personDays.utils';
import * as S from './phase-method';
import MethodComment from './MethodComment';
import MethodMembers from './MethodMembers';
interface Props {
  data: ProjectServicePackages;
  servicePackageId: number | null;
  onDelete: (methodId: number, serviceId: number | null) => void;
}

interface Params {
  id: string;
}

const PhaseMethod = ({
  data: {
    qualities: methodQualities,
    activated,
    name,
    id: methodId,
    startDate: methodStart,
    endDate: methodEnd,
    comment,
    memberRoles,
    initialDuration,
    disciplines,
    deliverables
  },
  servicePackageId,
  onDelete
}: Props) => {
  const parsedDeliverables = deliverables?.reduce(
    (acc: string, current: any) => {
      const line = `• ${current.name}\n`;
      acc += line;
      return acc;
    },
    ''
  );

  const isFirstRender = useRef(true);
  const qualitiesUpdated = useRef(false);
  const dateUpdated = useRef(false);
  const inputRef = useRef(null);
  const { id: projectId } = useParams<Params>();
  const { updateProject, isMemberRole } = useContext(ProgressContext);
  const [isActivated, setIsActivated] = useState<boolean>(activated!);
  const [startDate, setStartDate] = useState<undefined | Date>(
    methodStart ? new Date(methodStart) : undefined
  );
  const [endDate, setEndDate] = useState<undefined | Date>(
    methodEnd ? new Date(methodEnd) : undefined
  );
  const [qualities, setQualities] = useState<ProjectMethodQualities[]>(
    methodQualities!
  );
  // eslint-disable-next-line
  const [saved, setSaved] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>(!!comment ? comment.content : '');
  const [canSave, setCanSave] = useState<boolean>(false);
  // eslint-disable-next-line
  const [methodDeliverables, setMethodDeliverables] = useState<string>(parsedDeliverables!);
  const [methodName, setMethodName] = useState<string>(name);
  const [showInput, setShowInput] = useState<boolean>(false);

  const onMethodClick = () => {
    setShowInput(true);
  };

  const complexity = qualities.find((q: ProjectMethodQualities) => q.picked)!;

  useOnClickOutside(inputRef, () => {
    setShowInput(false);
  });

  useEffect(() => {
    if (
      isFirstRender.current ||
      qualitiesUpdated.current ||
      dateUpdated.current
    ) {
      qualitiesUpdated.current = false;
      dateUpdated.current = false;
      return;
    }
    setCanSave(true);
    if (!methodName || +complexity.personDays === 0) setCanSave(false);
    if (!methodDeliverables) setMethodDeliverables(methodDeliverables);
  }, [
    isActivated,
    methodDeliverables,
    qualities,
    commentText,
    methodName,
    startDate,
    endDate,
    complexity
  ]);

  useEffect(() => {
    if (isFirstRender.current) return;
    dateUpdated.current = true;
    setStartDate(methodStart ? new Date(methodStart) : undefined);
    setEndDate(methodEnd ? new Date(methodEnd) : undefined);
  }, [methodStart, methodEnd]);

  useEffect(() => {
    if (!isFirstRender.current) qualitiesUpdated.current = true;
    setQualities(methodQualities!);
  }, [methodQualities]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (showInput && inputRef.current) inputRef.current!.select();
    if (!showInput && !methodName.trim()) setMethodName(name);
    // eslint-disable-next-line
  }, [showInput]);

  const handleComment = (val: string) => {
    setCommentText(val);
  };

  const updateDate = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const onEditMethod = () => {
    return editMethod(projectId, methodId, {
      qualities,
      isActivated,
      name: methodName,
      deliverables: methodDeliverables,
      startDate: startDate ? format(startDate, 'yyyy-MM-dd') : '',
      endDate: endDate ? format(endDate, 'yyyy-MM-dd') : ''
    });
  };

  const onSaveComment = () => {
    if (commentText !== '') {
      return saveMethodComment(projectId, methodId, commentText);
    }
    return false;
  };

  const onSave = async () => {
    if (!canSave) return;
    try {
      const editData = await onEditMethod();
      const commentData = await onSaveComment();
      const dataObject = { ...editData, ...commentData };
      updateProject(dataObject);
      setCanSave(false);
      setShowInput(false);
      if (parsedDeliverables !== methodDeliverables) setSaved(true);
    } catch (err) {
      console.warn(err);
    }
  };

  const onPickQuality = (selected: ProjectMethodQualities) => {
    const updatedQualities: ProjectMethodQualities[] = qualities.map(
      (q: ProjectMethodQualities) => ({
        ...q,
        picked: selected.originalQualityId === q.originalQualityId
      })
    );
    setQualities(updatedQualities);
  };

  const onUpdatePersonDays = (evt: ChangeEvent<HTMLInputElement>) => {
    if (!validatePd(evt.target.value)) return;
    const updatedQualities: ProjectMethodQualities[] = qualities.map(
      (q: ProjectMethodQualities) => ({
        ...q,
        personDays: q.picked ? evt.target.value : q.personDays
      })
    );
    setQualities(updatedQualities);
  };

  const updateMethodName = (evt: ChangeEvent<HTMLInputElement>) => {
    setMethodName(evt.target.value);
  };

  return (
    <>
      <S.wrapper disabled={isMemberRole ? 1 : 0}>
        {name && !showInput ? (
          <S.title>
            <CbRadio
              alt
              checked={isActivated}
              onChange={() => setIsActivated(!isActivated)}
            >
              <S.methodColors>
                {disciplines?.map((d: ProjectProgressCategories) => (
                  <S.methodColor key={d.id} color={colorPicker(d.name)} />
                ))}
              </S.methodColors>
            </CbRadio>
            <S.titleLabel onClick={onMethodClick}>{methodName}</S.titleLabel>
            <DeliverableField
              value={methodDeliverables}
              setValue={setMethodDeliverables}
              isSaved={saved}
            />
          </S.title>
        ) : (
            <>
              <S.methodColors inputActive>
                {disciplines?.map((d: ProjectProgressCategories) => (
                  <S.methodColor key={d.id} color={colorPicker(d.name)} />
                ))}
              </S.methodColors>
              <S.methodEdit
                ref={inputRef}
                value={methodName}
                onChange={updateMethodName}
              />
            </>
        )}

        <S.fields>
          <MethodMembers members={memberRoles!} />
          <MethodComplexity qualities={qualities} onClick={onPickQuality} />
          <MethodDays
            onChange={onUpdatePersonDays}
            log={complexity.log}
            personDays={complexity.personDays}
          />
          <S.actions>
            <MethodComment
              comment={commentText}
              handleComment={handleComment}
              isComment={!!comment?.content}
            />
            <MethodDate
              from={startDate}
              to={endDate}
              onSave={updateDate}
              durationInWeeks={initialDuration}
            />
            <img
              src={deleteIcon}
              alt="Delete"
              onClick={() => onDelete(methodId, servicePackageId)}
            />

            <S.saveImage
              disabled={!canSave}
              src={saveIcon}
              alt="Save"
              onClick={() => onSave()}
            />
          </S.actions>
        </S.fields>
      </S.wrapper>
    </>
  );
};

export default PhaseMethod;
