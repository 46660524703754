import tw, { styled, css } from 'twin.macro';

interface Props {
  disabled?: boolean;
}

export const wrapper = styled.div(({ disabled }: Props) => [
  css`
    ${tw`relative text-textBlack`};

    textarea {
      resize: none;
      ${tw`bg-gray10 text-18 font-semibold tracking-wider max-w-full`};
    }

    textarea:focus {
      outline: 0;
      outline-color: transparent;
      outline-style: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    textarea:active {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  `,
  disabled &&
    css`
      ${tw`opacity-75`};
    `
]);
