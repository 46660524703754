import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div<Props>(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    },
    maxWidth
  }) => [
    css`
      ${tw`w-full lg:mr-30`};
      max-width: ${maxWidth ? maxWidth : '54rem'};

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `
  ]
);
