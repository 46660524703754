import tw, { styled, css } from 'twin.macro';
import { StyledProps } from '../../../types/util.types';
import { StyledButton } from '../../../components/Button/styles';

interface RoleBasedControlProps {
  disabled?: number;
}

export const wrapper = styled.div(() => [
  css`
    ${tw`mx-30 mb-80`};

    h3 {
      ${tw`text-textBlack text-48 leading-none mb-28 `};
    }
  `
]);

export const body = styled.div(() => [
  css`
    ${tw`
        flex flex-col
        w-full 
        bg-gray-800 shadow-discipline rounded-4
        py-32
        pl-16
    `}
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 15%);

    p {
      ${tw`text-textBlack text-32 leading-44 font-normal mb-44`}
    }
  `
]);

export const total = styled.div(() => [
  css`
    ${tw`
      bg-gray-600
      flex items-center justify-end self-end
      min-w-255 mr-32 py-8 px-16 gap-10
      text-32 text-gray-200 font-semibold leading-36 text-opacity-75
      border-2 border-gray-200 border-opacity-75
      `}
  `
]);

export const listWrapper = styled.div(() => [
  css`
    ${tw`
        mt-32
        px-16
    `}
  `
]);

export const listItem = styled.div(() => [
  css`
    ${tw`
        flex
        justify-between
        px-16
        border-b-2 border-gray9
        last:border-b-0
      `}
  `
]);

export const itemCost = styled.div(() => [
  css`
    ${tw`
        flex items-center
     `}

    img {
      ${tw`
            ml-20
            cursor-pointer
         `}
    }

    span {
      ${tw`flex items-center 
         text-textBlack font-semibold text-22 text-opacity-75`}

      span {
        margin-left: 0.5rem;
      }
    }
  `
]);

export const costActionImage = styled.img<RoleBasedControlProps>(
  ({ disabled }) => [
    disabled === 1 &&
    css`
        ${tw`pointer-events-none opacity-50`}
      `
  ]
);

export const itemTitle = styled.span(() => [
  css`
    ${tw`
        py-17
        text-24 text-textBlack leading-32 font-semibold
      `}
  `
]);

export const addCostWrap = styled.span<RoleBasedControlProps>(
  () => [
    css`
      ${tw`flex items-center ml-16 mt-32`}
      width: fit-content;
    `
  ]
);

export const modalWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue w-730 max-w-full
        p-32`}

    h3 {
      ${tw`
          text-textBlack text-32 leading-36 font-semibold 
          mb-0`}
    }
    ${StyledButton} {
      ${tw`ml-auto mt-26`}
    }
  `
]);

export const inputWrapper = styled.div<StyledProps>(({ canDelete }) => [
  css`
    ${tw`
        grid items-center
        w-full
        my-30
     `}
     column-gap: 16px;
     row-gap: 32px;
     grid-template-columns: repeat(2, 1fr);
     justify-items: end;

     > div {
      ${tw`pb-0`}
     }

     img {
      ${tw`cursor-pointer`}
     }

     p {
      display: none;
     }
  `,
  canDelete &&
  css`
    grid-template-columns: repeat(2, 1fr) 40px;
   `
]);

export const form = styled.form(() => [
  css`
    ${tw`
        w-full
     `}
  `
]);
