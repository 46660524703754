import React from "react";
import Button from "../../components/Button";
import { HeaderWrapper, HeaderTop } from "../../components/Header/StyledHeader";
import useGlobal from "../../store";
import { isSuperAdmin, isAdmin } from "../../utils";
import { routePaths } from "../../utils/routes.util";
import List from './List';
import * as S from './roles-page';

const Roles = () => {
  const [user] = useGlobal((state) => state.auth.currentUser);

  return (
    <>
      <HeaderWrapper>
        <HeaderTop>
          <h2>Roles</h2>
          {
            isSuperAdmin(user) || isAdmin(user) ?
              <Button small to={routePaths.ROLE_CREATE}>
                Add new role
              </Button>
            : <span/>
          }
        </HeaderTop>
      </HeaderWrapper>
      <S.wrapper>
        <h3>List of roles</h3>
        <List/>
      </S.wrapper>
    </>
  );
}

export default Roles;
