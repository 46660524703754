import React from 'react';
import { ReactComponent as IconArrow } from '../../images/arrow-right.svg';
import * as S from './calnedar-nav';

const CalendarNav = ({
  onPreviousClick,
  onNextClick
}: {
  [key: string]: any;
}) => {
  const showNextMonth = () => onNextClick();
  const showPrevMonth = () => onPreviousClick();

  return (
    <S.wrapper>
      <IconArrow onClick={showPrevMonth} />
      <IconArrow onClick={showNextMonth} />
    </S.wrapper>
  );
};

export default CalendarNav;
