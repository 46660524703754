import React, { useContext, useState } from 'react';
import { ExternalCostType } from '../../../store/projects/projects.types';
import { numberWithCommas } from '../../../utils/personDays.utils';
import { ProgressContext } from '../Progress';
import ExternalCostsModalsWrapper from './ExternalCostsModalsWrapper';
import * as S from './external-cost';

const ExternalCost = () => {
  const { project: { externalCosts, currency } } = useContext(ProgressContext);
  const [costs, setCosts] = useState<ExternalCostType[]>(externalCosts);

  const totalPrice = costs
    .reduce(
      (acc: number, curr: ExternalCostType) =>
        acc + Number(curr.price.replace(/,/g, '')),
      0
    )
    .toFixed(2);

  return (
    <ExternalCostsModalsWrapper costs={costs} setCosts={setCosts} >
      <S.total>
        <span>{numberWithCommas(Number(totalPrice))}</span>
        <span>{!currency ? 'EUR' : currency}</span>
      </S.total>
    </ExternalCostsModalsWrapper>
  );
};

export default ExternalCost;
