import React from 'react';
import ReactPaginate from 'react-paginate';
import { SetStateType } from '../../types/util.types';
import * as S from './pagination';

interface Props {
  page: number;
  pageCount: number;
  setPage: SetStateType<number>;
}

const Pager = ({ page, setPage, pageCount }: Props) => {
  const onChange = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  return (
    <S.wrapper>
      <ReactPaginate
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        forcePage={page}
        onPageChange={onChange}
        pageCount={pageCount}
        breakLabel={'...'}
        previousLabel=""
        nextLabel=""
        containerClassName="list"
        pageLinkClassName="page"
        activeLinkClassName="active"
        nextLinkClassName="next-btn"
        previousLinkClassName="prev-btn"
        disabledClassName="disabled"
      />
    </S.wrapper>
  );
};

export default Pager;
