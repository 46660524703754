import tw, { styled, css } from 'twin.macro';
import { avWrapper } from '../../../components/AssociatedValueDropdown/associated-value-dropdown';
import { StyledButton } from '../../../components/Button/styles';
import { StyledProps as Props } from '../../../types/util.types';

interface RoleBasedControlProps {
  disabled?: number;
}

export const wrapper = styled.div(() => [
  css`
    ${tw`mx-30 mb-80`};

    h3 {
      ${tw`text-textBlack text-48 leading-none mb-28`};
    }
  `
]);

export const body = styled.div(() => [
  css`
    ${tw`flex flex-col px-16 py-32 bg-gray-800 shadow-discipline rounded-4`};

    > ${addButton} {
      ${tw`first:mt-0 mt-32 ml-16`}
    }
  `
]);

export const addButton = styled.span<RoleBasedControlProps>(() => [
  css`
    ${tw`flex items-center`}
      width: fit-content;
  `
]);

export const modalWrapper = styled.div<Props>(({wide}) => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue w-730 max-w-full p-32`}

    h3 {
      ${tw`
          text-textBlack text-32 leading-36 font-semibold 
          mb-32`}
    }
    
    form {
      ${tw`w-full`}
    }

    ${StyledButton} {
      ${tw`ml-auto mt-26`}
    }
  `,
  wide &&
    css`
      ${tw`w-920`}
    `
]);

export const phase = styled.div(() => [
  css`
    ${tw`mb-27`};

    h4 {
      ${tw`mb-0 px-16 text-textBlack text-40 font-semibold leading-46`}
    }
  `
]);

export const itemWrapper = styled.div(() => [
  css`
    ${tw`
    flex flex-col w-full mt-40
  `}
  `
]);

export const itemHeader = styled.div(() => [
  css`
    ${tw`flex items-center justify-between w-full`}

    h4 {
      ${tw`mb-0 px-16 text-textBlack text-40 font-semibold leading-46 inline-block`}
      word-break: break-word;
    }
    img {
      ${tw`mr-16 cursor-pointer`}
    }
  `
]);

export const phaseEdit = styled.input(() => [
  css`
    ${tw`relative flex pt-10 flex-wrap items-center mb-15 md:mb-0 mr-auto 
    md:max-w-440 md:min-w-440 text-textBlack font-semibold text-34 leading-32 
    border-2 border-darkBlue outline-none pl-10 py-5 pr-10`}
  `
]);

export const itemFooter = styled.div(() => [
  css`
    ${tw`flex flex-col self-start pl-16 mt-40`};

    ${addButton} {
      ${tw`mb-16 self-start last:mb-0`}
    }
  `
]);

export const phaseActions = styled.div(() => [
  css`
    ${tw`flex flex-row items-center`};
    min-width: 96px;
  `
]);

export const savePhase = styled.img<Props>(({ disabled }) => [
  disabled &&
    css`
      ${tw`opacity-50 cursor-default!`}
    `
]);

export const deletePhase = styled.img<RoleBasedControlProps>(({ disabled }) => [
  disabled === 1 &&
  css`
      ${tw`pointer-events-none opacity-50`}
    `
]);

export const service = styled.div(() => [
  css`
    ${tw`px-16 pt-20 pb-25 border-b border-gray9`};
  `
]);

export const serviceHeader = styled.div(() => [
  css`
    ${tw`relative flex items-center flex-wrap pr-32`};
  `
]);

interface ServiceActiveProps {
  active: boolean;
}

export const serviceArrow = styled.img<ServiceActiveProps>(({ active }) => [
  css`
    ${tw`mr-16 cursor-pointer`}
  `,
  active &&
  css`
      ${tw`transform rotate-180`}
    `
]);

export const serviceTitle = styled.span(() => [
  css`
    ${tw`flex items-center text-32 font-semibold leading-36 text-textBlack text-opacity-75 cursor-pointer`}
  `
]);

export const serviceIcon = styled.span<RoleBasedControlProps>(({ disabled }) => [
  css`
    ${tw`mr-12`}
      path {
        ${tw`fill-black`}
      }
    `,

  disabled === 1 &&
  css`
    ${tw`pointer-events-none opacity-75`}
    `
]);

export const serviceDeleteIcon = styled.img<RoleBasedControlProps>(
  ({ disabled }) => [
    css`
      ${tw`cursor-pointer`}
    `,
    disabled === 1 &&
    css`
        ${tw`pointer-events-none opacity-50`}
      `
  ]
);

export const serviceInfo = styled.div(() => [
  css`
    ${tw`flex items-center flex-wrap flex-col md:flex-row md:ml-auto`};
  `
]);

export const serviceDate = styled.div(() => [
  css`
    ${tw`flex items-center w-320 my-15 md:my-0 opacity-75 text-textBlack font-semibold text-22`};

    img {
      ${tw`mr-12`}
    }
  `
]);

export const serviceDays = styled.div(() => [
  css`
    ${tw`flex items-center justify-between py-8 px-16 text-32 font-semibold leading-36 border-2 border-gray-200 border-opacity-75 text-gray-200 text-opacity-75 min-w-255 mr-80 bg-gray-600`};

    path {
      ${tw`fill-gray-200 opacity-75`}
    }
  `
]);

export const serviceBody = styled.div<ServiceActiveProps>(({ active }) => [
  css`
    ${tw`flex flex-col w-full`};
  `,
  !active &&
  css`
      ${tw`hidden`}
    `
]);

export const serviceDescription = styled.div(() => [
  css`
    ${tw`w-full mb-20 last:mb-0 text-18 font-semibold leading-28 text-textBlack text-opacity-75`};
    max-width: 60rem;
  `
]);

export const removeSp = styled.div(() => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue max-w-full
        py-32 px-20
        `}
    width: 48rem;

    p {
      ${tw`
          text-textBlack text-32 leading-36 font-semibold 
          mb-0`}
    }

    div {
      ${tw`
        flex justify-end w-full
        mt-28
      `}

      button {
        ${tw`first:mr-15`}
      }
    }
  `
]);

export const phaseMethodContainer = styled.div(() => [
  css`
    ${tw`md:flex-row px-16 py-12`}
  `
]);

export const ctForm = styled.form(() => [
  css`${tw`flex flex-col gap-8`}`
])

export const dropdownWrapper = styled.div(() => [
  css`${tw`grid grid-cols-2 gap-20`}
  ${avWrapper} {
    height: 6.7rem;
  }`
])


