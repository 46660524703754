import React, { useState, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { ProjectClientType } from '../../../store/projects/projects.types';
import * as S from './project-header';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import EditClient from './EditClient';
import NewClient from './NewClient';
import { ProgressContext } from '../Progress';
import images from '../../../utils/images.utils';

interface Props {
  isClient?: boolean;
}

const ProjectHeader = ({ isClient }: Props) => {
  const {
    isMemberRole,
    project: { client, name },
    project,
    updateProject
  } = useContext(ProgressContext);
  // const [client, setClient] = useState<ProjectClientType | null>(clientData);
  const [active, setActive] = useState<boolean>(false);

  const onOpen = () => {
    setActive(true);
  };

  const onClose = () => {
    setActive(false);
  };

  const onUpdate = ({
    clientData,
    image
  }: {
    clientData?: ProjectClientType;
    image?: string;
  }) => {
    let updatedClient: ProjectClientType = { ...client! };
    if (clientData) updatedClient = { ...clientData };
    if (image) updatedClient = { ...updatedClient, image };
    updateProject({ ...project, client: updatedClient });
  };

  const onCreateClient = (data: any) => {
    updateProject({ ...project, client: data });
    onClose();
  };

  return (
    <>
      <S.wrapper>
        <S.content>
          <div>
            <h2>{name}</h2>
            {isClient && (
              <Button>
                <img src={images.downloadWhiteIcon} alt="download" />
                Download fact sheet
              </Button>
            )}
          </div>
          {client && (
            <p>
              {client.firstName} {client.lastName}, this is your <br />{' '}
              personalized project example.
            </p>
          )}
          {!isClient && (
            <Button secondary onClick={onOpen} disabled={isMemberRole}>
              <ReactSVG wrapper="span" src={images.clientIcon} />{' '}
              {client ? 'Edit Client' : 'Create Client'}
            </Button>
          )}
        </S.content>
      </S.wrapper>
      <Modal isOpen={active} onDismiss={onClose}>
        <S.modalContent>
          {!client && (
            <NewClient onClose={onClose} onSuccess={onCreateClient} />
          )}
          {client && (
            <EditClient onClose={onClose} client={client} onUpdate={onUpdate} />
          )}
        </S.modalContent>
      </Modal>
    </>
  );
};

export default ProjectHeader;
