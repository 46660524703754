import React from 'react';
import * as S from './StyledMultipleSearchInputs';
import { SearchInput } from '.';
import addIcon from '../../images/add.svg';
import deleteIcon from '../../images/delete.svg';
import { MultipleInputs } from '../../store/method-service/types';
import { SetStateType } from '../../types/util.types';
import ClickableText from '../ClickableText';

interface Props {
  multipleInputs: MultipleInputs[];
  setMultipleInputs: SetStateType<MultipleInputs[]>;
  isDisabled?: boolean;
  setSelectedRoles?: any;
  selectedRoles?: any;
  apiVariable?: string | number;
  isAllDisabled?: boolean;
}

const MultipleSearchInputs = ({
  multipleInputs,
  setMultipleInputs,
  isDisabled,
  selectedRoles,
  setSelectedRoles,
  apiVariable,
  ...rest
}: Props) => {
  const handleAddFields = () => {
    const values = [...multipleInputs];
    values.push({ method: null, methodOptions: [] });
    setMultipleInputs(values);
  };
  const handleRemoveField = (index: number) => {
    const values = [...multipleInputs];
    values.splice(index, 1);
    setMultipleInputs(values);
  };

  const handleOptions = (option: any, index: number) => {
    const values = [...multipleInputs];
    values[index].methodOptions = option;

    setMultipleInputs(values);
  };

  const handleResult = (result: any, index: number) => {
    const values = [...multipleInputs];
    values[index].method = result;

    const checkIsArray = typeof values[index].method === 'object';

    setMultipleInputs(values);
    if (checkIsArray) {
      const newTestValue = selectedRoles.concat(
        values[index].method?.memberRoles
      );

      setSelectedRoles(newTestValue);
    }
  };
  return (
    <S.Wrapper {...rest}>
      <S.InputListRow>
        {multipleInputs &&
          multipleInputs.length > 0 &&
          multipleInputs.map((item: any, index: any) => {
            return (
              <S.InputItem key={`input_${index}`}>
                <SearchInput
                  result={item.method}
                  checkProp="name"
                  setResult={handleResult}
                  options={item.methodOptions}
                  setOptions={handleOptions}
                  label="Method Name"
                  api={`/methods?level=${apiVariable}&name=`}
                  withInputs
                  index={index}
                />
                {index !== 0 && index <= multipleInputs.length - 1 && (
                  <img
                    src={deleteIcon}
                    alt=""
                    onClick={() => handleRemoveField(index)}
                  />
                )}
                {index === multipleInputs.length - 1 && (
                  <ClickableText
                    onClick={handleAddFields}
                    disabled={isDisabled}
                    icon={addIcon}>
                    Add more methods
                  </ClickableText>
                )}
              </S.InputItem>
            );
          })}
      </S.InputListRow>
    </S.Wrapper>
  );
};

export default MultipleSearchInputs;
