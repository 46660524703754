import { useState, useEffect } from 'react';

const useDebounce = (
  value: string,
  delay: number = 500,
  startSearchAt: number = 3
) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (value?.length >= startSearchAt) {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [delay, value, startSearchAt]);

  return debouncedValue;
};

export default useDebounce;
