import React, { ChangeEvent } from 'react';
import TextInput from '../../../components/Inputs/TextInput';
import { SetStateType } from '../../../types/util.types';
import * as S from './name-field';

interface Props {
  value: string;
  setValue: SetStateType<string>;
  maxWidth?: string;
  label: string;
  disabled?: boolean;
}

const MethodLevels = ({
  value,
  setValue,
  maxWidth,
  label,
  disabled
}: Props) => {
  const changeHandler = (
    evt: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    setValue(evt.target.value);
  };

  return (
    <S.wrapper maxWidth={maxWidth}>
      <TextInput
        value={value}
        onChange={changeHandler}
        name="name"
        label={label}
        disabled={disabled}
      />
    </S.wrapper>
  );
};

export default MethodLevels;
