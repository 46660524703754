import React from 'react';
import * as S from './no-result';

interface Props {
    searchInput: string;
}

const NoResult = ({ searchInput }: Props) => {
    return (
        <S.text>No results were found for '{searchInput}'</S.text>
    )
}

export default NoResult;
