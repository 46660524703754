import React, { useState, ChangeEvent } from 'react';
import { FormikTouched, FormikErrors } from 'formik';
import { ReactSVG } from 'react-svg';
import StyledTextInput, { InputWrapper, ErrorText } from './StyledTextInput';
import { ReactComponent as EyeIcon } from '../../images/eye.svg';
import { ReactComponent as EyeCloseIcon } from '../../images/eye close.svg';
import userIcon from '../../images/auth/user.svg';
import passwordFilled from '../../images/auth/password-filled.svg';

interface Props {
  value: string | number;
  name: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  small?: boolean;
  frameless?: boolean;
  type?: string;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  error?: null | string | FormikErrors<any> | FormikErrors<any>[] | string[];
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (evt: ChangeEvent<HTMLInputElement>) => void;
  isClient?: {} | null | undefined;
  profile?: boolean;
  disableAutoComplete?: boolean;
}

const TextInput = ({
  value,
  label,
  name,
  placeholder = '',
  disabled = false,
  small = false,
  frameless = false,
  type = 'text',
  error = null,
  touched = false,
  isClient,
  onChange = () => {},
  onBlur = () => {},
  disableAutoComplete,
  profile,
  ...rest
}: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(type === 'text');

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <StyledTextInput
      small={small}
      disabled={disabled}
      frameless={frameless}
      isClient={isClient}
      {...rest}
    >
      <InputWrapper
        disabled={disabled}
        hasValue={!!value}
        invalid={touched && error}
        small={small}
        isClient={isClient}
      >
        {type === 'password' &&
          (isVisible ? (
            <EyeCloseIcon onClick={toggleVisibility} />
          ) : (
            <EyeIcon onClick={toggleVisibility} />
          ))}
        {type === 'text' && isClient && <ReactSVG src={userIcon} />}
        {type === 'password' && isClient && (
          <ReactSVG
            src={passwordFilled}
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 24px; height: 24px');
            }}
          />
        )}
        <input
          type={type === 'password' && !isVisible ? 'password' : 'text'}
          value={value}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete={disableAutoComplete ? 'off' : 'on'}
        />
        {label && <label>{label}</label>}
      </InputWrapper>
      <ErrorText  showHint={touched && error}>{error}</ErrorText>
    </StyledTextInput>
  );
};

export default TextInput;
