import React, { ChangeEvent } from 'react';
import * as S from './textarea-input';

interface Props {
  value?: number | string;
  name?: string;
  disabled?: boolean;
  rows?: number;
  cols?: number;
  onChange?: (evt: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (evt: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  textAreaRef?: any;
}

const TextArea = ({
  value,
  name,
  onChange = () => {},
  onFocus = () => {},
  disabled = false,
  rows,
  cols,
  placeholder,
  textAreaRef,
}: Props) => {
  return (
    <S.wrapper disabled={disabled}>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        ref={textAreaRef}
      />
    </S.wrapper>
  );
};

export default TextArea;
