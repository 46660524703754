export enum AuthRoutes {
  root = '/',
  project = '/projects',
  clientProjects = '/client-projects',
  projectsCreate = '/projects/create',
  singleProject = '/projects/:id',
  projectSummary = '/projects/:id/summary',
  editProject = '/projects/:id/edit',
  clientSummary = '/client-projects/:id/summary',
  profile = '/profile',
  members = '/members',
  membersCreate = '/members/create',
  services = '/services',
  servicesNewMethod = '/services/new-method',
  servicesNewService = '/services/new-service',
  clients = '/clients',
  unauthorized = '/unauthorized',
  roles = '/roles',
  roleCreate = '/roles/new'
}

export enum NonAuthRoutes {
  login = '/login',
  resetPass = '/reset-password'
}

export enum UserRoles {
  superAdmin = 'ROLE_SUPER_ADMIN',
  admin = 'ROLE_ADMIN',
  member = 'ROLE_PROJECT_MEMBER',
  client = 'ROLE_CLIENT'
}
