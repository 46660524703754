import tw, { styled, css } from 'twin.macro';
import arrow from '../../images/arrow-right.svg';

export const wrapper = styled.div(() => [
  css`
    ${tw`flex justify-center mt-30 text-textBlack absolute bottom-48 left-0 right-0 m-auto`};

    .list {
      ${tw`flex items-center justify-center`}
    }

    .page {
      ${tw`flex items-center justify-center mx-3 bg-transparent text-gray-200 text-24 font-semibold transition transition-colors duration-200 outline-none`}
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 2px;

      &.active {
        ${tw`text-green`}
      }
    }

    a {
      color: #0A0A33;
    }

    .previous,
    .next {
      &.disabled a {
        ${tw`bg-gray-600`}
      }
    }

    .prev-btn,
    .next-btn {
      ${tw`block ml-0 text-gray-200 outline-none transition-all duration-300 bg-gray-200`}
      width: 3.2rem;
      height: 3.2rem;
      -webkit-mask: url('${arrow}');
      mask: url('${arrow}');
      mask-repeat: no-repeat;
      mask-position: center;
    }

    .prev-btn {
      transform: rotate(180deg);
    }
  `
]);
