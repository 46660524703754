import React from 'react';
import { ProjectEvents } from '../../../store/projects/projects.types';
import { fetchIcon, colors } from '../../../utils/event.utils';

import * as S from './summary-chart';
import { differenceInCalendarDays } from 'date-fns';

interface Props {
  data: ProjectEvents;
  projectStart?: null | Date;
  projectDuration?: null | number;
}

const SummaryEvent = ({
  data: { name, iconName, iconColor, lineColor, date },
  projectStart,
  projectDuration
}: Props) => {
  const Icon = fetchIcon(iconName);
  const leftPos =
    projectStart && date && projectDuration
      ? (differenceInCalendarDays(new Date(date), projectStart) /
          projectDuration) *
        100
      : 0;

  return (
    <>
      <S.summaryEvent
        iconColor={colors[iconColor]}
        inChart={!!date}
        style={{ left: `${leftPos}%` }}
      >
        <S.summaryEventLine lineColor={colors[lineColor]} />
        <Icon />
        {name}
      </S.summaryEvent>
    </>
  );
};

export default SummaryEvent;
