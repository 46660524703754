import { getRoles } from '../store/roles/roles.action';
import { useEffect, useState } from 'react';
import { RoleType } from '../store/roles/roles.types';

const useTaskRoles = () => {
  const [taskRoles, setTaskRoles] = useState<RoleType[]>([]);

  const fetchRoles = async () => {
    const { data } = await getRoles();
    setTaskRoles(data);
  }

  useEffect(() => {
    fetchRoles();
  }, []);

  return {taskRoles, setTaskRoles, fetchRoles};
}

export default useTaskRoles