import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div(() => [
  css`
    ${tw`flex justify-between min-w-full max-w-full items-center mt-8`}
  `
]);

export const label = styled.div<Props>(({
  theme: {
    typography: { p }
  }
}) => [
    css`
      ${p}
      ${tw`flex-auto`}
    `
  ]
);

export const value = styled.div<Props>(({
  disabled,
  invalid,
  theme: {
    typography: { p }
  }
}) => [
    css`
    ${tw`relative flex justify-between items-center border-2 border-solid border-darkBlue`};
    min-width: 12rem;
    max-width: 12rem;

    input {
      ${p}
      ${tw`inline-block w-full border-0 pt-2 pb-2 pr-2 outline-none bg-transparent text-right`}
      -moz-appearance:textfield;
    }

    &:focus-within {
      ${tw`border-green`}
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    span {
      ${p}
      ${tw`inline-block pt-2 pb-2 pr-3 cursor-default`}
    }

    `,
    (disabled && css`
    input,
    span {
      opacity: 0.5;
      pointer-events: none;
    }
  `),
    (invalid
       &&
      css`
      ${tw`border-error`}
      &:focus-within {
        ${tw`border-error`}
      }
    `),
    disabled && 
    css`
      ${tw`border-gray-200 border-2 border-opacity-75 bg-gray-600`}
    `
  ]
);