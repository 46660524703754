import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';
import {
  Dropdown as dropdown,
  Wrapper as actionsWrapper
} from '../../../components/Actions/actions-component';

export const wrapper = styled.div<Props>(({ listView, disabled }) => [
  css`
    ${tw`flex items-center text-textBlack relative bg-white `};
  `,
  !listView &&
  css`
      ${tw`flex-col pb-20`}
      border-radius: 4px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

      &:nth-child(2n) {
        ${dropdown} {
          ${tw`
          sm:left-auto lg:left-100
          sm:right-100 lg:right-auto
          sm:-translate-x-4 lg:translate-x-4
          `}
        }
      }

      &:nth-child(3n) {
        ${dropdown} {
          ${tw`
          lg:left-auto xl:left-100
          lg:right-100 xl:right-auto
          lg:-translate-x-4 xl:translate-x-4
          `}
        }
      }

      &:nth-child(4n) {
        ${dropdown} {
          ${tw`
          xl:left-auto
          xl:right-100
          xl:-translate-x-4
          `}
        }
      }
    `,
  listView &&
  css`
      ${tw`flex-col justify-center sm:justify-start sm:flex-row sm:px-20 py-10 items-center flex-wrap`}

      &:not(:last-child) {
        ${tw`border-b-2 border-gray9`}
      }
    `,
  disabled &&
  css`
      ${actionsWrapper} {
        ${tw`z-10`}
      }
      &:after {
        ${tw`absolute block bg-white opacity-50 h-full w-full left-0`}
        content: '';
      }
    `
]);

export const memberName = styled.div<Props>(({ listView }) => [
  css`
    ${tw`px-5 font-semibold text-24 leading-32 `};
    word-break: break-word;
  `,
  listView &&
  css`
      ${tw`flex-1 min-w-20 text-center sm:text-left md:max-w-30 md:min-w-30`}
    `
]);

export const memberEmail = styled.div<Props>(({ listView }) => [
  css`
    ${tw`px-5 text-textBlack font-semibold break-all`}
  `,
  !listView &&
  css`
      ${tw`mt-3 mb-15 text-14 leading-9`}
    `,
  listView &&
  css`
      ${tw`mr-0 md:mr-20 text-16 leading-28 xl:flex-1 md:max-w-30 md:min-w-30`}
    `
]);

export const header = styled.div<Props>(({ listView, isClient }) => [
  css`
    ${tw`flex items-center justify-center w-full pt-64`}

    span {
      ${tw`bg-green2 text-white p-30 font-semibold lowercase rounded-4`}
    }
    img {
      ${tw`max-w-full`}
    }
  `,
  !listView &&
  css`
      ${tw` mb-15 `};
      min-height: 18.4rem;
      max-height: 18.4rem;

      img {
        ${tw`w-140  h-130 object-cover rounded-4`}
      }

      span {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 4.8rem;
        line-height: 9.6rem;
      }
    `,
  listView &&
  css`
      ${tw`mr-0 sm:mr-30 py-10`}
      height: auto;
      max-width: 7.2rem;
      min-width: 7.2rem;

      span {
        ${tw`flex items-center justify-center text-24 leading-28`}
        width: 6.6rem;
        height: 4.2rem;
      }
    `,
  isClient &&
  css`
      span {
        ${tw`bg-green4`}
      }
    `
]);

export const footer = styled.div<Props>(({ listView }) => [
  css`
    ${tw`flex items-center `};

    button:last-of-type {
      ${tw` mt-20`}
    }
  `,
  !listView &&
  css`
      ${tw`flex-col w-full`};

      ${actionsWrapper} {
        ${tw`self-end mr-20 mt-10`}
      }
    `,
  listView &&
  css`
      ${tw`flex-col sm:flex-row ml-auto w-full max-w-full md:max-w-xs`}
    `
]);

export const noConfirmation = styled.div<Props>(
  ({
    listView,
    theme: {
      typography: { p }
    }
  }) => [
      css`
      ${p}
      ${tw`flex items-center justify-between  w-full order-1 self-center  mt-10`};
      max-width: 16rem;
      min-width: 16rem;

      svg {
        height: 2rem;
        width: 2rem;
        path {
          fill: red;
        }
      }
    `,
      listView &&
      css`
        ${tw`order-none mr-10 sm:self-start mt-25`}
      `
    ]
);
