import tw, { styled, css } from 'twin.macro';

export const body = styled.div(() => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue max-w-full py-32 px-20 w-480`}

    p {
      ${tw`text-textBlack text-32 leading-36 font-semibold mb-0`}
    }

    p:nth-child(2) {
      ${tw`text-textBlack text-22 leading-24 mt-22`}
    }

    div {
      ${tw`flex justify-end w-full mt-28`}

      button {
        ${tw`first:mr-15`}
      }
    }
  `
]);
