import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import TextArea from '../../../components/Inputs/TextArea';
import images from '../../../utils/images.utils';
import * as S from './phase-method';

interface Props {
  comment: string;
  handleComment: (val: string) => void;
  isComment: boolean;
}

const MethodComment = ({ comment, handleComment, isComment }: Props) => {
  const ref = useRef(null);
  const textAreaRef = useRef<HTMLTextAreaElement>();
  const [active, setActive] = useState<boolean>(false);
  const [disabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (isComment) {
      setIsDisabled(true);
    }
  }, [isComment]);

  useEffect(() => {
    if (isComment && !disabled && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isComment, disabled]);

  const toggle = () => setActive(!active);

  const close = () => {
    if (active) setActive(false);
    if (isComment) {
      setIsDisabled(true);
    }
  };

  const editComment = () => {
    if (isComment) {
      setIsDisabled(false);
    }
    if (!isComment && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    handleComment(e.target.value);
  };

  const onFocus = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  };

  useOnClickOutside(ref, close);

  return (
    <S.commentWrapper ref={ref}>
      <S.iconCommentWrap active={active}>
        <img
          src={isComment ? images.commentActive : images.comment}
          alt="comment"
          onClick={toggle}
        />
      </S.iconCommentWrap>
      {active && (
        <S.commentBox>
          <img src={images.commentActive} alt="comment active" />
          <img src={images.closeX} alt="close" onClick={() => close()} />
          <img src={images.edit} alt="edit" onClick={() => editComment()} />
          <S.commentContent>
            <TextArea
              value={comment}
              onChange={handleChange}
              textAreaRef={textAreaRef}
              rows={3}
              disabled={disabled}
              onFocus={onFocus}
            />
          </S.commentContent>
        </S.commentBox>
      )}
    </S.commentWrapper>
  );
};

export default MethodComment;
