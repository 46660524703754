import { API_ROOT } from "../config/api.config";

export const convertImageURI = (identifier: string): string => {
    return `${API_ROOT}${identifier}`;
}

export default {
    comment: require('../images/commentary.svg'),
    commentActive: require('../images/commentary-filled.svg'),
    closeX: require('../images/close-x.svg'),
    edit: require('../images/edit.svg'),
    save: require('../images/pool-archive.svg'),
    delete: require('../images/delete.svg'),
    mail: require('../images/envelope.svg'),
    userOutline: require('../images/user-outline.svg'),
    cost: require('../images/cost.svg'),
    invIcon: require('../images/invitation.svg'),
    serviceIcon: require('../images/services.svg'),
    projectIcon: require('../images/project.svg'),
    papirPlaneIcon: require('../images/paper-plane.svg'),
    clientIcon: require('../images/icon-client.svg'),
    downloadWhiteIcon: require('../images/download-white.svg'),
    addIcon: require('../images/add-2.svg'),
    calendarIcon: require('../images/calendar.svg'),
    arrowDownIcon: require('../images/arrow-down.svg'),
    costIcon: require('../images/cost-euro.svg'),
    roles: require('../images/people-outline.svg')
};
