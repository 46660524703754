import api from '../../config/api.config';
import { handleErrors } from '../../utils/error.utils';
import { PhaseOverview } from './phase.types';

export const getPhases = async (): Promise<any> => {
  try {
    const {
      data: { data }
    } = await api('/phases');
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const phaseOverview: PhaseOverview = async ({
  page = 0,
  size = 20,
  title = ''
}) => {
  try {
    const {
      data: { data }
    } = await api(`/phases/overview?title=${title}&page=${page}&size=${size}`);
    return data;
  } catch (error) {
    handleErrors(error);
  }
};
