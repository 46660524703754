import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import useGlobal from './store';
import ModalProvider from './providers/modal.provider';
import Protected from './views/Protected';
import Login from './views/Login';
import ResetPassword from './views/ResetPassword';
import ScrollToTop from './components/ScrollToTop'
import NonAuthRoute from './components/RouteGuard/NonAuthRoute'
import api, { getAuthToken } from './config/api.config';
import { NonAuthRoutes } from './utils/routeEnums.utils';
import EmailAcceptWrapper from './components/EmailWrapper/EmailAcceptWrapper';

const App = () => {
  const [, actions] = useGlobal();
  const _mounted = useRef(false);

  if (!_mounted.current) {
    _mounted.current = true;
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;
        const refreshToken = localStorage.getItem('cpcRefresh');

        if (error.response.status !== 401) return Promise.reject(error);

        if (
          error.response.status === 401 &&
          error.config.headers?.grant_type === 'refresh_token'
        ) {
          actions.logout();
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          if (originalRequest._retry || !refreshToken) {
            actions.logout();
            return Promise.reject(error);
          }

          originalRequest._retry = true;

          return getAuthToken(actions.logout).then((res: any) => {
            originalRequest.headers['Authorization'] = `${res.token}`;
            return api.request(originalRequest);
          });
        }
      }
    );
  }

  return (
    <ModalProvider>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path={NonAuthRoutes.login} render={() => (
            <EmailAcceptWrapper>
              <NonAuthRoute path={NonAuthRoutes.login} Component={Login} />
            </EmailAcceptWrapper>
          )} />
          <NonAuthRoute path={NonAuthRoutes.resetPass} Component={ResetPassword} />
          <Route path="" component={Protected} />
        </Switch>
      </Router>
    </ModalProvider>
  );
}

export default App;
