import React, { useState, ChangeEvent, FormEvent } from 'react'
import { RoleType } from '../../../store/roles/roles.types';
import Button from "../../../components/Button";
import TextInput from '../../../components/Inputs/TextInput';
import { editRole } from '../../../store/roles/roles.action';
import * as S from './roles-list';
import { validateRoleName } from '../../../utils/roles.utils';
import { validatePrice } from '../../../utils/price.utils';

interface Props {
  onSuccess: (role: RoleType) => void;
  role: RoleType;
}

const EditRole = ({ onSuccess, role }: Props) => {
  const [values, setValues] = useState<RoleType>({
    id: role.id,
    name: role.name,
    price: role.price
  });
  const [submitting, setSubmitting] = useState<boolean>(false);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'name' && !validateRoleName(value)) return;
    if (name === 'price' && (value !== '' && !validatePrice(value))) return;

    setValues({ ...values, [name]: value });
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      setSubmitting(true);
      await editRole(role.name, values.name.trim(), values.price)
      onSuccess(values)
    } catch {
      setSubmitting(false);
    }
  };

  return (
    <S.modalWrapper>
      <h3>Edit role</h3>
      <S.form onSubmit={onSubmit}>
        <S.inputWrapper>
          <TextInput
            value={values.name}
            onChange={changeHandler}
            name="name"
            label="Role name"
            disableAutoComplete
          />
          <TextInput
            value={values.price}
            onChange={changeHandler}
            name="price"
            label="Price"
            disableAutoComplete
          />
        </S.inputWrapper>
        <Button
          type="submit"
          secondary
          disabled={!values.name || !values.price || submitting}
        >
          Confirm
        </Button>
      </S.form>
    </S.modalWrapper>
  )
}

export default EditRole