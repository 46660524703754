import React, { useState } from 'react';
import Actions from '../../../components/Actions';
import { SetStateType } from '../../../types/util.types';
import { Level } from '../../../store/method-service/types';
import * as S from './service-filter';

const methodLevels: Level[] = [
  {
    id: '',
    name: 'All'
  },
  {
    id: 'STARTER',
    name: '.starter'
  },
  {
    id: 'LIGHTHOUSE',
    name: '.lighthouse'
  },
  {
    id: 'TEASER',
    name: '.teaser'
  },
  {
    id: 'LEAN',
    name: '.lean'
  },
  {
    id: 'WIZARD',
    name: '.wizard'
  },
  {
    id: 'DESIGN_TO_COST',
    name: '.design-to-cost'
  }
];
const complexityOptions: Level[] = [
  {
    id: 'STANDARD',
    name: 'Standard'
  },
  {
    id: 'HIGH',
    name: 'High'
  },
  {
    id: 'EXTREME',
    name: 'Extreme'
  }
];
const serviceTypeOptions: Level[] = [
  {
    id: '',
    name: 'All'
  },
  {
    id: 'METHOD',
    name: 'Method'
  },
  {
    id: 'SERVICE_PACKAGE',
    name: 'Service package'
  }
];

interface Props {
  setQualityType: SetStateType<string>;
  setLevel: SetStateType<string>;
  setServiceType: SetStateType<string>;
}
const Filter = ({ setQualityType, setLevel, setServiceType }: Props) => {
  const [openComplexity, setOpenComplexity] = useState<boolean>(false);
  const [openLevel, setOpenLevel] = useState<boolean>(false);
  const [openType, setOpenType] = useState<boolean>(false);

  const toggleComplexityMenu = () => setOpenComplexity(!openComplexity);

  const closeComplexityMenu = () => {
    if (openComplexity) setOpenComplexity(false);
  };
  const toggleComplexityOption = (id: string) => {
    setQualityType(id);
    closeComplexityMenu();
  };

  const toggleLevelMenu = () => setOpenLevel(!openLevel);
  const closeLevelMenu = () => {
    if (openLevel) setOpenLevel(false);
  };

  const toggleLevelOption = (id: string) => {
    setLevel(id);
    closeLevelMenu();
  };

  const toggleTypeMenu = () => setOpenType(!openType);
  const closeTypeMenu = () => {
    if (openType) setOpenType(false);
  };

  const toggleTypeOption = (id: string) => {
    setServiceType(id);
    closeTypeMenu();
  };

  return (
    <S.wrapper>
      <S.item>
        Name
        <Actions
          toggle={toggleLevelMenu}
          open={openLevel}
          close={closeLevelMenu}
          dropdown
        >
          {methodLevels.map((item) => {
            return (
              <S.action
                key={item.id}
                onClick={() => toggleLevelOption(item.id)}
              >
                {item.name}
              </S.action>
            );
          })}
        </Actions>
      </S.item>
      <S.item>
        Complexity
        <Actions
          toggle={toggleComplexityMenu}
          open={openComplexity}
          close={closeComplexityMenu}
          dropdown
        >
          {complexityOptions.map((item) => {
            return (
              <S.action
                key={item.id}
                onClick={() => toggleComplexityOption(item.id)}
              >
                {item.name}
              </S.action>
            );
          })}
        </Actions>
      </S.item>
      <S.item>
        <span>Persondays</span>
        <span>PD</span>
      </S.item>
      <S.item>
        <span>Method /SP</span>
        <span>M/SP</span>
        <Actions
          toggle={toggleTypeMenu}
          open={openType}
          close={closeTypeMenu}
          dropdown
        >
          {serviceTypeOptions.map((item) => {
            return (
              <S.action key={item.id} onClick={() => toggleTypeOption(item.id)}>
                {item.name}
              </S.action>
            );
          })}
        </Actions>
      </S.item>
      <S.item></S.item>
    </S.wrapper>
  );
};

export default Filter;
