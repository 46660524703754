import tw, { styled, css } from 'twin.macro';
// import { InputWrapper } from '../../../components/Inputs/StyledTextInput';

export const wrapper = styled.div(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    }
  }) => [
    css`
      ${tw`flex flex-col w-full`};
      max-width: 25.5rem;

      h4 {
        ${tw`mb-25 text-textBlack text-24 leading-32 font-semibold`}
      }

      &:not(:last-child) {
        ${tw`md:mr-30`};
      }

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `
  ]
);
