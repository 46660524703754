import tw, { styled, css } from 'twin.macro';
import { StyledProps } from '../../types/util.types';

export const wrapper = styled.div(() => [
  css`
    ${tw`relative z-40 w-full mb-28`};
  `
]);

export const input = styled.div<StyledProps>(({ opened, disabled }) => [
  css`
    ${tw`flex flex-col justify-center w-full pl-16 pr-50 border-2 border-darkBlue text-22 leading-23 font-semibold text-textBlack bg-gray-800 cursor-pointer hover:bg-gray-600`};
    height: 6.4rem;

    svg {
      ${tw`absolute`}
      right: 2rem;
      top: 2rem;
    }
  `,
  opened && css`${tw`border-green hover:bg-gray-800`}`,
  disabled && 
  css`
    ${tw`border-gray-200 border-opacity-75 text-gray-200 text-opacity-75 bg-gray-600 pointer-events-none`}

    path {
      ${tw`fill-gray-200 opacity-75`}
    }
  `
]);

interface LabelProps {
  hasValue: boolean;
  disabled?: boolean | undefined;
}

export const label = styled.span<LabelProps>(({ hasValue, disabled }) => [
  css`
    ${tw`absolute top-0 text-gray-400 text-22 leading-32 font-semibold transition duration-300 transform translate-y-16`}
    left: 1.6rem;
  `,
  hasValue &&
    css`
      ${tw`text-14 leading-none text-gray-400 translate-y-6`}
    `,
  disabled && 
  css`${tw`text-gray-200 text-opacity-75`}`
]);

export const dropdown = styled.div(() => [
  css`
    ${tw`absolute z-50 top-100 right-0 mt-5 flex flex-col items-start w-full max-w-356 border-2 border-green bg-gray-800 p-6`}

    .DayPicker {
      ${tw`w-full`}

      .DayPicker-wrapper {
        ${tw`outline-none p-0`}

        svg path {
          ${tw`fill-black`}
        }
      }

      .DayPicker-Month {
        ${tw`w-full m-0`}
      }

      .DayPicker-Caption {
        ${tw`mb-8 text-24 leading-32 font-semibold text-black text-center`}
      }

      .DayPicker-Weekdays {
        ${tw`mb-12`}
      }

      .DayPicker-Weekday {
        ${tw`p-0 m-0 text-18 leading-48 text-black font-semibold`}
      }

      .DayPicker-Week {
        ${tw`mb-4 last:mb-0`}
      }

      .DayPicker-Day {
        ${tw`outline-none text-gray-200 font-semibold text-18 leading-32`}

        &.DayPicker-Day--outside {
          ${tw`cursor-default text-gray-200 opacity-50`}
        }

        &.DayPicker-Day--selected {
          ${tw`text-gray-800 bg-green`}
        }

        &.DayPicker-Day--disabled {
          ${tw`cursor-default text-gray-400`}
        }

        &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          ${tw`bg-gray-600`}
        }
      }
    }
  `
]);
