import React, { FunctionComponent } from 'react';
import tw, { styled, css } from 'twin.macro';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { motion, AnimatePresence } from 'framer-motion';
import '@reach/dialog/styles.css';

interface Props {
  isOpen: boolean;
  onDismiss: any;
  focusRef?: React.MutableRefObject<null>;
  closeModal?: any;
}

const Content = motion.custom(DialogContent);

const DialogReachOverlay = styled(DialogOverlay)(() => [
  css`
    ${tw`items-center`}
    z-index: 100;

    [data-dialog-overlay] {
      ${tw`absolute top-0 right-0 bottom-0 left-0 z-10`}
      background: rgba(236, 236, 236, 0.49);
    }

    [data-reach-dialog-content] {
      ${tw`relative flex  items-center justify-center w-screen min-h-screen m-0 bg-transparent`}
    }

    [data-dialog-content] {
      ${tw`flex items-center relative z-20 max-w-full`}
    }
  `
]);

const Modal: FunctionComponent<Props> = ({
  children,
  isOpen,
  onDismiss,
  closeModal,
  ...rest
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <DialogReachOverlay isOpen={isOpen} onDismiss={onDismiss} {...rest}>
          <Content
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{ opacity: 0 }}
            aria-label="modal-global"
            transition={{ type: 'tween' }}
          >
            <div data-dialog-overlay onClick={onDismiss} />
            <motion.div
              data-dialog-content
              initial={{ y: -50 }}
              animate={{ y: 0 }}
              exit={{ y: -50 }}
              transition={{ type: 'tween' }}
            >
              {children}
            </motion.div>
          </Content>
        </DialogReachOverlay>
      )}
    </AnimatePresence>
  );
};

export default Modal;
