import React  from 'react';
import Ink from 'react-ink';
import { StyledButton, StyledLinkButton } from './styles';

interface Props {
  to?: string;
  small?: boolean;
  secondary?: boolean;
  frameless?: boolean;
  disabled?: boolean;
  onClick?: any;
  type?: 'submit' | 'button';
  children?: any;
}

const Button = ({
  to,
  small,
  children,
  frameless,
  secondary,
  disabled = false,
  onClick = () => {},
  type = 'button'
}: Props ) => {
  return to ? (
    <StyledLinkButton
      to={to}
      small={small ? 1 : 0}
      frameless={frameless}
      secondary={secondary ? 1 : 0}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      {!disabled && <Ink />}
    </StyledLinkButton>
  ) : (
    <StyledButton
      small={small ? 1 : 0}
      frameless={frameless}
      secondary={secondary ? 1 : 0}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
      {!disabled && <Ink />}
    </StyledButton>
  );
};

export default Button;
