import React from 'react';
import { MemberRole } from '../../../store/method-service/types';
import * as S from './member-field';
import { SetStateType } from '../../../types/util.types';

interface Props {
  role: MemberRole;
  setSelected: SetStateType<MemberRole[]>;
  selected: MemberRole[];
}

const MemberRow = ({ role: { name, personDays } }: Props) => {
  return (
    <S.roles>
      {name}
      <S.personDays>
        <div>{personDays}</div>
        <span>pd</span>
      </S.personDays>
    </S.roles>
  );
};

export default MemberRow;
