import tw, { styled, css } from 'twin.macro';
import { StyledProps } from '../../types/util.types';

export const avWrapper = styled.div<StyledProps>(({ disabled, open }) => [
  css`
    ${tw`flex justify-start items-center mb-30 flex-row-reverse w-full relative outline-none bg-gray-800 font-semibold text-textBlack text-2xl md:text-4xl border-darkBlue border-2 border-solid`}

    padding: 1.6rem 1.6rem;

    &:hover {
      ${tw`bg-gray-600`}
    }
  `,
  disabled &&
  css`
    ${tw`border-gray-200 border-opacity-75 bg-gray-600 pointer-events-none`}

    div {
      ${tw`text-gray-200 text-opacity-75 `}
    }

    div:first-child {
      span {
        ${tw`bg-gray-200 opacity-75`}
      }
    }
    `,
  open && 
  css`
    ${tw`border-green hover:bg-gray-800`}
  `
]);

export const items = styled.div<StyledProps>(({ icon }) => [
  css`
    ${tw`block whitespace-no-wrap overflow-hidden pl-1 pr-20 mr-auto text-textBlack`};
    text-overflow: ellipsis;

    padding-left: ${icon && '2.8rem'};
  `
]);

export const label = styled.label<StyledProps>(
  ({ hasValue, labelColor, icon }) => [
    css`
      ${tw`absolute font-semibold text-22`}
      top: 50%;
      left: ${icon ? '4.5rem' : '1.8rem'};
      color: ${labelColor ? labelColor : '#929da2'};
      transform: translateY(-50%);
      transition: all 0.3s;
      line-height: 2.8rem;
      pointer-events: none;
    `,
    hasValue &&
    css`
        top: 0;
        transform: translateY(0.6rem);
        font-size: 1.4rem;
        line-height: 1;
      `
  ]
);

export const noOptions = styled.div(() => [
  css`
    ${tw`p-10 font-semibold text-22`}
    pointer-events:none;
  `
]);

export const iconWrapper = styled.div(() => [
  css`
    ${tw`flex absolute `};
    left: 10px;
    svg {
      width: 3rem;
      height: 3rem;
    }
  `
]);
