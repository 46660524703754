import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div(() => [
  css`
    ${tw`
      relative flex flex-col items-start
      border-2 border-darkBlue
      min-w-full max-w-full h-160 bg-gray-800
      pt-9 pr-2 mt-25
      transition-all duration-300
      focus-within:border-green
     `}

    textarea {
      ${tw`flex-1 w-full h-full pl-15 text-18 font-semibold bg-gray-800 resize-none outline-none cursor-auto`}

      ::-webkit-scrollbar {
        ${tw`w-3 mb-10`}
      }

      ::-webkit-scrollbar-thumb {
        ${tw`rounded-4 bg-gray-400`}
      }

      ::placeholder {
        ${tw`text-gray-400`}
      }

      ::-webkit-textarea-placeholder {
        ${tw`text-gray-400`}
      }

      :-ms-textarea-placeholder {
        ${tw`text-gray-400`}
      }
    }
  `
]);

export const icon = styled.img<Props>(({ active }) => [
  css`
    ${tw`absolute cursor-pointer top-15 right-0`}
  `,
  active &&
    css`
      ${tw`transform rotate-180`}
    `
]);

export const saveField = styled.div<Props>(({ isSaved }) => [
  css`
    ${tw`flex items-center ml-16`}

    svg {
      ${tw`w-18 h-13`}
      path {
        ${tw`fill-gray-400`}
      }
    }

    span {
      ${tw`text-18 font-semibold leading-28 ml-11 text-gray-400`}
    }
  `,
  isSaved &&
    css`
      span {
        ${tw`opacity-100 text-gray-200`}
      }

      svg {
        path {
          ${tw`fill-green`}
        }
      }
    `
]);
