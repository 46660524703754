import React, { FunctionComponent, ChangeEvent } from 'react';
import Wrapper from './checkbox-radio';

interface Props {
  value?: number | string;
  name?: string;
  checked?: boolean;
  small?: boolean;
  disabled?: boolean;
  radio?: boolean;
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  alt?: boolean;
}

const CbRadio: FunctionComponent<Props> = ({
  value,
  name,
  checked,
  alt = false,
  onChange = () => {},
  children,
  disabled = false,
  radio,
  small
}) => {
  return (
    <Wrapper alt={alt ? 1 : 0} small={small} disabled={disabled} radio={radio}>
      <input
        type={radio ? 'radio' : 'checkbox'}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />

      <span></span>
      <span>{children}</span>
    </Wrapper>
  );
};

export default CbRadio;
