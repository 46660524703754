import React from 'react';
import Button from '../../../components/Button';
import * as S from './links-expiration';
import { ReactComponent as LinkExpired } from '../../../images/link-expired.svg';
import { routePaths } from '../../../utils/routes.util';

interface Props {
  linkType: string;
  errorMsg: string;
}
const RegistrationLinks = ({ linkType, errorMsg }: Props) => {
  const textMsg = errorMsg.split('Reset link expired.')[1];

  return (
    <>
      {linkType === 'REGISTRATION_OPEN' ? (
        <S.content>
          <S.formTitle>
            <S.title>Link already opened!</S.title>
            <S.subtitle>
              Sorry, your registration link has been already opened!
            </S.subtitle>
            <Button to="/">Go to login</Button>
          </S.formTitle>
          <LinkExpired />
        </S.content>
      ) : linkType === 'REGISTRATION_EXPIRED' ? (
        <S.content>
          <S.formTitle>
            <S.title>Link expired!</S.title>
            <S.subtitle>Sorry, your registration link has expired!</S.subtitle>
            <Button to="/">Go to login</Button>
          </S.formTitle>
          <LinkExpired />
          </S.content>
        ) : linkType === 'INVITATION_REVOKED' ? (
          <S.content>
            <S.formTitle>
              <S.title>Link revoked!</S.title>
              <S.subtitle>The project you are looking for is no longer accessible. Please reach out to your contact at Consulteer.</S.subtitle>
              <Button to="/">Go to login</Button>
            </S.formTitle>
            <LinkExpired />
          </S.content>
      ) : linkType === 'RESET_PASS_OPEN' ? (
        <S.content>
          <S.formTitle>
            <S.title>Link already opened!</S.title>
            <S.subtitle>
              Sorry, your reset password link has been already opened!
            </S.subtitle>
            <Button to="/">Go to login</Button>
          </S.formTitle>
          <LinkExpired />
        </S.content>
      ) : linkType === 'RESET_PASS_EXPIRED' ? (
        <S.content>
          <S.formTitle>
            <S.title>Link expired!</S.title>
            <S.subtitle>{textMsg}</S.subtitle>
            <S.hint>
              Magic link expires in every 72 hours and can only be used once.
              You can create one by clicking on Request button too.
            </S.hint>
            <Button to={routePaths.RESET_PASSWORD}>Request Another Link</Button>
          </S.formTitle>
          <LinkExpired />
        </S.content>
      ) : null}
    </>
  );
};

export default RegistrationLinks;
