import React from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { ProjectEvents } from '../../../store/projects/projects.types';
import { fetchIcon, colors } from '../../../utils/event.utils';
import DeleteEvent from './DeleteEvent';
import * as S from './project-duration';

interface Props {
  data: ProjectEvents;
  projectStart?: null | Date;
  nextEvent?: ProjectEvents;
  prevEvent?: ProjectEvents;
}

const Event = ({
  data: { id, name, iconName, iconColor, lineColor, date },
  projectStart,
  nextEvent,
  prevEvent
}: Props) => {
  const leftPos =
    projectStart && date
      ? (differenceInCalendarDays(new Date(date), projectStart) / 7) * 10
      : 0;
  const Icon = fetchIcon(iconName);

  let showFullEvent: boolean = true;

  if (
    prevEvent &&
    differenceInCalendarDays(new Date(date!), new Date(prevEvent.date!)) < 4
  )
    showFullEvent = false;

  if (
    nextEvent &&
    differenceInCalendarDays(new Date(nextEvent.date!), new Date(date!)) < 4
  )
    showFullEvent = false;

  return (
    <S.event
      iconColor={colors[iconColor]}
      inChart={!!date}
      showFullEvent={showFullEvent}
      style={{ transform: `translateX(${leftPos}rem` }}
    >
      {showFullEvent ? (
        <>
          <S.eventLine lineColor={colors[lineColor]} isSidebar={true} />
          <Icon />
          {name}
          <DeleteEvent eventId={id} />
        </>
      ) : (
        <>
          <S.eventLine lineColor={colors[lineColor]} isSidebar={true}>
            <S.deleteEventColoredWrap>
              <DeleteEvent eventId={id} />
            </S.deleteEventColoredWrap>
          </S.eventLine>

          <S.eventIconColoredWrap>
            <Icon />
            {name}
          </S.eventIconColoredWrap>
        </>
      )}
    </S.event>
  );
};

export default Event;
