import tw, { styled, css } from 'twin.macro';
import { Dropdown } from '../../../components/Actions/actions-component';
import { StyledButton } from '../../../components/Button/styles';

export const wrapper = styled.div(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    }
  }) => [
    css`
      ${tw`w-full lg:mr-30`};
      max-width: 34.8rem;

      ${Dropdown} {
        ${tw`pt-15 px-20! pb-30`}
        max-width: none;
        width: calc(100% + 3.2rem);
        right: -1.7rem;

        ${StyledButton} {
          ${tw`mt-0`}
        }
      }

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `
  ]
);

export const roles = styled.div(() => [
  css`
    ${tw`flex justify-between items-center w-full mb-15`};
  `
]);

export const title = styled.div(() => [
  css`
    ${tw`w-full mb-25 text-textBlack text-32 leading-36 font-semibold`};
  `
]);
