import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    ${tw`absolute z-20 flex justify-between items-center w-full`};
    height: 3.2rem;

    svg {
      ${tw`transform cursor-pointer first:rotate-180`}
    }
  `
]);
