import React, { FormEvent, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AssociatedValueDropdown from '../../../components/AssociatedValueDropdown';
import Button from '../../../components/Button/Button';
import { TextInput } from '../../../components/Inputs';
import useTaskRoles from '../../../hooks/useTaskRoles';
import { addTaskToPhase } from '../../../store/projects/projects.actions';
import { RoleType } from '../../../store/roles/roles.types';
import { AssociatedValueOption } from '../../../types/util.types';
import { validatePrice } from '../../../utils/price.utils';
import { validatePd } from '../../../utils/personDays.utils';
import { ProgressContext } from '../Progress';
import * as S from './project-phases';

interface Props {
  phaseId: number;
  closeModal: () => void;
}

const AddCt = ({ phaseId, closeModal }: Props) => {
  const { id } = useParams<{ id: string }>();

  const { updateProject, project: { currency } } = useContext(ProgressContext);

  const { taskRoles } = useTaskRoles();

  const [name, setName] = useState<string>('');
  const [selectedRoles, setSelectedRoles] = useState<AssociatedValueOption[]>([]);
  const [roleOptions, setRoleOptions] = useState<AssociatedValueOption[]>([]);
  const [effortOptions, setEffortOptions] = useState<AssociatedValueOption[]>([]);
  const [selectedEffort, setSelectedEffort] = useState<AssociatedValueOption[]>([]);

  const [isForceOpen, setIsForceOpen] = useState<boolean>(false);


  useEffect(() => {
    setRoleOptions(convertRoleOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskRoles])

  useEffect(() => {
    if (selectedRoles.length >= 1) setIsForceOpen(true);
    setEffortOptions(selectedRoles.map((elem: AssociatedValueOption) => ({ ...elem, value: 1 })));
    setSelectedEffort([]);
  }, [selectedRoles])

  const convertRoleOptions = (): any[] => {
    return taskRoles.map((elem: RoleType) => ({
      id: elem.id,
      name: elem.name,
      value: elem.price
    }));
  }

  const convertRoleEffort = (): any[] => {
    return selectedRoles.map((e, i) => {
      return {
        roleId: e.id,
        personDays: +selectedEffort[i]?.value!,
        price: +e.value!
      };
    });
  }

  const confirm = async (ev: FormEvent) => {
    ev.preventDefault();
    const data = await addTaskToPhase(id, phaseId, name, 0.2, convertRoleEffort());
    if (!!data) {
      updateProject(data);
      closeModal();
    }
  }

  const isInvalidRoles = (option: AssociatedValueOption) => {
    return !option.value || +option.value <= 0 || !validatePrice(option.value, 10000);
  }

  const isInvalidEffort = (option: AssociatedValueOption) => {
    return !option.value || +option.value <= 0 || !validatePd(option.value);
  }

  const validateRoles = () => {
    return !roleOptions?.find((el: AssociatedValueOption) => isInvalidRoles(el));
  };

  const validateEffort = () => {
    return !effortOptions?.find((el: AssociatedValueOption) => isInvalidEffort(el));
  };

  let isDisabled = !name || selectedRoles.length === 0 || selectedEffort.length === 0 || !validateRoles() || !validateEffort();

  return (
    <S.modalWrapper wide>
      <h3>Create new custom task</h3>
      <S.ctForm onSubmit={confirm}>
        <TextInput
          value={name}
          name="name"
          label="Name of the Custom task"
          disableAutoComplete
          onChange={(e) => setName(e.target.value)}
        />
        <S.dropdownWrapper>
          <AssociatedValueDropdown
            isInvalid={isInvalidRoles}
            label="Select roles"
            checkbox
            labelColor="#0A0A33"
            options={roleOptions}
            setOptions={setRoleOptions}
            values={selectedRoles}
            setValues={setSelectedRoles}
            initials={convertRoleOptions()}
            unit={currency}
            config={{ numeric: true, step: 2 }}
            validate={validateRoles}
          />
          <AssociatedValueDropdown
            isInvalid={isInvalidEffort}
            label="Select effort"
            labelColor="#0A0A33"
            options={effortOptions}
            setOptions={setEffortOptions}
            values={selectedEffort}
            setValues={setSelectedEffort}
            unit="PD"
            validate={validateEffort}
            config={{ numeric: true, step: 2 }}
            forceOpen={isForceOpen}
            setForceOpen={setIsForceOpen}
          />
        </S.dropdownWrapper>
        <Button secondary type="submit" disabled={isDisabled}>
          Confirm
        </Button>
      </S.ctForm>
    </S.modalWrapper>
  );
};

export default AddCt;
