import React, { ChangeEvent } from 'react';
import { ProjectQualityLog } from '../../../store/projects/projects.types';
import DaysLog from './DaysLog';
import * as S from './phase-method';

interface Props {
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  personDays: number | string;
  disabled?: boolean | undefined;
  log?: ProjectQualityLog | null,
}

const MethodDays = ({ onChange, personDays, log, disabled }: Props) => {
  return (
    <S.pd editable={disabled}>
      {!!log && <DaysLog log={log} />}
      <input onChange={onChange} value={personDays} disabled={disabled} />
      <span>pd</span>
    </S.pd>
  );
};

export default MethodDays;
