import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import Button from '../Button';
import uploadWhite from '../../images/upload-white.svg';
import closeIcon from '../../images/close-x.svg';
import uploadDark from '../../images/upload.svg';
import * as S from './picture-cropper';

interface Props {
  onClose: () => void;
  selectedImage?: null | string;
  onCropSuccess: (file: any, cropPreview?: any) => void;
  aspect: number;
  title: string;
  description: string;
  uploadDefinition: string;
  secondary?: boolean;
}

const PictureCropper = ({
  onClose,
  selectedImage,
  onCropSuccess,
  aspect,
  title,
  description,
  uploadDefinition,
  secondary
}: Props) => {
  const [image, setImage] = useState<any>(selectedImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedArea, setCroppedArea] = useState(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const croppedImageRef = useRef<any>(null);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels);
  };

  const createImage = (url: any) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });

  const getCroppedImg = async (imageSrc: string, pixelCrop: any) => {
    const image: any = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    croppedImageRef.current = canvas.toDataURL('image/jpg');

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob: any) => {
          const file = new File([blob], 'crop.jpeg');
          resolve(file);
        },
        'image/jpeg',
        0.75
      );
    });
  };

  const onCrop = async () => {
    try {
      setSubmitting(true);
      const img = await getCroppedImg(image, croppedArea);
      await onCropSuccess(img, croppedImageRef.current);
      onClose();
    } catch {
      console.warn('Crop Failed');
      setSubmitting(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpeg',
    noClick: !!image,
    onDropRejected: () => {
      console.warn('Image rejected');
    },
    onDropAccepted: (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result;
        setImage(url);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  });

  return (
    <S.modalWrapper>
      <img src={closeIcon} alt="" onClick={onClose} />
      <S.modalContent>
        <h4>{title}</h4>
        <S.modalDrop
          {...getRootProps({ refKey: 'innerRef' })}
          hasImage={!!image}
        >
          <input {...getInputProps()} />

          {image ? (
            <Cropper
              crop={crop}
              onCropChange={setCrop}
              image={image}
              aspect={aspect}
              onCropComplete={onCropComplete}
            />
          ) : (
            <>
              <p>{description}</p>
              <img src={uploadDark} alt="" />
            </>
          )}
        </S.modalDrop>
        <Button
          small
          onClick={onCrop}
          disabled={!image || submitting}
          secondary={secondary}
        >
          <img src={uploadWhite} alt="" />
          {uploadDefinition}
        </Button>
      </S.modalContent>
    </S.modalWrapper>
  );
};

export default PictureCropper;
