import tw, { styled, css } from 'twin.macro';

export const wrap = styled.div(() => [
  css`
    @media print {
      page-break-inside: avoid;
    }
  `
]);

export const contactWrap = styled.div(() => [
  css`
    ${tw`mx-37 mb-31`};

    h1 {
      ${tw`text-22 text-textBlack font-bold mb-20 leading-none`};
      letter-spacing: 0.29px;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`mx-16`}

      h1 {
        ${tw`text-14 mb-10`}
      }
    }
  `
]);

export const contactBody = styled.div(() => [
  css`
    ${tw`bg-white flex flex-row px-24 py-24`}

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`px-16 py-17`}
    }
  `
]);

export const contactPicture = styled.img(() => [
  css`
    ${tw`rounded-4 mr-40`}
    height: auto;
    width: 15rem;

    @media screen and (max-width: 575px) and (min-width: 320px) {
      margin-right: 1rem;
      width: 8rem;
      height: 8rem;
    }
  `
]);

export const contactInfo = styled.div(() => [
  css`
    ${tw`pt-10`}

    h6 {
      ${tw`text-18 text-textBlack font-semibold`}
    }

    h4 {
      ${tw`text-18 text-textBlack font-normal`}
      letter-spacing: 0.33px;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`pt-0`}
      h6 {
        ${tw`text-12`}
      }

      h4 {
        ${tw`text-12 mb-0`}
      }
    }
  `
]);

export const contactMail = styled.div(() => [
  css`
    ${tw`flex mt-16 pl-2 py-1 items-center`}

    path {
      ${tw`fill-black`}
    }

    span {
      ${tw`ml-20 text-18 font-normal text-textBlack`}
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      margin-top: 1.2rem;

      span {
        ${tw`text-10 ml-10`}
      }

      svg {
        width: 1.5rem;
      }
    }
  `
]);

export const footedBottomWrap = styled.div(() => [
  css`
    ${tw`w-full bg-darkBlue flex justify-between items-center py-5 px-34`};

    div {
      ${tw`flex items-center`};

      & > span:nth-of-type(1) {
        ${tw`mr-5`};
      }
    }

    span {
      ${tw`text-white font-semibold text-14`};
      letter-spacing: 0.32px;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`px-16`}

      span {
        ${tw`text-9`}
      }
    }
  `
]);
