import { IPublicClientApplication } from "@azure/msal-browser";
import React from "react";
import useGlobal from "../store";
import { toastUtil } from "../utils/toast.util";

interface AzureADOAuth2ProviderProps {
    instance: IPublicClientApplication | null,
    children?: any
};

interface AzureADOAuth2ContextProps {
    instance: IPublicClientApplication | null;
};

export const AzureADOAuth2Context = React.createContext<AzureADOAuth2ContextProps>({
    instance: null
});

export const AzureADOAuth2Provider = ({ instance, children }: AzureADOAuth2ProviderProps) => {
    const [state, actions] = useGlobal();

    if (!state.auth.loggedIn) {
        instance?.handleRedirectPromise()
            .then((response) => {
                !!response && actions.azureADLogin(response);
            })
            .catch((error) => {
                console.error(error);
                toastUtil('error', 'Could not login user via AD');
            });
    }

    return (
        <AzureADOAuth2Context.Provider value={{ instance }}>
            {children}
        </AzureADOAuth2Context.Provider>
    );
}