import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Actions from '../../../components/Actions';
import { Project } from '../../../store/projects/projects.types';
import {
  toggleFavorite,
  archiveProject,
  reactivateProject,
  removeProject
} from '../../../store/projects/projects.actions';
import * as S from './project-item';
import star from '../../../images/star-outline.svg';
import starFilled from '../../../images/star-filled.svg';
import useGlobal from '../../../store';
import { toastUtil } from '../../../utils/toast.util';
import { isAdmin, isMemberRole, isSuperAdmin } from '../../../utils';
import SimpleModal from '../../../components/Modal/SimpleModal/SimpleModal';
import { ReactSVG } from 'react-svg';

interface Props extends Project {
  listView: boolean;
  id: number;
  onChangeCallback: () => Promise<any>;
}

const ProjectItem = ({
  id,
  name,
  favorite,
  archived,
  listView,
  onChangeCallback
}: Props) => {
  const [user] = useGlobal((state) => state.auth.currentUser);

  const [open, setOpen] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);


  const [showReactivateModal, setShowReactivateModal] = useState<boolean>(false);
  const [isActivating, setIsActivating] = useState<boolean>(false);

  const toggle = () => setOpen(!open);
  const close = () => {
    if (open) setOpen(false);
  };

  const toggleReactivateModal = () => {
    setShowReactivateModal(!showReactivateModal);
    setOpen(false)
  };

  const setFavorite = async () => {
    try {
      await toggleFavorite(user?.accountId, id, !favorite);
      await onChangeCallback();
      toastUtil('success', 'Success', '', 1000);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const archive = async () => {
    try {
      await archiveProject(id);
      await onChangeCallback();
      close();
      toastUtil('success', 'Successfully archived', '', 1500);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteProject = async () => {
    await removeProject(id);
    await onChangeCallback();
    setShowDeleteModal(false);
  }
  
  const reactivate = async () => {
    setIsActivating(true)
    await reactivateProject(id);
    setIsActivating(false)
    await onChangeCallback();
    toastUtil('success', 'Project successfully activated', '', 1500);
  };

  return (
    <>
      <S.wrapper listView={listView}>
        <S.title list={listView ? 1 : 0} to={`/projects/${id}`}>
          {name}
        </S.title>
        <S.footer listView={listView}>
          <ReactSVG src={favorite ? starFilled : star} onClick={setFavorite} />
          {!archived && (
            <Actions
              listView={listView}
              toggle={toggle}
              open={open}
              close={close}
            >
              <Link to={`/projects/${id}`}>Go to Project example</Link>
              {!isMemberRole(user) &&
                <>
                  <div onClick={archive}>Archive</div>
                  <Link to={`/projects/${id}/edit`}>Edit project data</Link>
                </>}
              {(isSuperAdmin(user) || isAdmin(user)) &&
                <div onClick={() => { setShowDeleteModal(true) }}>Delete Project</div>}
            </Actions>
          )}
          {(archived && !isMemberRole(user)) &&
            <Actions
              listView={listView}
              toggle={toggle}
              open={open}
              close={close}
            >
              <div onClick={toggleReactivateModal}>Reactivate</div>
            </Actions>
          }
        </S.footer>
      </S.wrapper>
      
      <SimpleModal
        isOpen={showReactivateModal}
        onDismiss={toggleReactivateModal}
        confirm={reactivate}
        disabled={isActivating}
      >
        <p>Are you sure that you want to reactivate this project?</p>
      </SimpleModal>
      
      <SimpleModal
        isOpen={showDeleteModal}
        onDismiss={() => { setShowDeleteModal(false) }}
        confirm={deleteProject}
      >
        <p>Are you sure you want to delete this project?</p>
      </SimpleModal>
    </>
  );
};

export default ProjectItem;
