import { DropdownCbOption } from '../../types/util.types';
// import { AppState } from '..';

interface GetAllArgs {
  page: number;
  size: number;
  qualityType: string;
  level: string;
  sort: string;
  serviceType: string;
  name: string;
}

export type GetAllServices = ({
  page,
  size,
  level,
  qualityType,
  sort,
  serviceType
}: GetAllArgs) => Promise<any>;

export interface AllServices {
  id: number;
  name: string;
  level: string;
  qualities?: Complexity[];
  disciplines: Disciplines[];
  qualityMeasurements: Level[];
  serviceType: string;
  deliverables: Level[];
  description: string;
  duration: number;
  memberRoles?: MemberRole[];
  servicePackageMemberRoles?: SPMemberRole[];
  personDaysPmQm: number;
  phases: Phases[];
  complexities?: Complexity[];
  comment?: string;
  methods?: MethodArgs[];
}

// method

interface Phases {
  id: string;
  title: string;
}

export interface Level {
  id: string;
  name: string;
}

export interface MemberRole {
  memberRoleId: number;
  name?: string;
  personDays: number | string;
}
export interface SPMemberRole {
  name: string;
  personDays: number | string;
}

export interface Complexity {
  type: string;
  personDays: string | number;
  price: string | number;
  id?: number;
  picked?: boolean;
}

export interface BulletListItem {
  name: string;
}

export type CreateMethod = (
  name: string,
  level: DropdownCbOption[],
  disciplines: DropdownCbOption[],
  phase: DropdownCbOption[],
  memberRoles: MemberRole[],
  personDaysPmQm: string,
  duration: string,
  complexityStandards: Complexity[],
  description: string,
  deliverables: string,
  qualityMeasurements: string,
  id?: number
) => Promise<any>;

export const methodLevels: Level[] = [
  {
    id: 'STARTER',
    name: '.starter'
  },
  {
    id: 'LIGHTHOUSE',
    name: '.lighthouse'
  },
  {
    id: 'TEASER',
    name: '.teaser'
  },
  {
    id: 'LEAN',
    name: '.lean'
  },
  {
    id: 'WIZARD',
    name: '.wizard'
  },
  {
    id: 'DESIGN_TO_COST',
    name: '.design-to-cost'
  }
];

interface Quality {
  id: number;
  type: string;
  personDays: number;
  price: number;
  picked: boolean;
}
export interface Disciplines {
  id: number;
  name: string;
}

export interface Method {
  id: number;
  name: string;
  level: string;
  quality: Quality;
  disciplines: Disciplines[];
}
interface GetMethodArgs {
  page: number;
  size: number;
  name?: string;
  sort?: boolean;
  qualityType?: string;
}

export type GetMethod = ({
  page,
  size,
  name,
  sort,
  qualityType
}: GetMethodArgs) => Promise<any>;

export type DuplicateArgs = (id: number) => Promise<any>;
// SP

export interface Qualities {
  id: number;
  type: string;
  personDays: number;
  price: number;
}

interface MethodArgs {
  id: number;
  name: string;
  minPersonDays: number;
  memberRoles: MemberRole[];
  qualities: Qualities[];
}
export interface ComplexitySP {
  personDays: number;
  price: number;
}

export interface MultipleInputs {
  method: MethodArgs | null;
  methodOptions: MethodArgs[];
}

export type CreateServicePackage = (
  name: string,
  level: DropdownCbOption[],
  methods: any,
  comment: string,
  id?: number
) => Promise<any>;

// global
export interface ServicesState {
  phases: DropdownCbOption[];
  disciplines: DropdownCbOption[];
  memberRoles: MemberRole[];
}

export interface ServicesActions {
  getDisciplinesRolesPhases: () => Promise<any>;
}
