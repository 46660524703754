import React, { useState, ChangeEvent, FormEvent, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { SetStateType } from '../../types/util.types';
import * as S from './top-bar';
// import bell from '../../images/bell-notification.svg';
// import question from '../../images/question.svg';
import search from '../../images/search.svg';
import clear from '../../images/close-x-white.svg';

interface Props {
  setSearch?: SetStateType<string>;
  placeholder?: string;
  title?: string;
  alt?: boolean;
}

const TopBar = ({
  setSearch = () => {},
  placeholder = '',
  title,
  alt
}: Props) => {
  const ref = useRef(null);
  const [val, setVal] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setVal(e.target.value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setSearch(val);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const onClear = () => {
    setVal('');
    setIsFocused(false);
  };

  useOnClickOutside(ref, handleBlur);

  return (
    <S.wrapper alt={alt}>
      {title ? (
        <h2>{title}</h2>
      ) : (
        <S.searchWrapper
          onSubmit={handleSubmit}
          isFocused={isFocused}
          ref={ref}
        >
          <img src={search} alt="" onClick={() => setSearch(val)} />
          <input
            value={val}
            onChange={handleChange}
            placeholder={placeholder}
            onFocus={handleFocus}
          />
          <img src={clear} alt="" onClick={onClear} />
        </S.searchWrapper>
      )}
      {/* {!isFocused && (
        <S.actions>
          <img src={bell} alt="" />
          <img src={question} alt="" />
        </S.actions>
      )} */}
    </S.wrapper>
  );
};

export default TopBar;
