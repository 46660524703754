import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    }
  }) => [
    css`
      ${tw`w-full`};
      max-width: 25.5rem;

      &:not(:last-child) {
        ${tw`md:mr-30`}
      }

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `
  ]
);

export const title = styled.div(() => [
  css`
    ${tw`w-full mb-25 text-textBlack text-32 leading-36 font-semibold`};
  `
]);
