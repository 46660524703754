import React from 'react'
import ContentLoader from 'react-content-loader'

const ListLoader = (props: any) => {
  return (
    <ContentLoader
      width='98%'
      height={85}
      {...props}
    >
      <rect x="70" y="15" rx="5" ry="5" width="100%" height="15" />
      <rect x="70" y="39" rx="5" ry="5" width="90%" height="9" />
      <rect x="20" y="10" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  )
}

export default ListLoader