import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as S from './project-proposal-card';
import Actions from '../../../components/Actions';
import { Project } from '../../../store/projects/projects.types';

interface Props extends Project {
  listView: boolean;
  id: number;
  onChangeCallback: () => Promise<any>;
  showSummary?: boolean;
}

const ProjectProposalCard = ({
  id,
  name,
  listView,
  showSummary
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => setOpen(!open);
  const close = () => {
    if (open) setOpen(false);
  };

  return (
    <S.wrapper listView={listView}>
      <S.title list={listView ? 1 : 0} summary={showSummary ? 1 : 0} to={`/client-projects/${id}/summary`}>{name}</S.title>
      {
        !showSummary && <S.message list={listView ? 1 : 0}>Project proposal is being prepared</S.message>
      }
      <S.footer listView={listView}>
      {
        showSummary && (
          <Actions
            listView={listView}
            toggle={toggle}
            open={open}
            close={close}
          >           
            <Link to={`/client-projects/${id}/summary`}>Go to Project Summary</Link>           
          </Actions>
        )
      }
      </S.footer>
    </S.wrapper>
  );
};

export default ProjectProposalCard;
