import React, { ChangeEvent } from 'react';
import useGlobal from '../../../store';
import { SetStateType } from '../../../types/util.types';
import { isClientRole } from '../../../utils/index';
import { CbRadio } from '../../../components/Inputs';
import { ProjectSummaryCalculation, ProjectSummaryCalculationItem } from '../../../store/projects/projects.types';
import images from '../../../utils/images.utils';
import SummaryCalculationItem from './SummaryCalculationItem';

import * as S from './summary-calculation';

interface Props {
  data: ProjectSummaryCalculation;
  showCostToClient: boolean;
  setShowCostToClient: SetStateType<boolean>;
  showEffortToClient: boolean;
  setShowEffortToClient: SetStateType<boolean>;
  currency: string;
}

const SummaryCalculation = ({
  data: {
    summaryCalculationEffort,
    summaryCalculationCost,
    effortHidden,
    costHidden
  },
  currency,
  showCostToClient,
  setShowCostToClient,
  setShowEffortToClient,
  showEffortToClient
}: Props) => {
  const [user] = useGlobal((state) => state.auth.currentUser);

  const hideProjectCalculation =
    effortHidden && costHidden && isClientRole(user);

  const displayCost =
    (showCostToClient && !isClientRole(user)) ||
    (!costHidden && isClientRole(user));

  const displayEffort =
    (showEffortToClient && !isClientRole(user)) ||
    (!effortHidden && isClientRole(user));

  const hidePrint = !showEffortToClient && !showCostToClient;

  const hasOnlyCT = (calculationItem: ProjectSummaryCalculationItem) => {
    return calculationItem?.tasksCalculation > 0 && calculationItem?.methodsCalculation === 0;
  }

  const displayVatText = () => {
    if (!hasOnlyCT(summaryCalculationCost) && !hasOnlyCT(summaryCalculationEffort)) return "incl. Project Management and Quality Measurements";
  };

  return (
    <>
      {!hideProjectCalculation && (
        <S.wrap hidePrint={isClientRole(user) ? false : hidePrint} >
          <h3>Project Effort</h3>
          <S.body>
            {!isClientRole(user) && (
              <S.checkboxWrap>
                <S.buttonLabel>
                  <CbRadio
                    name="effort"
                    small
                    checked={showEffortToClient}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      setShowEffortToClient(evt.target.checked);
                    }}
                  >
                    <S.checkboxText>Show Effort</S.checkboxText>
                  </CbRadio>
                </S.buttonLabel>
                <S.buttonLabel>
                  <CbRadio
                    name="cost"
                    small
                    checked={showCostToClient}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      setShowCostToClient(evt.target.checked);
                    }}
                  >
                    <S.checkboxText>Show Cost</S.checkboxText>
                  </CbRadio>
                </S.buttonLabel>
              </S.checkboxWrap>
            )}
            <S.cardsWrap costToLeft={!showEffortToClient && !isClientRole(user)}>
              {(!effortHidden || !isClientRole(user)) && (
                <S.card active={displayEffort}>
                  {displayEffort && (
                    <SummaryCalculationItem
                      data={summaryCalculationEffort}
                      type="Effort"
                      unit="PD"
                      icon={images.userOutline}
                    />
                  )}
                </S.card>
              )}
              <S.card active={displayCost}>
                {displayCost && (
                  <SummaryCalculationItem
                    data={summaryCalculationCost}
                    type="Cost"
                    unit={currency}
                    icon={images.cost}
                  />
                )}
              </S.card>
            </S.cardsWrap>
            {(!!displayVatText() && (displayEffort || displayCost)) && <S.vatText>{displayVatText()}</S.vatText>}
          </S.body>
        </S.wrap>
      )}
    </>
  );
};

export default SummaryCalculation;
