export const roleLabels: { [key: string]: string } = {
  ROLE_SUPER_ADMIN: 'Super Admin',
  ROLE_ADMIN: 'Project Admin',
  ROLE_PROJECT_MEMBER: 'Project Member',
  ROLE_CLIENT: 'Client',
  ROLE_GUEST: 'Guest'
};

export const roles: { [key: string]: string } = {
  superAdmin: 'ROLE_SUPER_ADMIN',
  admin: 'ROLE_ADMIN',
  member: 'ROLE_PROJECT_MEMBER',
  client: 'ROLE_CLIENT',
  guest: 'ROLE_GUEST'
};

export const validateRoleName = (name: string) => {
  return !!name.match(/^[a-zA-Z\s]*$/);
};
