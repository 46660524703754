import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const wrapper = styled.div(() => [
  css`
    ${tw` flex flex-col mt-40 mx-25 px-15 pb-40 pt-30 bg-white text-textBlack`};
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  `
]);

export const section = styled.div<Props>(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    }
  }) => [
      css`
      ${tw`w-full lg:mr-30`};

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `
    ]
);

export const form = styled.div(() => [
  css`
    ${tw`grid gap-30 w-4/5`};
    grid-template-columns: auto 300px;
  `
]);

export const title = styled.div(() => [
  css`
    ${tw` mb-25 text-textBlack text-32 leading-36 font-semibold`};
  `
]);

export const footer = styled.div(() => [
  css`
    ${tw`flex items-start self-end w-full`};
    grid-column: 2/3
  `
]);

export const footerActions = styled.div(() => [
  css`
    ${tw`flex flex-col justify-end w-full sm:flex-row mt-24`};

    button {
      ${tw`w-full sm:w-auto first:mb-24 sm:first:mb-0`}
    }
  `
]);

