import React from 'react';
import { Redirect, RouteComponentProps, Route } from 'react-router-dom';
import useGlobal from '../../../store';
import { NonAuthRoutes, AuthRoutes } from  '../../../utils/routeEnums.utils'
interface Props {
	Component: any
	path: string;
    exact?: boolean;
	requiredRoles: string[];
}

const AuthRoute = ({ Component, path, exact = false, requiredRoles }: Props): JSX.Element => {
    const isAuthed = !!localStorage.getItem('cpcToken');
	const [user] = useGlobal((state) => state.auth.currentUser);
	const userHasRequiredRole = requiredRoles.includes(user?.role as string);

	const handlePathName = () => {
		if (isAuthed && !userHasRequiredRole) {
			return  AuthRoutes.unauthorized;
		}
		return  NonAuthRoutes.login;
	}

	if (isAuthed) {
		if (!user) {
			return (
				<></>
			);
		}
	}

	return (
		<Route
			exact={exact}
			path={path}
			render={(props: RouteComponentProps) =>
				(isAuthed && userHasRequiredRole) ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: handlePathName(),
							state: { 
								requestedPath: path 
							}
						}}
					/>
				)
			}
		/>
	);
};

export default AuthRoute