import * as Yup from 'yup';

const emailDomainRegex = /@consulteer.com|schindlercreations.com$/;

const initialValidation = Yup.object().shape({
  email: Yup.string()
    .email('Email is not valid')
    .matches(
      emailDomainRegex,
      'Domain must be consulteer.com or schindlercreations.com'
    )
    .required('Email field is required'),
  firstName: Yup.string().required('First name field is required'),
  lastName: Yup.string().required('Last name field is required')
});

export { initialValidation };
