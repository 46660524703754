import React, { useState } from 'react';
import Actions from '../../../components/Actions';
import {ProjectMemberRoles} from '../../../store/projects/projects.types';
import * as S from './phase-method';

interface Props {
  members: ProjectMemberRoles[];
}

const MethodMembers = ({members}: Props) => {

  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => setOpen(!open);
  const close = () => {
    if (open) setOpen(false);
  };

  return (
    <S.membersWrapper open={open}>
      Members
       <Actions toggle={toggle} open={open} close={close} dropdown>
        {members.map((m: ProjectMemberRoles) => 
          <S.memberItem key={m.memberRoleId}>{m.name} <span>min {m.personDays}</span></S.memberItem>
        )}
        </Actions>
    </S.membersWrapper>
  );
};

export default MethodMembers;