import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const content = styled.div(() => [
  css`
    ${tw`relative bg-gray-800 text-textBlack p-20 w-full border-darkBlue border-2`};
    border-radius: 2px;
    max-width: 635px;
  `
]);

export const title = styled.div<Props>(({ red }) => [
  css`
    ${tw`flex flex-col items-center lg:flex-row`};
    > div {
      ${tw`mr-20`}
    }

    h4 {
      ${tw`text-4xl lg:text-5xl font-bold tracking-wide leading-48 mb-0`}
    }

    svg {
      width: 48px;
      height: 48px;
      path {
        ${red ? tw`fill-error` : tw`fill-green`}
      }
    }
  `
]);

export const buttonContainer = styled.div(() => [
  css`
    ${tw`flex justify-end mt-20`};

    button {
      ${tw`mx-10`}
      min-width:140px;
    }
    button:last-child {
      ${tw`bg-green text-white`}
    }
  `
]);

export const hint = styled.div(() => [
  css`
    ${tw`flex text-textBlack leading-22  text-18`}
    p {
      ${tw`ml-2 leading-22 font-semibold text-14`}
      letter-spacing: 0.64px;
    }
  `
]);