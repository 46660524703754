import React, { useState, useMemo } from 'react';
import { DialogContent } from '@reach/dialog';
import { motion, AnimatePresence } from 'framer-motion';
// import '@reach/dialog/styles.css';

import CloseIcon from '../images/close-x.svg';

import ErrorIcon from '../images/error.svg';
import Button from '../components/Button';
import {
  DialogReachOverlay,
  Icon,
  Title,
  ButtonContainer,
  ModalContent,
  CloseModalIcon
} from './styled.modal.provider';

interface OpenModalArg {
  text?: string;
  isError?: boolean | null;
  icon?: string | undefined;
  buttons?: any | undefined;
  isSmall?: boolean | null;
}

interface ModalProviderProps {
  openModal: ({ text, isError, icon, buttons, isSmall }: OpenModalArg) => void;
  closeModal?: () => void;
  isError: boolean | null;
  text: string;
  icon: string;
  buttons: any;
  isSmall: boolean | null;
}
interface Props {
  children?: any;
}

export const ModalContext = React.createContext<ModalProviderProps>({
  openModal: () => {},
  closeModal: () => {},
  isError: null,
  text: '',
  isSmall: null,
  icon: '',
  buttons: ''
});

const Content = motion.custom(DialogContent);

const ModalProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean | null>(null);
  const [text, setText] = useState<string>('');
  const [icon, setIcon] = useState<string>('');
  const [isSmall, setIsSmall] = useState<boolean | null>(null);
  const [buttons, setButtons] = useState('');

  const values = useMemo(
    () => ({
      openModal: ({ text, isError, icon, buttons, isSmall }: OpenModalArg) => {
        setText(text ? text : '');
        setIsError(isError ? isError : null);
        setIsSmall(isSmall ? isSmall : null);
        setIcon(icon ? icon : '');
        setButtons(buttons);
        setIsOpen(true);
      },
      closeModal: () => {
        setIsOpen(false);
      }
    }),
    []
  );

  return (
    <ModalContext.Provider
      value={{
        ...values,
        isError,
        text,
        icon,
        buttons,
        isSmall
      }}
    >
      {children}
      <AnimatePresence>
        {isOpen && (
          <DialogReachOverlay isOpen={isOpen} onDismiss={values.closeModal}>
            <Content
              initial={{
                opacity: 0,
                maxWidth: !isSmall ? '635px' : '540px',
                width: '100%',
                padding: 0
              }}
              animate={{
                opacity: 1
              }}
              exit={{ opacity: 0 }}
              aria-label="modal-global"
            >
              <div data-dialog-overlay onClick={values.closeModal} />
              <motion.div
                data-dialog-content
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                exit={{ y: -50 }}
                transition={{ type: 'tween' }}
              >
                <ModalContent isError={isError}>
                  <CloseModalIcon src={CloseIcon} onClick={values.closeModal} />
                  <Title>
                    {isError ? <Icon src={ErrorIcon} /> : <Icon src={icon} />}
                    {!isSmall && text ? (
                      <h4>{text}</h4>
                    ) : isError && !text ? (
                      <h4>
                        Something went wrong. <br /> Please try again.
                      </h4>
                    ) : (
                      <h5>{text}</h5>
                    )}
                  </Title>

                  {isError && !buttons ? (
                    <ButtonContainer>
                      <Button small secondary onClick={values.closeModal}>
                        I want to try again
                      </Button>
                    </ButtonContainer>
                  ) : (
                    buttons
                  )}
                </ModalContent>
              </motion.div>
            </Content>
          </DialogReachOverlay>
        )}
      </AnimatePresence>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
