import React, { useState, useContext, FunctionComponent } from 'react'
import { ProgressContext } from '../Progress';
import { SetStateType } from '../../../types/util.types';
import { ExternalCostType } from '../../../store/projects/projects.types';
import AddCost from './AddCost';
import Modal from '../../../components/Modal';
import ExternalCostsList from './ExternalCostsList';
import images from '../../../utils/images.utils';

import * as S from './external-cost';
import ClickableText from '../../../components/ClickableText';

interface Props {
  costs: ExternalCostType[];
  setCosts: SetStateType<ExternalCostType[]>;
}

const ExternalCostsModalsWrapper: FunctionComponent<Props> = ({
  costs,
  setCosts,
  children
}) => {
  const { isMemberRole } = useContext(ProgressContext);
  const [open, setOpen] = useState<boolean>(false);

  const onSuccess = (data: ExternalCostType | ExternalCostType[]) => {
    setCosts(prev => prev.concat(data));
    setOpen(false);
  };

  return (
    <>
      <S.wrapper>
        <h3>External Cost</h3>
        <S.body>
          {children}
          <ExternalCostsList costs={costs} setCosts={setCosts} />
          <S.addCostWrap>
            <ClickableText
              icon={images.addIcon}
              onClick={() => { setOpen(true) }}
              disabled={!!isMemberRole}>
              Add external cost
            </ClickableText>
          </S.addCostWrap>
        </S.body>
      </S.wrapper>
      <Modal isOpen={open} onDismiss={() => { setOpen(false) }}>
        <AddCost onSuccess={onSuccess} />
      </Modal>
    </>
  )
}

export default ExternalCostsModalsWrapper