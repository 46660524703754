import tw, { styled, css } from 'twin.macro';
import { Dropdown } from '../../../components/Actions/actions-component';
// import { StyledProps as Props } from '../../types/util.types';
import { StyledButton } from '../../../components/Button/styles';

interface RoleBasedControlProps {
  disabled?: number;
}

export const wrapper = styled.div(() => [
  css`
    ${tw`mx-30`};

    h3 {
      ${tw`text-textBlack text-48 leading-none mb-28`};
    }
  `
]);

export const body = styled.div(() => [
  css`
    ${tw`
        w-full 
        rounded-4 bg-gray-800 shadow-discipline 
        py-32
        px-32
    `}
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 15%);
    p {
      ${tw`text-textBlack text-32 leading-44 font-normal mb-44`}
    }
  `
]);

export const itemsWrapper = styled.div(() => [
  css`
    ${tw`grid grid-cols-1 sm:grid-cols-2 xxl:grid-cols-4 xl:grid-cols-3 gap-30 mb-32`}
    justify-content: space-evenly;
    justify-items: center;

    div:nth-child(4n) {
      ${Dropdown} {
        ${tw`
        xl:left-auto
        xl:right-100
        xl:-translate-x-4
        `}
      }
    }
  `
]);

export const item = styled.div(() => [
  css`
    ${tw`bg-darkBlue bg-opacity-05 rounded-t-4 w-full`}

    h4 {
      ${tw`text-18 text-textBlack font-semibold leading-28 mb-5`}
    }
  `
]);

export const itemHeader = styled.header(() => [
  css`
    ${tw`
        flex
        items-center
        justify-center
        relative
        `}
    height: 24rem;

    img {
      ${tw`w-full h-full object-contain rounded-t-4 pt-32`}
    }

    span {
      ${tw`text-white lowercase bg-green2 font-semibold p-30 rounded-4 text-48 leading-56`}
    }
  `
]);

export const itemBody = styled.div(() => [
  css`
    ${tw`
      w-full 
      flex flex-col 
      content-center justify-center 
      px-24 py-24
      text-13 text-textBlack leading-28 font-semibold text-center`}

      div:nth-child(3) {
        align-self: flex-end;
      }

      ${Dropdown} {

        div {
          ${tw`w-full p-15 hover:text-green text-left font-semibold cursor-pointer text-18`};
        }
      }
  `
]);

export const itemMain = styled.div(() => [
  css`
    ${tw`
        flex flex-col`}
  `
]);

export const itemFooter = styled.div(() => [
  css`
    ${tw`mt-20 flex flex-col items-center`}

    span {
      ${tw`flex items-center pr-5 break-all`}

      &:first-child:not(:last-child) {
        ${tw`mb-8`}
      }

      svg {
        ${tw`mr-5 w-6 h-6`}
        path {
          ${tw`fill-black`}
        }
      }
    }
  `
]);

export const addMemberWrap = styled.span<RoleBasedControlProps>(
  () => [
    css`
      ${tw`ml-16`}
      width: fit-content;
    `
  ]
);

export const modalWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue w-730 max-w-full p-32`}

    h3 {
      ${tw`text-textBlack text-32 leading-36 font-semibold mb-0`}
    }
    ${StyledButton} {
      ${tw`ml-auto mt-26`}
    }
  `
]);

export const memberInput = styled.div(() => [
  css`
    ${tw`flex items-center relative
        mt-32
        w-full
        pr-50
   `}

    img {
      ${tw`absolute right-0 cursor-pointer`}
    }

    > div {
      ${tw`mb-0`}
    }
  `
]);

export const wrapperItem = styled.span(() => [
  css`
    ${tw`flex items-center
         pl-16 pt-13 pb-12
         text-22 font-semibold text-textBlack
         bg-white
         border-2 border-darkBlue h-17 w-full`}

    img {
      ${tw`mr-16`}
    }
  `
]);

export const modalAddMember = styled.span(() => [
  css`
    ${tw`mt-24`}
  `
]);
