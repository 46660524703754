import React, { useEffect, useState, useRef } from 'react';
import { getAllServices } from '../../store/method-service/actions';
import { Method, Level } from '../../store/method-service/types';
import * as S from './services-page';
import * as D from './Filter/service-filter';
import {
  HeaderWrapper,
  HeaderTop,
  HeaderBottom
} from '../../components/Header/StyledHeader';
import useGlobal from '../../store';
import Button from '../../components/Button';
import TopBar from '../../components/TopBar';
import Actions from '../../components/Actions';
import sortIcon from '../../images/order.svg';
import List from './List';
import { routePaths } from '../../utils/routes.util';
import {
  NUMBER_OF_ITEMS_FOR_PAGINATION,
  PLACEHOLDER_ARRAY
} from '../../utils/general.utils';
import NoResult from '../../components/NoResult';
import { ReactSVG } from 'react-svg';

const sortOptions: Level[] = [
  {
    id: 'NAME',
    name: 'Alphabet'
  },
  {
    id: 'CREATED_DATE',
    name: 'Date'
  }
];

const Services = () => {
  const [, getDisciplinesRolesPhases] = useGlobal(
    () => null,
    (actions) => actions.getDisciplinesRolesPhases
  );
  const first = useRef(true);
  const [services, setServices] = useState<Method[]>([]);
  const [sort, setSort] = useState<string>('NAME');
  const [serviceType, setServiceType] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0);
  const [qualityType, setQualityType] = useState<string>('');
  const [level, setLevel] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const toggle = () => setOpen(!open);
  const close = () => {
    if (open) setOpen(false);
  };
  const toggleSortOption = (id: string) => {
    setSort(id);
    close();
  };

  useEffect(() => {
    getDisciplinesRolesPhases();
    // eslint-disable-next-line
  }, []);

  const fetchAllServices = async () => {
    try {
      const { totalPageNumber, content } = await getAllServices({
        page,
        size: NUMBER_OF_ITEMS_FOR_PAGINATION,
        level,
        qualityType,
        sort,
        serviceType,
        name
      });
      setServices(content);
      setTotalPageNumber(totalPageNumber);
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (first.current) return;
    page === 0 ? fetchAllServices() : setPage(0);
    // eslint-disable-next-line
  }, [qualityType, level, sort, serviceType, name]);

  useEffect(() => {
    first.current = false;
    fetchAllServices();
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <TopBar placeholder="Search by name" setSearch={setName} />
      <HeaderWrapper>
        <HeaderTop>
          <h2>Services</h2>
          <S.buttonContainer>
            <Button small to={routePaths.SERVICES_NEW_METHOD} secondary>
              Create new method
            </Button>
            <Button small to={routePaths.SERVICES_NEW_SERVICE}>
              Create new service package
            </Button>
          </S.buttonContainer>
        </HeaderTop>
        <HeaderBottom>
          <S.headerActions>
            <span>
              <ReactSVG src={sortIcon} />
              Sort by {sort === 'NAME' ? 'Alphabet' : 'Date'}
            </span>
            <Actions toggle={toggle} open={open} close={close} dropdown>
              {sortOptions.map((item) => {
                return (
                  <D.action
                    key={item.id}
                    onClick={() => toggleSortOption(item.id)}
                  >
                    {item.name}
                  </D.action>
                );
              })}
            </Actions>
          </S.headerActions>
        </HeaderBottom>
      </HeaderWrapper>
      <S.wrapper>
        <h3>Service Packages and Methods</h3>
        {services.length === 0 && !isLoading && !!name ? <NoResult searchInput={name} /> :
          <List
            items={services}
            setQualityType={setQualityType}
            totalPageNumber={totalPageNumber}
            page={page}
            setPage={setPage}
            setLevel={setLevel}
            setServiceType={setServiceType}
            placeHolderArray={PLACEHOLDER_ARRAY}
            isLoading={isLoading}
            fetchAllServices={fetchAllServices}
          />
        }
      </S.wrapper>
    </>
  );
};

export default Services;
