import React, { useState, Fragment } from 'react';
import { MemberRole } from '../../../store/method-service/types';
import * as DropdownS from '../../../components/Dropdown/dropdown-component';
import Actions from '../../../components/Actions';
import * as S from './member-field';
import MemberRow from './MemberRow';

interface Props {
  selectedRoles: any;
  label: string;
  title?: string;
  disabled?: boolean;
}

const MemberField = ({ selectedRoles, label, title, ...rest }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<MemberRole[]>([]);

  const toggle = () => setOpen(!open);
  const close = () => {
    if (open) setOpen(false);
  };

  return (
    <S.wrapper {...rest}>
      {title && <S.title>{title}</S.title>}
      <DropdownS.wrapper open={open}>
        <Actions toggle={toggle} open={open} close={close} dropdown>
          {selectedRoles &&
            selectedRoles.length > 0 &&
            selectedRoles.map((role: any, index: number) => {
              return (
                <MemberRow
                  key={index}
                  role={role}
                  setSelected={setSelected}
                  selected={selected}
                />
              );
            })}
        </Actions>
        <DropdownS.label
          hasValue={!!selectedRoles?.length}
          open={open}
          labelColor="#0A0A33"
        >
          {label}
        </DropdownS.label>
        <DropdownS.items>
          {selectedRoles?.map((role: any, index: number) => (
            <Fragment key={index}>
              {role.name} ({role.personDays} pd)
              {index < selectedRoles.length - 1 && ',\u00A0'}
            </Fragment>
          ))}
        </DropdownS.items>
      </DropdownS.wrapper>
    </S.wrapper>
  );
};

export default MemberField;
