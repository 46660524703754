import React from 'react';
import { differenceInCalendarDays } from 'date-fns';
import {
  ProjectProgressPhase,
  ProjectServicePackages
} from '../../../store/projects/projects.types';
import * as S from './project-duration';

interface Props {
  data: ProjectProgressPhase;
  projectStart: Date | null;
}

const Phase = ({
  data: { startDate, endDate, methodsServicePackages },
  projectStart
}: Props) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const leftPos = projectStart
    ? (differenceInCalendarDays(start, projectStart) / 7) * 10
    : 0;
  const width = (differenceInCalendarDays(end, start) / 7) * 10;

  let allMethods: any = methodsServicePackages
    .map((el: ProjectServicePackages) => {
      if (el.phaseItemType === 'SERVICE_PACKAGE') return el.methods;
      return el;
    })
    .flat();

  return (
    <S.phase
      isGrayedOut={!projectStart}
      style={{
        transform: `translateX(${leftPos}rem`,
        width: projectStart ? `${width}rem` : '100rem'
      }}
    >
      {projectStart && allMethods.map((m: any) => <S.method key={m.id} />)}
    </S.phase>
  );
};

export default Phase;
