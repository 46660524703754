import React from 'react';
import { ReactSVG } from 'react-svg';
import { ButtonWrapper } from './styles';

interface Props {
  icon?: string;
  onClick?: any;
  children?: any;
  disabled?: boolean | undefined;
}

const ClickableText = ({ children, icon, onClick, disabled }: Props) => {
  return (
    <ButtonWrapper onClick={onClick} disabled={disabled}>
      {!!icon && <ReactSVG src={icon} />}
      <button disabled={disabled}>{children}</button>
    </ButtonWrapper>
  )
}

export default ClickableText
