import React from 'react';
import { ProjectSummaryCalculation } from '../../../store/projects/projects.types';
import images, { convertImageURI } from '../../../utils/images.utils';
import { format } from 'date-fns';

import * as S from './summary-contact';
import { ReactSVG } from 'react-svg';

interface Props {
  data: ProjectSummaryCalculation;
  projectName: string;
}

const SummaryContact = ({
  data: { updater, updateDate},
  projectName
}: Props) => {

  return (
    <S.wrap>
      <S.contactWrap>
        <h1>Contact</h1>
        <S.contactBody>
          <S.contactPicture src={!!updater?.image ? convertImageURI(updater?.image) : ''} />
          <S.contactInfo>
            <h6>{`${updater?.firstName} ${updater?.lastName}`}</h6>
            <h4>{updater?.position || 'Test'}</h4>
            <S.contactMail>
              <ReactSVG src={images.mail} />
              <span>{updater?.email || ''}</span>
            </S.contactMail>
          </S.contactInfo>
        </S.contactBody>
      </S.contactWrap>
      <S.footedBottomWrap>
        <span>Clever Project Composer</span>
        <span>{projectName}</span>
        <div>
          <span>Creation Date</span>
          <span>{format(new Date(updateDate), 'dd/MM/yyyy')}</span>
        </div>
      </S.footedBottomWrap>
    </S.wrap>
  );
};

export default SummaryContact;
