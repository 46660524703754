import tw, { css, styled } from "twin.macro";

export const options = styled.div(() => [
    css`
      ${tw`flex flex-col justify-center`}
    `
]);

export const other = styled.div(() => [
  css`
    ${tw`self-center text-center text-gray-200 mt-32`}

    > * {
      ${tw`mb-16`}
    }
  `
]);