import React from 'react';
import TopBar from '../../components/TopBar';
import Button from '../../components/Button';
import { HeaderWrapper, HeaderTop } from '../../components/Header/StyledHeader';
import ChangePasswordForm from '../../components/ChangePasswordForm';
import * as S from './profile-page';
import ProfileForm from './ProfileForm';

const PersonalSettings = () => (
  <>
    <TopBar title="Personal Settings" />
    <HeaderWrapper>
      <HeaderTop>
        <h2>Edit Profile</h2>
        <Button small secondary to="/">
          Back to CPC
        </Button>
      </HeaderTop>
    </HeaderWrapper>
    <S.content>
      <div>
        <ProfileForm />
      </div>

      <div>
        <ChangePasswordForm />
      </div>
    </S.content>
  </>
);

export default PersonalSettings;
