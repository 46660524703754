import api from '../../config/api.config';
import { handleErrors } from '../../utils/error.utils';
import { ClientOverview } from './clients.types';

export const clientOverview: ClientOverview = async ({
  page,
  name,
  filter = '',
  size = 12
}) => {
  try {
    const {
      data: { data }
    } = await api(
      `/invitationEmails/overview?page=${page}&size=${size}&filter=${filter}&name=${name}`
    );
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.resolve(error);
  }
};
