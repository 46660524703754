import React, { useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import { ReactSVG } from 'react-svg';
import useGlobal from '../../store';
import UserRole from '../UserRole';
import * as S from './app-sidebar';
import arrow from '../../images/arrow-down.svg';
import { routePaths } from '../../utils/routes.util';
import { convertImageURI } from '../../utils/images.utils';
import { AzureADOAuth2Context } from '../../providers/azure-ad.oauht2.provider';
import { clearStorage } from '../../utils/storage.utils';

interface Props {
  toggleMenu?: () => void;
}

const UserMenu = ({ toggleMenu }: Props) => {
  const [auth, logout] = useGlobal(
    (state) => state.auth,
    (actions) => actions.logout
  );

  const [user] = useGlobal(
    (state) => state.auth.currentUser
  );

  const { instance } = useContext(AzureADOAuth2Context);

  const ref = useRef(null);
  const [active, setActive] = useState<boolean>(false);

  const toggle = () => setActive(!active);
  const close = () => {
    if (active) setActive(false);
  };
  useOnClickOutside(ref, close);

  const handleLogout = async () => {
    if (auth.method === 'AZURE_AD') {
      await instance?.logoutRedirect({
        onRedirectNavigate: () => {
          clearStorage();
          return true;
        }
      });
    }
    logout();
  };

  return (
    <S.userMenuWrapper>
      <div>
        {user?.image && <img src={convertImageURI(user.image)} alt="" />}
        {!user?.image && user?.firstName && (
          <span>{`${user.firstName.substring(0, 2)}${user.lastName.substring(
            0,
            2
          )}`}</span>
        )}
      </div>
      <span>Hello,</span>
      <S.userMenuActions onClick={toggle} ref={ref} active={active}>
        <span>
          {user?.firstName} {user?.lastName} <ReactSVG src={arrow} />
        </span>
        {active && (
          <S.userMenuDropdown>
            <Link to={routePaths.PROFILE} onClick={toggleMenu}>
              Personal Settings
            </Link>
            <span onClick={handleLogout}>
              Sign out of CPC
            </span>
          </S.userMenuDropdown>
        )}
        {user?.role && <UserRole role={user.role} company={user.company} />}
      </S.userMenuActions>

    </S.userMenuWrapper>
  );
};

export default UserMenu;
