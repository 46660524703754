import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useGlobal from '../../store';
import { ProjectSummary } from '../../store/projects/projects.types';
import * as S from './project-summary';
import {
  getSummary,
  updateSummary,
  inviteClientToProject
} from '../../store/projects/projects.actions';
import Button from '../../components/Button';
import SummaryHeader from './SummaryHeader';
import SummaryPhases from './SummaryPhases';
import SummaryContact from './SummaryContact';
import SummaryChart from './SummaryChart';
import SummaryCalculation from './SummaryCalculation';
import ProjectSummaryHeader from './ProjectSummaryHeader';
import { toastUtil } from '../../utils/toast.util';
import { isClientRole } from '../../utils/index';
import { useInterval } from '../../hooks/useInterval';

interface Params {
  id: string;
}

const Summary = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const [summary, setSummary] = useState<ProjectSummary | null>(null);
  const [showEffortToClient, setShowEffortToClient] = useState<boolean>(false);
  const [showCostToClient, setShowCostToClient] = useState<boolean>(false);
  const [isButtonDisabled, setDisabledButton] = useState<boolean>(false);
  const [user] = useGlobal((state) => state.auth.currentUser);

  const getProjectSumary = useCallback(async () => {
    try {
      const response = await getSummary(id);
      setSummary(response);
    } catch (error) {
      console.warn(error);
    }
  }, [id]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getProjectSumary();
    }
    return () => {
      mounted = false;
    };
  }, [getProjectSumary]);

  useInterval(() => {
    if (!isClientRole(user)) {
      return false;
    }
    getProjectSumary();
  }, 1000 * 10);

  const inviteClient = async () => {
    try {
      await inviteClientToProject({
        accountId: summary?.client?.id,
        projectId: parseInt(id)
      });
      //chain invite then update summary beacuse of flag for footer cost
      await updateSummary(id, !showCostToClient, !showEffortToClient);
      toastUtil('success', 'Client Invited');
      history.push(`/projects`);
      window.scrollTo(0, 0);
    } catch (error) {
      console.warn(error);
    }
  };

  const updateProjectSummary = async () => {
    try {
      await updateSummary(id, !showCostToClient, !showEffortToClient);
      toastUtil('success', 'Summary updated');
      history.push(`/projects`);
      window.scrollTo(0, 0);
    } catch (error) {
      console.warn(error);
    }
  };

  const sendClientSummary = async () => {
    try {
      await updateSummary(id, !showCostToClient, !showEffortToClient);
      setDisabledButton(true);
      toastUtil('success', 'Summary Sent');
      history.push(`/projects`);
      window.scrollTo(0, 0);
    } catch (error) {
      console.warn(error);
    }
  };

  if (!summary) return null;

  return (
    <>
      <SummaryHeader
        name={summary.projectName}
        imageUrl={summary.projectImage}
        clientData={summary.client}
      />
      <S.summaryWrapper>
        <ProjectSummaryHeader
          name={summary.projectName}
          imageUrl={summary.projectImage}
          clientData={summary.client}
        />
        <SummaryPhases data={summary.phases} client={summary.client} />
        <SummaryChart
          events={summary.events}
          name={summary.projectName}
          projectDuration={summary.duration}
          phases={summary.phases}
        />
        <SummaryCalculation
          data={summary?.summaryCalculation}
          currency={summary?.currency}
          setShowCostToClient={setShowCostToClient}
          showCostToClient={showCostToClient}
          setShowEffortToClient={setShowEffortToClient}
          showEffortToClient={showEffortToClient}
        />
        <SummaryContact
          projectName={summary.projectName}
          data={summary?.summaryCalculation}
        />
      </S.summaryWrapper>
      <S.summaryButtons>
        {summary.inviteClient && (
          <Button
            type="button"
            onClick={inviteClient}
            disabled={isButtonDisabled}
          >
            Invite client
          </Button>
        )}
        {summary.update && (
          <Button type="button" onClick={updateProjectSummary}>
            Show update to the client
          </Button>
        )}
        {summary.create && (
          <Button
            type="button"
            onClick={sendClientSummary}
            disabled={isButtonDisabled}
          >
            Send One-Page Summary
          </Button>
        )}
      </S.summaryButtons>
    </>
  );
};

export default Summary;
