export const validatePd = (s: string | number) => {
  const pd = s + '';
  var rgx = /^[0-9]*(\.[0-9]{0,2})?$/;
  
  return pd.match(rgx) && +pd < 1000
};

export const numberWithCommas = (n: number) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isInvalidValue = (s: any, min: number = 0) =>
  s * 1 < min || s === '' || s === '.';
