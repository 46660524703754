import tw, { styled, css } from 'twin.macro';
import CbRadio from '../../../components/Inputs/checkbox-radio';
import { StyledProps as Props, StyledProps } from '../../../types/util.types';

export const wrap = styled.div<StyledProps>(({ hidePrint }) => [
  css`
    ${tw`mx-16 sm:mx-37 mb-31 `};

    @media print {
      page-break-inside: avoid;
    }

    h3 {
      ${tw`text-14 sm:text-22 text-textBlack font-bold mb-10 sm:mb-20 leading-none`};
    }
  `, 
  hidePrint && 
  css`
    @media print {
      display: none;
    }
  ` 
]);

export const body = styled.div(() => [
  css`
    ${tw`bg-white flex flex-col px-24 py-24 items-start`}

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`px-16 pt-16`}
    }
  `
]);

export const checkboxWrap = styled.div(() => [
  css`
    ${tw`flex justify-between w-full`}
  `
]);

export const cardsWrap = styled.div<Props>(({ costToLeft }) => [
  css`
    ${tw`flex justify-between items-stretch w-full`}
  `,
  costToLeft &&
    css `
      @media print {
        ${tw`flex-row-reverse`}
      } 
    `
]);

export const staticText = styled.span(() => [
  css`
    ${tw`block text-green text-12 font-semibold mb-8 last:mb-0`};

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`text-9 mt-6`}
    }
  `
]);

export const card = styled.div<Props>(({ active }) => [
  css`
    ${tw`flex items-center flex-wrap gap-20 w-375`};

    @media print {
      ${tw`w-400`}
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`p-10 w-140`}

      img {
        ${tw`h-28 ml-1`}
      }

      div {
        span:nth-of-type(1) {
          ${tw`text-textBlack text-12 font-bold`};
        }
        
        span:nth-of-type(2) {
          ${tw`text-textBlack text-9 font-bold`};
        }
      }
    }
  `,
  active && tw`p-16 mt-10 rounded-1 border-half border-gray9`
]);

export const item = styled.div<Props>(({ hide }) => [
  css`
    ${tw`flex w-full`}

    svg {
      ${tw`pr-16 w-16`};
      path {
        ${tw`fill-black`}
      }
    }
  `, 
  hide && 
  css`
    visibility: hidden;
  `
]);

export const data = styled.div(() => [
  css`
    ${tw`flex flex-col flex-1`};

    span:nth-of-type(1) {
      ${tw`text-textBlack text-18 font-bold`};
    }

    span:nth-of-type(2) {
      ${tw`text-textBlack text-14 font-bold`};
    }
  `
]);

export const roles = styled.div(() => [
  css`
    ${tw`w-full my-10`}

    div {
      ${tw`flex flex-row justify-between`}
    }

    p {
      ${tw`text-textBlack text-14 font-bold leading-18`}
    }
  `
]);

export const buttonLabel = styled.div(() => [
  css`
    ${tw`w-375`}
    ${CbRadio} {
      ${tw`justify-start`}
      span:first-of-type {
        ${tw`w-6 h-6 after:h-9 after:w-2`}
      }
    }

    @media print {
      ${tw`hidden`}
    }
  `
]);

export const checkboxText = styled.div(() => [
  css`
    ${tw`text-textBlack text-14 mr-16`};

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`text-9`}
    }
  `
]);

export const vatText = styled.p(() => [
  css`
    ${tw`text-green text-12 font-semibold my-8`};

    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`text-9 mt-6`} 
    }
  `
]);
