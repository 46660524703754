import React from 'react';
import { Company } from '../../store/auth/auth.types';
import * as S from './user-role';
import { roleLabels } from '../../utils/roles.utils';

interface Props {
  role: string;
  company?: Company;
}

const UserRole = ({ role, company }: Props) => {
  const isClient = role === 'ROLE_CLIENT';

  return (
    <S.wrapper isClient={isClient}>
      {!isClient ? roleLabels[role] : company && company.name}
    </S.wrapper>
  );
};

export default UserRole;
