import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import useDebounce from '../../hooks/useDebounce';
import {
  InputContent,
  InputDropdown,
  Label,
  NoOptions,
  Options
} from './StyledSearchInput';

interface labelFormatProps {
  [key: string]: {
    [key: string]: string;
  };
}

interface Props {
  setValue: (value: string) => void;
  label: string | undefined;
  disabled?: boolean;
  value: string;
  selectValue: (value: any) => void;
  onSearch: (searchTerm: string) => Promise<any>;
  labelProps: string[];
  hideNoOptions?: boolean;
  startSearchAt?: number;
  searchDelay?: number;
  labelPropFormat?: labelFormatProps;
}

const NewSearchInput = ({
  setValue,
  label,
  disabled,
  value,
  selectValue,
  onSearch,
  labelProps,
  labelPropFormat,
  hideNoOptions,
  startSearchAt = 3,
  searchDelay = 500,
  ...rest
}: Props) => {
  const toggle = () => setShowDrop(!showDrop);
  const close = () => {
    if (showDrop) setShowDrop(false);
  };
  const [showDrop, setShowDrop] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const debouncedSearchTerm = useDebounce(value, searchDelay, startSearchAt);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const ref = useRef(null);
  useOnClickOutside(ref, close);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDrop(true);
    setValue(e.target.value);
  };

  const handleTab = (ev: any) => {
    const { keyCode } = ev;
    if (keyCode === 9) close();
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      (async () => {
        try {
          setIsSearching(true);
          const searchResult = await onSearch(debouncedSearchTerm);
          setIsSearching(false);
          setOptions(searchResult);
        } catch (error) {
          console.warn('error:', error);
          setIsSearching(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <InputContent onClick={toggle} disabled={disabled}>
      <input
        onKeyDown={handleTab}
        onChange={handleChange}
        value={value}
        disabled={disabled}
      />

      <Label hasValue={!!value} open={showDrop} {...rest}>
        {label}
      </Label>

      <InputDropdown
        show={
          (showDrop &&
            !hideNoOptions &&
            value.length &&
            value.length > startSearchAt - 1) ||
          (hideNoOptions && options.length && showDrop)
        }
        ref={ref}
      >
        {isSearching && !hideNoOptions && <NoOptions>Loading...</NoOptions>}
        {!isSearching &&
          (options === undefined || options?.length < 1) &&
          !hideNoOptions && <NoOptions>No options</NoOptions>}

        {options?.map((item: any) => {
          const dropdownLabel = labelProps
            .map((prop: string) =>
              labelPropFormat && labelPropFormat[prop]
                ? labelPropFormat[prop][item[prop]]
                : item[prop]
            )
            .join(' ');

          return (
            <Options
              key={item.id}
              onClick={() => {
                selectValue(item);
              }}
            >
              {dropdownLabel}
            </Options>
          );
        })}
      </InputDropdown>
    </InputContent>
  );
};

export default NewSearchInput;
