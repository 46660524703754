import tw, { styled, css } from 'twin.macro';
import { avWrapper } from '../../../components/AssociatedValueDropdown/associated-value-dropdown';
import { Dropdown } from '../../../components/Actions/actions-component';
import { pd } from '../PhaseMethod/phase-method';
import { StyledProps } from '../../../types/util.types';

export const wrapper = styled.div<StyledProps>(({ disabled }) => [
  css`
    ${tw`relative flex items-start flex-col md:flex-row px-16 py-12 flex-wrap`};
  `,
  disabled === 1 &&
  css`
      ${tw`pointer-events-none`}
      > div:last-child {
        > div:last-child {
          ${tw`opacity-50`}
        }
      }
    `
]);

export const title = styled.div(() => [
  css`
    ${tw`relative flex pt-10 flex-wrap items-center mb-15 md:mb-0 mr-auto md:max-w-440 md:min-w-440 text-textBlack font-semibold text-24 leading-32`};
  `
]);

export const taskEdit = styled.input(() => [
  css`
    ${tw`relative flex pt-10 flex-wrap items-center mb-15 md:mb-0 mr-auto 
    md:max-w-440 md:min-w-440 text-textBlack font-semibold text-24 leading-32 
    border-2 border-darkBlue outline-none pl-10 py-5 pr-10`}
  `
]);

export const titleLabel = styled.span(() => [
  css`
    ${tw`pr-50 relative`}
    flex: 1;
  `
]);

export const taskColors = styled.div<StyledProps>(({ inputActive }) => [
  css`
    ${tw`flex absolute`}
    top: 50%;
    transform: translateY(-50%);
    left: -4.8rem;
  `,
  inputActive && css`left: -3.2rem;`
]);

export const taskColor = styled.div<StyledProps>(({ color }) => [
  css`
    ${tw`h-72`}
    width: 8px;
    background-color: ${color};
  `
]);

export const fields = styled.div(() => [
  css`
    ${tw`flex flex-col md:flex-row items-center w-full md:w-auto ml-auto gap-30 flex-wrap`};

    ${pd} {
      ${tw`mx-0`}
    }
  `
]);

export const roleEffortDropdown = styled.div<StyledProps>(({ hasValue }) => [
  css`
    ${tw`w-full md:w-245 mb-15 md:mb-0`};
    min-width: 24rem;

    ${avWrapper} {
      ${tw`text-22 font-semibold mb-0`}
      max-height: 4.8rem;
    }

    ${Dropdown} {
      width: 150%;
      left: -2px;
      z-index: 21;
    }
  `,
  hasValue &&
  css`
    ${avWrapper} {
      > label {
        display: none;
      }
    }
  `
]);

export const actions = styled.div(() => [
  css`
    ${tw`flex items-center self-end md:self-center ml-auto`};

    > * {
      ${tw`mr-16 last:mr-0`}
    }

    > img {
      ${tw`cursor-pointer`}
    }
  `
]);

export const dateFooter = styled.div(() => [
  css`
    ${tw`flex justify-end px-8`};

    > * {
      ${tw`mr-16 last:mr-0`}
    }
  `
]);

export const saveImage = styled.img<StyledProps>(({ disabled }) => [
  disabled &&
  css`
      ${tw`opacity-50 cursor-default!`}
    `
]);
