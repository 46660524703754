import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    ${tw``}
    > img {
      width: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      img {
        display: none;
      }
    }

    @media print {
      ${tw`hidden`}
    }
  `
]);

export const content = styled.div(() => [
  css`
    ${tw`bg-white px-30 py-30 sm:py-50
    `};

    div {
      ${tw`flex items-start justify-between mb-30`}
    }

    h2 {
      ${tw`mb-0 leading-none text-textBlack text-56 font-bold
      
      `}
    }
    p {
      ${tw`mb-30 last:mb-0 font-normal text-32 text-textBlack leading-44
      `}
    }

    button {
      svg {
        path {
          fill: white;
        }
      }
    }
    @media screen and (max-width: 575px) and (min-width: 320px) {
      ${tw`pt-30 bg-gray12 mx-auto px-0`}
      width: 37rem;

      div {
        ${tw`mb-4`}
      }

      h2 {
        ${tw`text-28`}
      }
      p {
        ${tw`text-16 leading-22`}
      }

      button {
        display: none;
      }
    }
  `
]);

// export const smallButton = styled.span(() => [
//   css`
//     ${tw`bg-darkBlue sm:hidden p-10 rounded-2`}
//     width: 4.8rem;
//     height: 4.8rem;

//     svg {
//       path {
//         fill: white;
//       }
//     }
//   `
// ]);
