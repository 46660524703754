import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const content = styled.div<Props>(() => [
  css`
    ${tw`grid grid-cols-1 xl:grid-cols-2 gap-12 mx-10 md:mx-30 mt-40`}

    > div {
      ${tw`bg-white text-40 leading-46`}
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    }
  `
]);
