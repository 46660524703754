import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const list = styled.div<Props>(({ listView }) => [
  css`
    ${tw`mx-10 md:mx-30 mt-40 pb-20 overflow-hidden overflow-visible	`}
  `,
  !listView &&
    css`
      ${tw`grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-12 text-white text-center`}/* 

      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

      @media screen and (max-width: 380px) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      } */
    `,
  listView &&
    css`
      ${tw`bg-white mt-15 px-15`}
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    `
]);
