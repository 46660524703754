import api from '../../config/api.config';
import {
  GetProjects,
  ToggleFavorite,
  ArchiveProject,
  UploadClientAvatar,
  CreateProject,
  EditProject,
  ProjectDataEdit,
  UploadProjectImage,
  InviteClient,
  ProjectProgress,
  ProjectSummary,
  ExternalCostType,
  ProjectMethod,
  SaveComment,
  EditMethodData,
  RenamePhase,
  CreatePhaseTask,
  EditPhaseTask,
  ExternalCost
} from './projects.types';
import { parseBulletList } from '../method-service/actions';
import { format } from 'date-fns';
import { toastUtil } from '../../utils/toast.util';
import { handleErrors } from '../../utils/error.utils';

export const getProjects: GetProjects = async ({
  page,
  size,
  filter,
  name = '',
  sort = true
}) => {
  try {
    const {
      data: { data }
    } = await api(
      `/projects/overview?page=${page}&size=${size}&filter=${filter?.toUpperCase()}&name=${name}&sort=${sort}`
    );

    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const toggleFavorite: ToggleFavorite = async (
  accountId,
  projectId,
  favorite
) => {
  try {
    if (favorite) {
      await api.patch(`/accounts/${accountId}/projects/${projectId}/favorite`);
    } else {
      await api.patch(
        `/accounts/${accountId}/projects/${projectId}/un-favorite`
      );
    }
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const archiveProject: ArchiveProject = async (projectId) => {
  try {
    await api.patch(`/projects/${projectId}/archive`);
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const reactivateProject: ArchiveProject = async (projectId) => {
  try {
    await api.patch(`/projects/${projectId}/unarchive`);
  } catch (error) {
    handleErrors(error);
  }
};

export const uploadClientAvatar: UploadClientAvatar = async (
  accountId,
  file
): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    await api.post(`/accounts/${accountId}/images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    const {
      data: { data }
    } = await api(`/accounts/${accountId}/profile`);

    return data;
  } catch (error) {
    handleErrors(
      error,
      'The avatar image is too big. Please upload one with a max size of 1MB.'
    );
    return Promise.reject(error);
  }
};

export const getProjectCategories = async () => {
  try {
    const {
      data: { data }
    } = await api(`/projects/categories`);

    return data;
  } catch (error) {
    handleErrors(error);
  }
};

export const getProjectStudios = async () => {
  try {
    const {
      data: { data }
    } = await api(`/studios`);

    return data;
  } catch (error) {
    handleErrors(error);
  }
};

export const createProject: CreateProject = async ({
  projectName,
  projectType,
  projectOwner,
  studios,
  client,
  projectCategories,
  level,
  priceType,
  dailyRate,
  currencyType
}) => {
  const newLevel = level && level.length >= 1 && level[0].id;

  try {
    const projectData: any = {
      name: projectName,
      projectType: projectType[0].id,
      projectOwner,
      studios,
      client,
      projectCategories,
      priceType: priceType.toUpperCase(),
      dailyRate,
      currency: currencyType[0].id
    };
    if (projectType[0].id === 'GRID_PROJECT') projectData.level = newLevel;
    const {
      data: { data }
    } = await api.post(`/projects?`, projectData);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const inviteClientToProject: InviteClient = async ({
  accountId,
  projectId
}) => {
  try {
    const {
      data: { data }
    } = await api.post(
      `/accounts/${accountId}/clientInvitation?projectId=${projectId}`
    );
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const uploadProjectImage: UploadProjectImage = async ({
  projectId,
  file
}) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const {
      data: { data }
    } = await api.post(`/projects/${projectId}/images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject({ imageError: true });
  }
};

export const getProgress = async (
  projectId: string | number
): Promise<ProjectProgress | undefined> => {
  try {
    const {
      data: { data }
    } = await api(`/projects/${projectId}/progress`);
    return data;
  } catch (error) {
    handleErrors(error);
  }
};

export const editProject: EditProject = async (
  projectId,
  name,
  priceType,
  dailyRate,
  projectOwner,
  studios,
  currency
) => {
  try {
    const {
      data: { data }
    } = await api.put(`/projects/${projectId}`, {
      name,
      priceType: priceType.toUpperCase(),
      dailyRate,
      projectOwner,
      studios,
      currency
    });
    toastUtil('success', 'Project data updated');
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const removeProject = async (
  projectId: number
) => {
  try {
    const {
      data: { data }
    } = await api.delete(`/projects/${projectId}`);
    toastUtil('success', 'Project deleted succesfully');
    return data;
  } catch (error) {
    handleErrors(error);
  }
};

export const getProjectData = async (
  projectId: string | number
): Promise<ProjectDataEdit> => {
  try {
    const {
      data: { data }
    } = await api(`/projects/${projectId}`);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const getSummary = async (
  projectId: string | number
): Promise<ProjectSummary> => {
  try {
    const {
      data: { data }
    } = await api(`/projects/${projectId}/summary`);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const updateSummary = async (
  projectId: string | number,
  isCostHidden: boolean,
  isEffortHidden: boolean
): Promise<ProjectSummary> => {
  try {
    const {
      data: { data }
    } = await api.put(
      `/projects/${projectId}/summary/update?isCostHidden=${isCostHidden}&isEffortHidden=${isEffortHidden}`
    );
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const addProjectMembers = async (
  projectId: string,
  ids: Array<number | null>
): Promise<any> => {
  try {
    const {
      data: { data }
    } = await api.put(`/projects/${projectId}/members`, { ids });
    return data;
  } catch (error) {
    handleErrors(error);
  }
};

export const createExternalCost = async (
  projectId: string,
  externalCosts: ExternalCost[]
): Promise<ExternalCostType> => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.post(`/projects/${projectId}/externalCosts`, { externalCosts });
    toastUtil('success', message);
    delete data.project;
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const removeExternalCost = async (
  projectId: string,
  externalCostId: number
) => {
  try {
    const {
      data: {
        meta: { message }
      }
    } = await api.patch(
      `/projects/${projectId}/externalCosts/${externalCostId}/remove`
    );
    toastUtil('success', message);
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const editExternalCost = async (
  projectId: string,
  externalCostId: number,
  name: string,
  price: string
): Promise<ExternalCostType[]> => {
  try {
    const {
      data: {
        data: { externalCosts },
        meta: { message }
      }
    } = await api.patch(
      `/projects/${projectId}/externalCosts/${externalCostId}/edit`,
      {
        name,
        price
      }
    );
    toastUtil('success', message);
    return externalCosts;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const addPhase = async (
  name: string,
  projectId: string
): Promise<ProjectProgress> => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.patch(`/projects/${projectId}/phases`, { name });
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const renamePhase: RenamePhase = async (
  projectId,
  phaseId,
  phaseName
) => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.put(`/projects/${projectId}/projectPhases/${phaseId}`, {
      name: phaseName
    });
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const removePhase = async (
  phaseId: number,
  projectId: string
): Promise<ProjectProgress> => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.patch(`/projects/${projectId}/phases/${phaseId}/remove`);
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const addEvent = async (
  projectId: string,
  name: string,
  iconName: string,
  iconColor: string,
  lineColor: string,
  date: Date | null
): Promise<ProjectProgress> => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.post(`/projects/${projectId}/events`, {
      name,
      iconName,
      iconColor,
      lineColor,
      date: date ? format(date, 'yyyy-MM-dd') : null
    });
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const removeEvent = async (
  projectId: string,
  eventId: number
): Promise<ProjectProgress> => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.patch(`/projects/${projectId}/events/${eventId}/delete`);
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const attachClient = async (
  clientId: number,
  projectId: string
): Promise<ProjectProgress> => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.patch(`/accounts/${clientId}/projects/${projectId}/add`);
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const addSpToPhase = async (
  projectId: string,
  phaseId: number,
  name: string,
  level: string,
  methods: any
): Promise<ProjectProgress | undefined> => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.patch(
      `/projects/${projectId}/phases/${phaseId}/projectServicePackages`,
      {
        name,
        level,
        methods: methods.map((m: any) => ({
          ...m,
          disciplines: m.discipline,
          complexityStandards: m.qualities.map((q: any) => ({
            ...q,
            originalQualityId: q.id,
            picked: q.type === 'STANDARD'
          }))
        }))
      }
    );
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
  }
};

export const removeSpFromPhase = async (
  projectId: string,
  phaseId: number,
  servicePackageId: number
): Promise<ProjectProgress> => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.patch(
      `/projects/${projectId}/phases/${phaseId}/projectServicePackages/${servicePackageId}/remove`
    );
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const addMethodToPhase = async (
  projectId: string,
  phaseId: number,
  method: ProjectMethod
): Promise<ProjectProgress | undefined> => {
  try {
    const qualities = method.qualities.map((m: any) => ({
      ...m,
      originalQualityId: m.id,
      picked: m.type === 'STANDARD' ? true : false
    }));

    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.patch(
      `/projects/${projectId}/phases/${phaseId}/projectMethods`,
      {
        ...method,
        complexityStandards: qualities,
        disciplines: method.discipline
      }
    );
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
  }
};

export const addMethodToProjectServicePackage = async (
  projectId: string,
  phaseId: number,
  servicePackageId: number,
  method: ProjectMethod
): Promise<ProjectProgress | undefined> => {
  const qualities = method.qualities.map((m: any) => ({
    ...m,
    originalQualityId: m.id,
    picked: m.type === 'STANDARD' ? true : false
  }));

  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.patch(
      `/projects/${projectId}/phases/${phaseId}/projectServicePackages/${servicePackageId}/projectMethods`,
      {
        ...method,
        complexityStandards: qualities,
        disciplines: method.discipline
      }
    );
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
  }
};

export const removeMethod = async (
  projectId: string,
  phaseId: number,
  methodId: number,
  servicePackageId: null | number
): Promise<ProjectProgress> => {
  const url = servicePackageId
    ? `/projects/${projectId}/phases/${phaseId}/projectServicePackages/${servicePackageId}/projectMethods/${methodId}/remove`
    : `/projects/${projectId}/phases/${phaseId}/projectMethods/${methodId}/remove`;
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.patch(url);
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const saveMethodComment: SaveComment = async (
  projectId,
  projectMethodId,
  comment
) => {
  try {
    const payload = { content: comment };
    const {
      data: { data }
    } = await api.put(
      `/projects/${projectId}/projectMethods/${projectMethodId}/comments`,
      payload
    );
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const editMethod = async (
  projectId: string,
  methodId: number,
  methodData: EditMethodData
): Promise<ProjectProgress> => {
  try {
    const {
      data: { data }
    } = await api.patch(`/projects/${projectId}/projectMethods/${methodId}`, {
      ...methodData,
      deliverables: parseBulletList(methodData.deliverables)
    });
    toastUtil('success', 'Your method is successfully updated!');
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const addTaskToPhase : CreatePhaseTask = async (
  projectId,
  phaseId,
  name,
  duration,
  roles
) => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.post(
      `/projects/${projectId}/phases/${phaseId}/projectTasks`,
      {
        name, duration, roles
      }
    );
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
  }
};

export const editTask = async (
  projectId: string,
  taskId: number,
  taskData: EditPhaseTask
): Promise<ProjectProgress> => {
  try {
    const {
      data: { data }
    } = await api.patch(`/projects/${projectId}/projectTasks/${taskId}`, {
      ...taskData,
      deliverables: parseBulletList(taskData.deliverables)
    });
    toastUtil('success', 'Your task is successfully updated!');
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const deleteTask = async (
  projectId: string,
  phaseId: number,
  taskId: number,
): Promise<ProjectProgress | undefined> => {
  try {
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.delete(`/projects/${projectId}/phases/${phaseId}/projectTasks/${taskId}/remove`);
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
    return;
  }
};

export const updateProjectStartDate = async (
  projectId: number,
  startDate: string
): Promise<ProjectProgress | undefined> => {
  try {
    const {
      data: { data }
    } = await api.patch(`/projects/${projectId}/date`, { startDate });
    toastUtil('success', 'The project start date is successfully updated!');
    return data;
  } catch (error) {
    handleErrors(error);
  }
};
