import React, { useState } from 'react';

import { useFormik } from 'formik';
import { TextInput } from '../../components/Inputs';
import Button from '../../components/Button';
import { initialValues } from './form-model';
import validationSchema from './validation-schema';
import AuthWrapper from '../../components/AuthWrapper';
import { resetPassword } from '../../store/auth/auth.actions';
import * as S from './reset-password';
import { routePaths } from '../../utils/routes.util';

const ResetPassword = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [emailSent, setEmailSent] = useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values): Promise<any> => {
      const { email } = values;
      try {
        setSubmitting(true);

        await resetPassword(email);
        setEmailSent(true);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  const isValidForm = validationSchema.isValidSync({ ...formik.values });
  return (
    <AuthWrapper>
      <S.wrapper>
        <S.formTitle>
          <S.title>Password Reset</S.title>
          <S.subtitle>
            {emailSent ? (
              <>
                If we found a matching account for{' '}
                <span>{formik.values.email}</span>, a password reset link will
                be landing in your email inbox.
              </>
            ) : (
              'Fill in your email address or username and we’ll email you a link allowing you to reset your password.'
            )}
          </S.subtitle>
        </S.formTitle>

        <S.form onSubmit={formik.handleSubmit}>
          {!emailSent && (
            <TextInput
              type="text"
              label="Email Address"
              touched={formik.touched.email}
              error={formik.errors.email}
              {...formik.getFieldProps('email')}
            />
          )}

          <S.formActions>
            {emailSent ? (
              <>
                <Button to={routePaths.LOGIN}>Go back to login</Button>
                <S.bottomActions>
                  <div>Email didn’t arrive?</div>
                  <span
                    onClick={() => {
                      setEmailSent(false);
                    }}
                  >
                    Reset password again
                  </span>
                </S.bottomActions>
              </>
            ) : (
              <>
                <Button type="submit" disabled={submitting || !isValidForm}>
                  Send email reset
                </Button>
              </>
            )}
          </S.formActions>
        </S.form>
      </S.wrapper>
    </AuthWrapper>
  );
};

export default ResetPassword;
