import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

const StyledTextInput = styled.div<Props>(({ isClient, small, profile, disabled }) => [
  css`
    ${tw`relative w-full`}
    ${!profile && 'padding-bottom: 2.8rem;'}

    input {
      ${tw`w-full relative outline-none bg-transparent font-semibold z-5 text-black text-2xl md:text-4xl border-darkBlue border-2 border-solid`}
      height: ${small ? '4.8rem' : '6.4rem'};
      padding: 1.6rem 1.6rem 1.6rem;
      transition: all 0.3s;
      line-height: 3.2rem;
      ${isClient &&
      css`
        padding-left: 4.5rem;
      `}

      &:focus {
        ${tw`border-green`}
      }
    }

    label {
      ${tw`absolute top-0 text-gray-400`}
      left: 1.8rem;
      transform: translateY(1.6rem);
      transition: all 0.3s;
      font-size: 2.2rem;
      line-height: 3.2rem;
      font-weight: 600;
      ${(p: any) =>
        p.isClient &&
        css`
          padding-left: 3rem;
        `}
    }
  `,
  disabled && 
  css`
    input {
      ${tw`border-gray-200 border-2 border-opacity-75 text-gray-200 text-opacity-75`}
    }

    label {
      ${tw`text-gray-200 text-opacity-75`}
    }
  `
]);

export const ErrorText = styled.p<Props>(
  ({ showHint }) => [
    css`
      ${tw`invisible text-lg md:text-2xl text-error h-8`}
      ${showHint && tw`visible`}
    `
  ]);

export const InputWrapper = styled.div<Props>(
  ({ disabled, hasValue, invalid, isClient }) => [
    css`
      ${tw`flex relative w-full bg-gray-800 transition-all duration-300`}
      height: max-content;

      > div,
      > svg {
        ${tw`absolute z-10 fill-gray-400`}
        top: 50%;
        left: 1.6rem;
        height: 2.4rem;
        transform: translateY(-50%);
        cursor: pointer;

        ~ input {
          padding-right: 5.6rem;
        }

        path,
        #Rectangle,
        #Rectangle-Copy-2,
        #Rectangle-Copy-3 {
          ${tw`fill-gray-400`}
          transition: all 0.3s;
        }
      }
      > svg {
        ${tw`left-auto right-15 fill-gray-400`}
      }
    `,
    !disabled &&
      !invalid &&
      css`
        &:focus-within:hover {
          ${tw`bg-gray-800`}
        }
        
        &:hover {
          ${tw`bg-gray-600`}
        }
      `,
    disabled &&
      css`
        ${tw`bg-gray-600`}
      `,
    invalid &&
      css`
        ${tw`border-error`}

        label {
          ${tw`text-gray-400`}
        }

        input {
          ${tw`border-error`};
        }

        &:focus-within:hover {
          ${tw`bg-gray-800`}
        }

        &:hover {
          ${tw`bg-gray-600`}
        }
      `,
    hasValue &&
      css`
        label {
          transform: translateY(0.6rem);
          font-size: 1.4rem;
          line-height: 1;
        }
      `
  ]
);

export const PasswordVisibility = styled.span(() => [
  css`
    ${tw`absolute z-10`}
    top: 50%;
    right: 1.6rem;
    height: 2.4rem;
    transform: translateY(-50%);
    cursor: pointer;
  `
]);

export default StyledTextInput;
