import api from '../../config/api.config';
import { handleErrors } from '../../utils/error.utils';
import { replaceSpecialCharacter } from  '../../utils';
import { AddCompany, CompanyType } from './companies.types';

export const dropdownSearchTerm = async (
  searchTerm: string,
  apiUrl: string
) => {
  try {
    const {
      data: { data }
    } = await api(`${apiUrl}${searchTerm}`);
    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error.response.data);
  }
};

export const getCompanies = async (
  searchTerm: string
): Promise<CompanyType | undefined> => {
  try {
    const {
      data: { data }
    } = await api(`/companies?name=${replaceSpecialCharacter(searchTerm)}`);
    return data;
  } catch (error) {
    handleErrors(error);
  }
};

export const addCompany: AddCompany = async ({ companyName }) => {
  try {
    const {
      data: { data }
    } = await api.post(`/companies`, {
      name: replaceSpecialCharacter(companyName)
    });

    return data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};
