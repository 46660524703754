import React, { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import { AssociatedValueOption, AVDropdownConfig } from '../../../types/util.types';
import { CbRadio } from '../../Inputs';
import * as S from './associated-value-dropdown-item';

interface Props {
  option: AssociatedValueOption;
  initial?: AssociatedValueOption;
  checkbox?: boolean,
  checked?: boolean;
  disabled?: boolean;
  radio?: boolean;
  alt?: boolean;
  invalid?: boolean,
  unit?: string;
  config?: AVDropdownConfig,
  onCheck?: (option: AssociatedValueOption, checked: boolean) => void;
  onEdit?: (option: AssociatedValueOption, value: string | number) => void;
}

const AVDropdownItem: FunctionComponent<Props> = ({
  option,
  initial,
  checkbox,
  checked,
  alt = false,
  disabled = false,
  radio,
  invalid = false,
  unit,
  children,
  config,
  onCheck = () => { },
  onEdit = () => { }
}) => {
  const disable = disabled || (checkbox && !checked);
  const def = !!option.value ?
    (!!config?.numeric ? Number(option.value).toFixed(config.step) : option.value) : '';

  const [check, setCheck] = useState(checked || false);
  const [value, setValue] = useState(def);

  useEffect(() => {
    onEdit(option, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (checkbox && !check && !!initial) {
      setValue(!!option.value ?
        (!!config?.numeric ? Number(option.value).toFixed(config.step) : option.value) : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  useEffect(() => {
    onCheck(option, check);
    if (!check && invalid && !!initial) {
      onEdit(option, initial?.value!)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);

  const updateValue = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
  };

  return (
    <S.wrapper>
      {checkbox && <CbRadio
        radio={radio}
        alt={alt}
        name={option.name}
        value={option.id}
        checked={check}
        onChange={(evt) => setCheck(evt.target.checked)}
        disabled={disabled}
      />}
      <S.label>{children}</S.label>
      <S.value invalid={invalid} disabled={disable}>
        <input type={!!config?.numeric ? 'number' : 'text'}
          value={value}
          onChange={updateValue}
          disabled={disable} />
        {unit && <span>{unit}</span>}
      </S.value>
    </S.wrapper>
  );
};

export default AVDropdownItem;
