import React, { useState } from 'react';
import arrowDown from '../../../images/arrow-down.svg';
import { ReactComponent as CheckMark } from '../../../images/check.svg';

import * as S from './deliverable-field';

interface Props {
  value: string;
  setValue: any;
  isSaved: boolean;
}

const DeliverableField = ({ value, setValue, isSaved }: Props) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const toggleDetails = () => setShowDetails(!showDetails);

  const handleInput = (evt: any) => {
    const bullet = '•';
    const bulletSpace = `${bullet} `;
    const { keyCode, target } = evt;
    const { selectionStart, value } = target;

    if (keyCode === 13) {
      target.value = [...value]
        .map((item, index) =>
          index === selectionStart - 1 ? `\n${bulletSpace}` : item
        )
        .join('');

      target.selectionStart = selectionStart + bulletSpace.length;
      target.selectionEnd = selectionStart + bulletSpace.length;
    }

    if (value[0] !== bullet && value) {
      target.value = `${bulletSpace}${value}`;
    }
    setValue(target.value);
  };

  const onChange = (evt: any) => {
    setValue(evt.target.value);
  };

  return (
    <>
      <S.icon
        src={arrowDown}
        alt="expand"
        onClick={toggleDetails}
        active={showDetails}
      />
      {showDetails && (
        <S.wrapper>
          <S.saveField isSaved={isSaved}>
            <CheckMark />
            <span>Saved</span>
          </S.saveField>
          <textarea
            placeholder="• Enter Deliverable Here"
            value={value}
            onKeyUp={handleInput}
            onChange={onChange}
          />
        </S.wrapper>
      )}
    </>
  );
};

export default DeliverableField;
