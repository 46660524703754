import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { removeEvent } from '../../../store/projects/projects.actions';
import { ProgressContext } from '../Progress';
import images from '../../../utils/images.utils';
import * as S from './project-duration';
import SimpleModal from '../../../components/Modal/SimpleModal/SimpleModal';

interface Params {
  id: string;
}
interface Props {
  eventId: number;
  [x: string]: any;
}

const DeleteEvent = ({ eventId, onClick }: Props) => {
  const { id: projectId } = useParams<Params>();
  const { updateProject } = useContext(ProgressContext);
  const [active, setActive] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const closeDeleteModal = () => {
    setActive(false);
  };

  const showDeleteModal = () => {
    setActive(true);
  };

  const onDeleteEvent = async () => {
    try {
      setIsDeleting(true);
      const data = await removeEvent(projectId, eventId);
      setIsDeleting(false);
      updateProject(data);
      setIsDeleting(false);
    } catch (err) {
      console.warn(err);
      setIsDeleting(false);
    }
  };

  return (
    <>
      <S.deleteEventWrap>
        <img src={images.delete} alt="delte event" onClick={showDeleteModal} />
      </S.deleteEventWrap>
      <SimpleModal
        isOpen={active}
        onDismiss={closeDeleteModal}
        confirm={onDeleteEvent}
        disabled={isDeleting}
      >
        <p>Are you sure that you want to delete this event?</p>
      </SimpleModal>
    </>
  );
};

export default DeleteEvent;
