import React, { useState, useEffect, ChangeEvent } from 'react';
import { TextInput } from '../../../components/Inputs';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import PictureCropper from '../../../components/PictureCropper/PictureCropper';
import Dropdown from '../../../components/Dropdown';
import { useDropzone } from 'react-dropzone';
import uploadDark from '../../../images/upload.svg';
import uploadWhite from '../../../images/upload-white.svg';
import { methodLevels } from '../../../store/method-service/types';
import * as S from './project-form';
import { SearchInput } from '../../../components/Inputs';
import {
  getProjectCategories,
  getProjectStudios
} from '../../../store/projects/projects.actions';
import { DropdownCbOption, SetStateType } from '../../../types/util.types';
import { BaseUser } from '../../../store/auth/auth.types';
import { currencies } from '../../../utils/currency.utils';

interface Props {
  projectName: string;
  setProjectName: SetStateType<string>;
  category: DropdownCbOption[];
  setCategory: SetStateType<DropdownCbOption[]>;
  studios: DropdownCbOption[];
  setStudios: SetStateType<DropdownCbOption[]>;
  projectOwner: any;
  setProjectOwner: SetStateType<any>;
  projectType: DropdownCbOption[];
  setProjectType: SetStateType<DropdownCbOption[]>;
  setFile: SetStateType<any>;
  packageLevel: DropdownCbOption[];
  setPackageLevel: SetStateType<DropdownCbOption[]>;
  priceType: string;
  dailyRate: string;
  setCurrencyType: SetStateType<DropdownCbOption[]>;
  currencyType: DropdownCbOption[];
  updateDailyRate: (evt: ChangeEvent<HTMLInputElement>) => void;
}
interface ProjectOwnerOptionsArg extends BaseUser {
  company: DropdownCbOption;
  status: string;
  poName: string;
}
const ProjectForm = ({
  projectName,
  setProjectName,
  category,
  setCategory,
  studios,
  setStudios,
  projectOwner,
  setProjectOwner,
  projectType,
  setProjectType,
  setFile,
  packageLevel,
  setPackageLevel,
  priceType,
  dailyRate,
  updateDailyRate,
  setCurrencyType,
  currencyType
}: Props) => {
  const [projectImagePreview, setProjectImagePreview] = useState<any>(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const typeOptions = [
    { id: 'WHITE_TABLE', name: 'White table' },
    { id: 'GRID_PROJECT', name: 'Grid project' }
  ];
  const isTypeGrid = projectType.some(
    (el: DropdownCbOption) => el.id === 'GRID_PROJECT'
  );
  const [projectOwnerOptions, setProjectOwnerOptions] = useState<
    ProjectOwnerOptionsArg[]
  >([]);
  const [categoryOptions, setCategoryOptions] = useState<DropdownCbOption[]>();
  const [studioOptions, setStudioOptions] = useState<DropdownCbOption[]>();
  const [projectTypeOptions] = useState<DropdownCbOption[]>(typeOptions);
  const [currencyTypeOptions] = useState<DropdownCbOption[]>(currencies);

  const openCropModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const onCropSuccess = async (file: any, preview: any) => {
    try {
      setFile(file);
      setCroppedImagePreview(preview);
    } catch {
      console.warn('Image upload failed');
    }
  };

  useEffect(() => {
    (async () => {
      const newCategories = await getProjectCategories();
      const newStudios = await getProjectStudios();
      if (!!newCategories && !!newStudios) {
        setCategoryOptions(newCategories);
        setStudioOptions(newStudios);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectImagePreview) openCropModal();
  }, [projectImagePreview]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/png, image/jpeg',
    onDropRejected: () => {
      setProjectImagePreview(null);
    },
    onDropAccepted: (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result;
        setProjectImagePreview(url);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  });

  return (
    <S.wrapper>
      <h3>Project</h3>
      <TextInput
        label="Project name"
        name="projectName"
        value={projectName}
        onChange={(e: any) => {
          setProjectName(e.target.value);
        }}
        disableAutoComplete
      />
      <S.projectCategory>
        <Dropdown
          checkbox
          labelColor="#0a0a33"
          label="Project category"
          options={categoryOptions}
          values={category}
          setValues={setCategory}
        />
      </S.projectCategory>

      <Dropdown
        checkbox
        labelColor="#0A0A33"
        label="Studio"
        options={studioOptions}
        values={studios}
        setValues={setStudios}
      />
      <SearchInput
        disabledInput
        checkProp="poName"
        result={projectOwner}
        setResult={setProjectOwner}
        options={projectOwnerOptions}
        setOptions={setProjectOwnerOptions}
        label="Project owner"
        labelColor="#0A0A33"
        api={`/accounts/search?roles=ROLE_SUPER_ADMIN,ROLE_ADMIN&name=`}
      />

      <Dropdown
        label="Project type"
        labelColor="#0A0A33"
        options={projectTypeOptions}
        values={projectType}
        setValues={setProjectType}
      />
      {isTypeGrid ? (
        <Dropdown
          label="Package level"
          labelColor="#0A0A33"
          options={methodLevels}
          values={packageLevel}
          setValues={setPackageLevel}
        />
      ) : (
        <Dropdown label="Old Project" disabled labelColor="#0A0A33" />
      )}
      <S.price>
        <TextInput
          name="priceType"
          value={priceType}
          disabled
          label="Daily rate"
        />
        <div>
          <TextInput
            name="dailyRate"
            value={dailyRate}
            label="Daily rate price"
            onChange={updateDailyRate}
            disableAutoComplete
          />
          <Dropdown
            label="Currency"
            labelColor="#0A0A33"
            options={currencyTypeOptions}
            values={currencyType}
            setValues={setCurrencyType}
          />
        </div>
      </S.price>

      <S.avatarWrapper>
        <S.droparea
          {...getRootProps({ refKey: 'innerRef' })}
          preview={!!croppedImagePreview}
        >
          <input {...getInputProps()} />
          {croppedImagePreview ? (
            <img src={croppedImagePreview} alt="" />
          ) : (
            <div>
              <p>
                Drop project image here, or select a file from your computer
              </p>
              <img src={uploadDark} alt="" />
            </div>
          )}
        </S.droparea>
        <S.actions>
          <Button secondary onClick={open} small>
            <img src={uploadWhite} alt="" />
            Upload Image
          </Button>
          <span>JPG or PNG, at least 256px.</span>
        </S.actions>
      </S.avatarWrapper>
      <Modal isOpen={openModal} onDismiss={closeModal}>
        <PictureCropper
          title="Upload Image"
          description="Drop project image here, or select a file from your computer"
          uploadDefinition="Upload Image"
          aspect={15 / 3}
          onClose={closeModal}
          selectedImage={projectImagePreview}
          onCropSuccess={onCropSuccess}
        />
      </Modal>
    </S.wrapper>
  );
};

export default ProjectForm;
