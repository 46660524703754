import React, { FunctionComponent, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { isClientAction } from '../../store/auth/auth.actions';
import useGlobal from '../../store';

const EmailAcceptWrapper: FunctionComponent = ({ children }) => {
  const [loggedIn] = useGlobal((state) => state.auth.loggedIn);

  const { search } = useLocation();
  const token = search.split('=')[1];

  useEffect(() => {
      loggedIn && !!token && isClientAction(token)
    // eslint-disable-next-line
  }, [token])

  return (
    <>
      {children}
    </>
  )
}

export default EmailAcceptWrapper