import React, { useState, Fragment, useEffect } from 'react';
import { useFormik, FormikHelpers, FormikValues } from 'formik';
import { ReactSVG } from 'react-svg';
import {
  initialValues,
  formModel,
  AllRoleOptions,
  RoleOptions
} from './form-model';
import { initialValidation } from './validation-schema';
import { CompanyType } from '../../../store/companies/companies.types';
import { FormFieldType } from '../../../types/form.types';
import * as S from './member-form';
import { TextInput, NewSearchInput } from '../../../components/Inputs';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';
import { addMember } from '../../../store/members/members.actions';
import { getCompanies } from '../../../store/companies/companies.actions';
import { toastUtil } from '../../../utils/toast.util';
import { handleFieldErrors } from '../../../utils/error.utils';
import UserIcon from '../../../images/user-filled.svg';
import staticPositionsJson from '../../../json/staticPositions.json';
import useGlobal from '../../../store';
import { roles } from '../../../utils/roles.utils';

const MemberForm = () => {
  const [user] = useGlobal((state) => state.auth.currentUser?.role)
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [role, setRole] = useState<any>([]);
  const [position, setPosition] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [selectedCompany, setSelectedCompany] = useState<CompanyType | null>();

  const formik = useFormik({
    initialValues,
    validationSchema: initialValidation,

    onSubmit: async (
      values,
      formikHelpers: FormikHelpers<FormikValues>
    ): Promise<any> => {
      const { firstName, lastName, email } = values;
      try {
        setSubmitting(true);
        await addMember({
          role: role[0].id,
          position,
          firstName,
          lastName,
          company: selectedCompany!.id,
          email
        });
        formik.handleReset({});
        setRole([]);
        setCompany('');
        setPosition('');
        toastUtil(
          'success',
          `Invitation was send to ${firstName} ${lastName} at ${email} `,
          'small invite'
        );
        setSubmitting(false);
      } catch (error) {
        handleFieldErrors(error, formikHelpers.setFieldError);
        setSubmitting(false);
      }
    }
  });

  const isValidForm = initialValidation.isValidSync({ ...formik.values });

  const searchPosition = async () => {
    try {
      const data = staticPositionsJson.filter((pos: any) =>
        pos.name.toLowerCase().includes(position.toLowerCase())
      );
      return data;
    } catch (error) {
      console.warn(error);
    }
  };

  const searchCompany = async () => {
    return await getCompanies(company);
  };

  const renderPositionField = () => {
    return (
      <NewSearchInput
        setValue={(value: string) => setPosition(value)}
        selectValue={(item: any) => {
          setPosition(item.name);
        }}
        value={position}
        label="Position"
        labelProps={['name']}
        onSearch={searchPosition}
        hideNoOptions
        startSearchAt={0}
        searchDelay={0}
      />
    );
  };

  useEffect(() => {
    if (company !== selectedCompany?.name) setSelectedCompany(null);
  }, [company, selectedCompany]);

  return (
    <S.form onSubmit={formik.handleSubmit}>
      <S.formContent>
        <h3>Member</h3>
        <Dropdown
          label="Select member role"
          labelColor="#0A0A33"
          options={user === roles.superAdmin ? AllRoleOptions : RoleOptions}
          icon={<ReactSVG src={UserIcon} />}
          values={role}
          setValues={setRole}
          small
        />

        {renderPositionField()}

        {formModel.map((field: FormFieldType) => (
          <Fragment key={field.name}>
            {field.type !== 'dropdown' ? (
              <TextInput
                type={field.type}
                label={field.title}
                touched={formik.touched[field.name]}
                error={formik.errors[field.name]}
                {...formik.getFieldProps(field.name)}
                disableAutoComplete
              />
            ) : (
              <NewSearchInput
                setValue={(value: string) => setCompany(value)}
                selectValue={(item: CompanyType) => {
                  setSelectedCompany(item);
                  setCompany(item.name);
                }}
                value={company}
                label="Company Name"
                labelProps={['name']}
                onSearch={searchCompany}
              />
            )}
          </Fragment>
        ))}
      </S.formContent>
      <Button
        type="submit"
        small
        disabled={
          submitting ||
          !isValidForm ||
          role.length < 1 ||
          !selectedCompany ||
          !position
        }
      >
        Invite member
      </Button>
    </S.form>
  );
};

export default MemberForm;
