import tw, { styled, css } from 'twin.macro';

export const form = styled.form(() => [
  css`
    ${tw`flex flex-col w-full items-center`};

    button {
      ${tw`self-end mt-40`}
    }
  `
]);

export const formContent = styled.div(() => [
  css`
    ${tw`w-full bg-white pt-25 pb-80 px-15 md:px-40 xl:px-100 py-40`}
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    > div:nth-child(2), > div:nth-child(3) {
      ${tw`mb-50`}
    }
  `
]);

export const dropdownPlaceholder = styled.div(() => [
  css`
    ${tw`flex items-center `};
    > div {
      ${tw`mr-10`}
    }
  `
]);
