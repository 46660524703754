import React, { useRef, useEffect, useState } from 'react';
import { differenceInCalendarDays, format } from 'date-fns';
import {
  ProjectSummaryPhaseType,
  ProjectProgressDuration
} from '../../../store/projects/projects.types';
import * as S from './summary-chart';

interface Props {
  data: ProjectSummaryPhaseType;
  projectDuration: ProjectProgressDuration | null;
}

const SummaryPhase = ({
  data: { startDate, endDate },
  projectDuration
}: Props) => {
  const dateRef = useRef<any>(null);
  const chartRef = useRef<any>(null);
  const barRef = useRef<any>(null);
  const start = new Date(startDate);
  const end = new Date(endDate);
  const [isDateAlignedRight, setIsDateAlignedRight] = useState<boolean>(false);
  const [isBarAlignedRight, setIsBarAlignedRight] = useState<boolean>(false);

  const totalProjectDays = projectDuration
    ? differenceInCalendarDays(
        new Date(projectDuration.endDate),
        new Date(projectDuration.startDate)
      )
    : 0;
  const totalPhaseDays =
    startDate && endDate ? differenceInCalendarDays(end, start) : 0;

  const leftPos = projectDuration
    ? differenceInCalendarDays(start, new Date(projectDuration.startDate)) /
      totalProjectDays
    : 0;

  useEffect(() => {
    function alignement() {
      const dateWidth = dateRef?.current?.getBoundingClientRect().width;
      const chartWidth = chartRef?.current?.getBoundingClientRect().width;
      const barWidth = barRef?.current?.getBoundingClientRect().width;
      const leftAlign = chartWidth * leftPos;
      setIsDateAlignedRight(leftAlign + dateWidth > chartWidth);
      setIsBarAlignedRight(leftAlign + barWidth > chartWidth);
    }
    alignement();
    window.addEventListener('resize', alignement);
    return () => {
      window.removeEventListener('resize', alignement);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <S.chartRow ref={chartRef}>
        {startDate && endDate && (
          <S.phaseDate
            ref={dateRef}
            style={{
              left: `${isDateAlignedRight ? 'auto' : `${leftPos * 100}%`}`,
              right: `${isDateAlignedRight ? '5px' : 'auto'}`
            }}
          >
            {format(new Date(start), 'dd.MM.yyyy')}
            {' - '}
            {format(new Date(end), 'dd.MM.yyyy')}
          </S.phaseDate>
        )}
        <S.bar
          ref={barRef}
          isGrayedOut={!projectDuration || !startDate || !endDate}
          style={{
            left: `${isBarAlignedRight ? 'auto' : `${leftPos * 100}%`}`,
            right: `${isBarAlignedRight ? '0' : 'auto'}`,
            width:
              projectDuration && startDate && endDate
                ? `${(totalPhaseDays / totalProjectDays) * 100}%`
                : '100%'
          }}
        />
      </S.chartRow>
    </>
  );
};

export default SummaryPhase;
