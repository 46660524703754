import React, { ChangeEvent } from 'react';
import { SetStateType } from '../../types/util.types';
import { validatePd, isInvalidValue } from '../../utils/personDays.utils';
import * as S from './pd-field';

interface Props {
  value: string | number;
  setValue: SetStateType<number | string>;
  initValue: number | string;
  active?: boolean;
  disabled?: boolean;
}

const PdField = ({ value, setValue, initValue, ...rest }: Props) => {
  const handlePdChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (!validatePd(evt.target.value)) return;
    setValue(evt.target.value);
  };

  const handlePdBlur = (evt: ChangeEvent<HTMLInputElement>) => {
    let val = evt.target.value;
    if (val.endsWith('.')) setValue(val.substring(0, val.length - 1));
    if (!val) setValue(initValue);
  };

  return (
    <S.wrapper invalid={isInvalidValue(value)} {...rest}>
      <input value={value} onChange={handlePdChange} onBlur={handlePdBlur} />
      <span>pd</span>
    </S.wrapper>
  );
};

export default PdField;
