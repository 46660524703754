import React, { useReducer, FunctionComponent, useContext } from 'react';
import { ProjectProgressPhase } from '../../../store/projects/projects.types';
import { phaseItemModalsReducer } from './store/phase-item.reducer';
import * as S from './project-phases';
import Modal from '../../../components/Modal';
import AddSp from './AddSp';
import AddMethod from './AddMethod';
import images from '../../../utils/images.utils';
import AddCt from './AddCt';
import { ProgressContext } from '../Progress';
import ClickableText from '../../../components/ClickableText';

interface Props {
  phase: ProjectProgressPhase;
  categories: string;
}

const PhaseItemModalWrapper: FunctionComponent<Props> = ({
  phase,
  categories,
  children
}) => {
  const { isMemberRole } = useContext(ProgressContext);
  const [modals, dispatch] = useReducer(phaseItemModalsReducer, {
    sp: false,
    method: false,
    ct: false
  });

  const openModal = (modal: string) => {
    return () => dispatch({ type: "OPEN", payload: modal });
  }

  const closeModals = () => {
    dispatch({ type: "CLOSE" });
  }

  return (
    <>
      <S.itemWrapper>
        {children}
        <S.itemFooter>
          <S.addButton>
            <ClickableText
              icon={images.addIcon}
              onClick={openModal('sp')}
              disabled={!!isMemberRole}>
              Add service package to phase
            </ClickableText>
          </S.addButton>
          <S.addButton>
            <ClickableText
              icon={images.addIcon}
              onClick={openModal('method')}
              disabled={!!isMemberRole}>
              Add method to phase
            </ClickableText>
          </S.addButton>
          <S.addButton>
            <ClickableText
              icon={images.addIcon}
              onClick={openModal('ct')}
              disabled={!!isMemberRole}>
              Add custom task to phase
            </ClickableText>
          </S.addButton>
        </S.itemFooter>
      </S.itemWrapper>
      <Modal isOpen={modals.sp} onDismiss={closeModals}>
        <AddSp
          closeModal={closeModals}
          categories={categories}
          phaseId={phase.id}
        />
      </Modal>
      <Modal isOpen={modals.method} onDismiss={closeModals}>
        <AddMethod
          closeModal={closeModals}
          categories={categories}
          phaseId={phase.id}
        />
      </Modal>
      <Modal isOpen={modals.ct} onDismiss={closeModals}>
        <AddCt
          phaseId={phase.id}
          closeModal={closeModals}
        />
      </Modal>
    </>
  );
};

export default PhaseItemModalWrapper;