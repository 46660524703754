import React, { ChangeEvent } from 'react';
import CbRadio from '../../../components/Inputs/CbRadio';
import { roles, roleLabels } from '../../../utils/roles.utils';
import useGlobal from '../../../store';

interface Props {
  role: string;
  newRole: string;
  handleRoleChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const MemberRoles = ({ role, newRole, handleRoleChange }: Props) => {
  const [user] = useGlobal((state) => state.auth.currentUser);
  let isAdmin = user?.role === roles.superAdmin || user?.role === roles.admin;
  let isSuperAdmin = user?.role === roles.superAdmin;

  return (
    <>
       <CbRadio
        value={roles.superAdmin}
        name="role"
        radio
        small
        disabled={!isSuperAdmin}
        onChange={handleRoleChange}
        checked={(role === roles.superAdmin && !newRole) || newRole === roles.superAdmin}
      >
        {roleLabels.ROLE_SUPER_ADMIN}
      </CbRadio>
      <CbRadio
        value={roles.admin}
        name="role"
        radio
        small
        disabled={!isAdmin}
        onChange={handleRoleChange}
        checked={(role === roles.admin && !newRole) || newRole === roles.admin}
      >
        {roleLabels.ROLE_ADMIN}
      </CbRadio>
      <CbRadio
        value={roles.member}
        name="role"
        radio
        small
        onChange={handleRoleChange}
        checked={
          (role === roles.member && !newRole) || newRole === roles.member
        }
      >
        {roleLabels.ROLE_PROJECT_MEMBER}
      </CbRadio>
    </>
  );
};

export default MemberRoles;
