import React, { useState, ChangeEvent, FormEvent } from 'react';
import { HeaderWrapper, HeaderTop } from '../../components/Header/StyledHeader';
import * as S from './create-roles-page';
import TextInput from '../../components/Inputs/TextInput';
import Button from '../../components/Button';
import { routePaths } from '../../utils/routes.util';
import { createRole } from '../../store/roles/roles.action';
import { validateRoleName } from '../../utils/roles.utils';
import { validatePrice } from '../../utils/price.utils';

const CreateRole = () => {
  const [name, setName] = useState<string>('');
  const [price, setPrice] = useState<string>('');

  const changeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    if (name === 'roleName' && validateRoleName(value)) setName(value);
    if (name === 'price' && (value === '' || validatePrice(value, 10000))) setPrice(value);
  };

  const isDisabled = !name || !price;

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await createRole({ name: name.trim(), price });
    setName('');
    setPrice('');
  };

  return (
    <>
      <HeaderWrapper>
        <HeaderTop>
          <h2>Creating Role</h2>
          <Button small to={routePaths.ROLES} >
            Back to roles
          </Button>
        </HeaderTop>
      </HeaderWrapper>
      <S.wrapper>
        <S.form>
          <S.section>
            <S.title>Name</S.title>
            <TextInput
              label="Name of the role"
              name="roleName"
              value={name}
              onChange={changeHandler}
            />
          </S.section>
          <S.section>
            <S.title>Price</S.title>
            <TextInput
              name="price"
              value={price}
              label="Price in EUR"
              onChange={changeHandler}
            />
          </S.section>

          <S.footer>
            <S.footerActions>
              <Button
                secondary
                disabled={isDisabled}
                onClick={onSubmit}
              >
                Create Role
              </Button>
            </S.footerActions>
          </S.footer>
        </S.form>
      </S.wrapper>
    </>
  )
}

export default CreateRole