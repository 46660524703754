import React, { useState, useEffect, FormEvent, useContext } from 'react';
import { useParams } from 'react-router-dom';
import NewSearchInput from '../../../components/Inputs/NewSearchInput';
import Button from '../../../components/Button/Button';
import { searchServicePackage } from '../../../store/method-service/actions';
import { addSpToPhase } from '../../../store/projects/projects.actions';
import { PhaseType } from '../../../store/phase/phase.types';
import { ProgressContext } from '../Progress';
import * as S from './project-phases';

interface Props {
  closeModal: () => void;
  categories: string;
  phaseId: number;
}

interface Params {
  id: string;
}

const AddSp = ({ closeModal, categories, phaseId }: Props) => {
  const { updateProject } = useContext(ProgressContext);
  const { id } = useParams<Params>();
  const [name, setName] = useState<string>('');
  const [selected, setSelected] = useState<PhaseType | null>(null);

  const onSearch = async (searchTerm: string) => {
    return await searchServicePackage(searchTerm, categories);
  };

  const confirm = async (evt: FormEvent) => {
    evt.preventDefault();
    const data = await addSpToPhase(
      id,
      phaseId,
      selected!.name,
      selected!.level,
      selected!.methods
    );

    if (!!data) {
      updateProject(data);
      closeModal();
    }
  };

  useEffect(() => {
    if (name && selected && name !== selected?.name) setSelected(null);
  }, [name, selected]);

  return (
    <S.modalWrapper>
      <h3>Add service package to phase</h3>
      <form onSubmit={confirm}>
        <NewSearchInput
          setValue={(value: string) => setName(value)}
          selectValue={(item: PhaseType) => {
            setName(item.name);
            setSelected(item);
          }}
          value={name}
          label="Enter service package"
          labelProps={['name', 'level']}
          onSearch={onSearch}
        />
        <Button secondary type="submit" disabled={!selected}>
          Confirm
        </Button>
      </form>
    </S.modalWrapper>
  );
};

export default AddSp;
