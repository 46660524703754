import { LogLevel, RedirectRequest } from "@azure/msal-browser";
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AD_CLIENT_ID!,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AD_TENANT_ID}`,
        redirectUri: process.env.REACT_APP_AD_REDIRECT_URI,
        postLogoutRedirectUri: `${process.env.REACT_APP_AD_REDIRECT_URI}login`
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        return;
                    case LogLevel.Verbose:
                        return;
                    case LogLevel.Warning:
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

export const loginRequest: RedirectRequest = {
    scopes: ['email', 'profile']
}

export const pca = new PublicClientApplication(msalConfig);