import { FormFieldType } from '../../../types/form.types';

interface Values {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const initialValues: Values = {
  email: '',
  password: '',
  rememberMe: true
};

export const formModel: FormFieldType[] = [
  {
    name: 'email',
    type: 'text',
    placeholder: 'Email Address'
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Password'
  }
];
