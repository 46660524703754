import React, { useState, useRef } from 'react';
import { format } from 'date-fns';
import useOnClickOutside from 'use-onclickoutside';
import DayPicker from 'react-day-picker';
import { SetStateType } from '../../types/util.types';
import { ReactComponent as Icon } from '../../images/calendar.svg';
import CalendarNav from '../CalendarNav';
import * as S from './date-input';

interface Props {
  before?: string;
  after?: string;
  setDate: SetStateType<Date | null>;
  date: Date | null;
  label?: string;
  onOpen?: () => void;
  activeAlert?: boolean;
  disabledDays?: string;
  disabled?: boolean | undefined;
}

const DateInput = ({
  before,
  after,
  date,
  setDate,
  onOpen,
  activeAlert,
  disabledDays,
  label = 'Date',
  disabled
}: Props) => {
  const ref = useRef(null);
  const [active, setActive] = useState<boolean>(false);

  const toggle = () => {
    if (disabled) return;
    if (!active && onOpen) onOpen();
    setActive(!active);
  };
  const close = () => {
    if (activeAlert) return;
    setActive(false);
  };

  const onDayClick = (day: any, { disabled }: any) => {
    if (disabled) return;
    close();
    setDate(day);
  };

  useOnClickOutside(ref, close);

  const restProps: any = {};
  if (before && after) {
    restProps.disabledDays = {
      before: before ? new Date(before) : undefined,
      after: after ? new Date(after) : undefined
    };
  }

  return (
    <S.wrapper ref={ref} >
      <S.input opened={active} onClick={toggle} disabled={disabled}> 
        <Icon />
        <S.label hasValue={!!date} disabled={disabled}>
          {label}
        </S.label>
        {date && format(date, 'dd.MM.yyyy')}
      </S.input>
      {active && (
        <S.dropdown>
          <DayPicker
            onDayClick={onDayClick}
            selectedDays={date}
            navbarElement={<CalendarNav />}
            {...restProps}
            showOutsideDays
            enableOutsideDaysClick={false}
            disabledDays={[{ daysOfWeek: [0, 6] }]}
            initialMonth={date ? new Date(date) : new Date()}
          />
        </S.dropdown>
      )}
    </S.wrapper>
  );
};

export default DateInput;
