import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const wrapper = styled.div<Props>(({ alt }) => [
  css`
    ${tw`flex items-center justify-between flex-no-wrap md:flex-wrap px-10 md:px-30 py-12 bg-darkBlue3 text-white`};
    height: 7.2rem;

    h2 {
      ${tw`mb-0 text-24 md:text-32 font-bold`}
    }
  `,
  alt &&
    css`
      ${tw`bg-darkBlue`}
    `
]);

interface SearchWrapperProp {
  isFocused: boolean;
}

export const searchWrapper = styled.form<SearchWrapperProp>(({ isFocused }) => [
  css`
    ${tw`flex items-center md:w-full pl-15 relative z-5`};

    img {
      ${tw`mr-10 cursor-pointer`}
      width: 2.6rem;

      &:nth-of-type(2) {
        ${tw`cursor-pointer opacity-0`}
        width: 2rem;
      }
    }

    input {
      ${tw`flex w-full items-center outline-none bg-transparent text-22 font-semibold `}
      height: 4.8rem;

      ::-webkit-input-placeholder {
        ${tw`font-semibold`}
      }

      :-ms-input-placeholder {
        ${tw`font-semibold`}
      }

      ::placeholder {
        ${tw`font-semibold`}
      }
    }
  `,
  isFocused &&
    css`
      ${tw`bg-white bg-opacity-20 z-10`}

      img {
        &:nth-of-type(2) {
          ${tw`opacity-100`}
        }
      }
    `
]);

export const actions = styled.div(() => [
  css`
    ${tw`flex items-center absolute right-10 md:right-30 z-5`};

    img {
      height: 2.4rem;

      &:not(:first-child) {
        ${tw`ml-20`}
      }
    }
  `
]);
