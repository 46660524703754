import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const wrapper = styled.div(() => [
  css`
    ${tw`flex flex-col mx-10 md:mx-30 mt-40`};
  `
]);
export const createProjectContent = styled.div<Props>(() => [
  css`
    ${tw`grid gap-12 `}
    grid-template-columns: repeat( auto-fit, minmax(350px, 1fr) );

    > div {
      ${tw`h-full w-full bg-white pt-40 pb-80 px-15 md:px-40 xl:px-60 xxl:px-100  text-textBlack`}
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    }
  `
]);

export const footer = styled.div(() => [
  css`
    ${tw`flex flex-col items-start self-end w-full sm:w-auto mt-30 md:mr-40 xl:mr-60 xxl:mr-100`};

    span {
      ${tw`text-18 leading-28`}
    }
  `
]);

export const footerActions = styled.div(() => [
  css`
    ${tw`flex w-full sm:flex-row mt-24`};

    button {
      ${tw`w-full sm:w-auto sm:first:mr-24  first:mb-24 sm:first:mb-0`}
    }
  `
]);
