import React from 'react';
import {
  ProjectSummaryPhaseType,
  ProjectProgressDuration,
  ProjectEvents
} from '../../../store/projects/projects.types';

import { format, differenceInCalendarDays } from 'date-fns';
import SummaryPhase from './SummaryPhase';
import SummaryEvent from './SummaryEvent';
import * as S from './summary-chart';

interface Props {
  phases: ProjectSummaryPhaseType[];
  events: ProjectEvents[];
  projectDuration: ProjectProgressDuration | null;
  name: string;
}

const SummaryChart = ({ phases, projectDuration, events }: Props) => {
  const projectStart = projectDuration?.startDate
    ? new Date(projectDuration.startDate)
    : null;

  const durationInDays =
    projectDuration?.startDate && projectDuration?.endDate
      ? differenceInCalendarDays(
          new Date(projectDuration.endDate),
          new Date(projectDuration.startDate)
        )
      : null;

  return (
    <S.wrapper>
      <h3>
        Project Timeline -{' '}
        {projectDuration ? Math.ceil(projectDuration.duration) : 0} Weeks
      </h3>
      <S.chartWrapper hasEvents={!!events.length}>
        <S.chart>
          <S.sidebar>
            <S.sidebarPhaseWrap>
              {phases.map((p: ProjectSummaryPhaseType) => (
                <S.sidebarPhase key={p.id}>{p.name}</S.sidebarPhase>
              ))}
            </S.sidebarPhaseWrap>
            <S.siderBarEventWrap>
              {events
                .filter((e: ProjectEvents) => !e.date)
                .map((e: ProjectEvents) => (
                  <SummaryEvent data={e} key={e.id} />
                ))}
            </S.siderBarEventWrap>
          </S.sidebar>
          <S.main>
            {projectDuration?.startDate && projectDuration?.endDate && (
              <>
                <S.projectDateWrapper hasEvents={!!events.length}>
                  <S.projectTitle>Project Start</S.projectTitle>
                  <S.projectDate>
                    {format(new Date(projectDuration.startDate), 'dd.MM.yyyy')}
                  </S.projectDate>
                </S.projectDateWrapper>
                <S.projectDateWrapper isEnd hasEvents={!!events.length}>
                  <S.projectTitle>Project End</S.projectTitle>
                  <S.projectDate>
                    {format(new Date(projectDuration.endDate), 'dd.MM.yyyy')}
                  </S.projectDate>
                </S.projectDateWrapper>
              </>
            )}
            {phases.map((p: ProjectSummaryPhaseType) => (
              <SummaryPhase
                key={p.id}
                data={p}
                projectDuration={projectDuration}
              />
            ))}
            <S.eventWrap>
              {events
                .filter((e: ProjectEvents) => e.date)
                .map((e: ProjectEvents) => (
                  <SummaryEvent
                    data={e}
                    key={e.id}
                    projectStart={projectStart}
                    projectDuration={durationInDays}
                  />
                ))}
            </S.eventWrap>
          </S.main>
        </S.chart>
      </S.chartWrapper>
    </S.wrapper>
  );
};

export default SummaryChart;
