import React, {
  useState,
  useContext,
  useEffect,
  useRef
} from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { EditPhaseTask, ProjectTask } from '../../../store/projects/projects.types';
import { ProgressContext } from '../Progress';
import { colorPicker, parsedDeliverables } from '../../../utils/general.utils';
import saveIcon from '../../../images/icon-save.svg';
import useTaskRoles from '../../../hooks/useTaskRoles';
// eslint-disable-next-line
import * as S from './phase-task';
import { AssociatedValueOption } from '../../../types/util.types';
import AssociatedValueDropdown from '../../../components/AssociatedValueDropdown';
import { deleteTask, editTask } from '../../../store/projects/projects.actions';
import { useParams } from 'react-router-dom';
import { validatePd } from '../../../utils/personDays.utils';
import { validatePrice } from '../../../utils/price.utils';
import MethodDate from '../PhaseMethod/MethodDate';
import { format } from 'date-fns';
import MethodDays from '../PhaseMethod/MethodDays';
import deleteIcon from '../../../images/delete.svg';
import MethodComment from '../PhaseMethod/MethodComment';
import Deliverables from '../Deliverables';
import SimpleModal from '../../../components/Modal/SimpleModal/SimpleModal';

interface Props {
  data: ProjectTask;
  phaseId: number;
}

const PhaseTask = ({
  data: {
    id,
    name,
    startDate: taskStart,
    endDate: taskEnd,
    taskMemberRoles,
    comment,
    deliverables
  },
  phaseId
}: Props) => {

  const { id: projectId } = useParams<{ id: string }>();

  const { isMemberRole, project: { currency }, updateProject } = useContext(ProgressContext);

  const inputRef = useRef(null);

  const { taskRoles } = useTaskRoles();

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [taskName, setTaskName] = useState<string>(name);
  const [showInput, setShowInput] = useState<boolean>(false);

  const [selectedRoles, setSelectedRoles] = useState<AssociatedValueOption[]>([]);
  const [roleOptions, setRoleOptions] = useState<AssociatedValueOption[]>([]);

  const [isForceOpen, setIsForceOpen] = useState<boolean>(false);
  const [selectedEffort, setSelectedEffort] = useState<AssociatedValueOption[]>([]);
  const [effortOptions, setEffortOptions] = useState<AssociatedValueOption[]>([]);

  const [taskDeliverables, setTaskDeliverables] = useState<string>(parsedDeliverables(deliverables))

  const [sumPersonDays, setSumPersonDays] = useState<number>(0);

  const [commentText, setCommentText] = useState<string>(!!comment ? comment.content : '');

  const [startDate, setStartDate] = useState<undefined | Date>(
    taskStart ? new Date(taskStart) : undefined
  );
  const [endDate, setEndDate] = useState<undefined | Date>(
    taskEnd ? new Date(taskEnd) : undefined
  );
  const [dateUpdated, setDateUpdated] = useState<boolean>(false);

  const [canSave, setCanSave] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);

  const preselected = !!taskMemberRoles ? taskMemberRoles.map(mr => mr.roleId) : [];

  useOnClickOutside(inputRef, () => {
    setShowInput(false);
  });

  useEffect(() => {
    if (!taskName || selectedEffort.length === 0) setCanSave(false);
    else setCanSave(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskName, commentText, taskDeliverables]);

  useEffect(() => {
    if (!dateUpdated) setCanSave(false);
    else setCanSave(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateUpdated]);

  useEffect(() => {
    //@ts-ignore
    if (showInput && inputRef.current) inputRef.current!.select();
    if (!showInput && !taskName.trim()) setTaskName(name);
    // eslint-disable-next-line
  }, [showInput]);

  useEffect(() => {
    const roles = convertRoles();
    const effort = convertEffort();

    setRoleOptions(roles);
    setEffortOptions(effort);

    setSelectedRoles(roles.filter(e => {
      return !!preselected.find(i => i === e.id);
    }));
    setSelectedEffort(effort);
    setCanSave(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskRoles]);

  useEffect(() => {
    if(validateValues(selectedEffort, true) || selectedEffort.length === 0) {
      setCanSave(false);
      return;
    }
    setSumPersonDays(selectedEffort?.map((elem: any) => elem.value)
      .reduce((prev: any, next: any) => prev + next, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEffort]);

  useEffect(() => {
    setStartDate(taskStart ? new Date(taskStart) : undefined);
    setEndDate(taskEnd ? new Date(taskEnd) : undefined);
  }, [taskStart, taskEnd]);

  useEffect(() => {
    if(validateValues(selectedRoles, false)) setCanSave(false);
  }, [selectedRoles]);

  const validateValues = (array: any, pd: boolean) => {
    if (pd) return array.map((elem: any) => elem.value).some((elem: any) => +elem <= 0 || !validatePd(elem));
    return array.map((elem: any) => elem.value).some((elem: any) => +elem <= 0 || !validatePrice(elem, 10000));
  }

  const onSave = async () => {
    if (!canSave) return;
    try {
      const editData: EditPhaseTask = {
        name: taskName,
        startDate: startDate ? format(startDate, 'yyyy-MM-dd') : '',
        endDate: endDate ? format(endDate, 'yyyy-MM-dd') : '',
        roles: convertRoleEffort(),
        deliverables: taskDeliverables,
        comment: commentText
      };

      const data = await editTask(projectId, id, editData);
      updateProject(data);
      setCanSave(false);
      setShowInput(false);
      setDateUpdated(false);
      if (parsedDeliverables(deliverables) !== taskDeliverables) setSaved(true);
    } catch (err) {
      console.warn(err);
    }
  };

  const onDelete = async () => {
    setIsDeleting(true);
    const data = await deleteTask(projectId, phaseId, id);
    setDeleteModal(false);
    !!data && updateProject(data);
    setIsDeleting(false);
  }

  const showDeleteModal = () => {
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const updateDates = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
    setDateUpdated(true);
  };

  const convertRoleEffort = (): any[] => {
    return selectedRoles.map((e, i) => {
      return {
        roleId: e.id,
        personDays: +selectedEffort[i]?.value!,
        price: +e.value!
      };
    });
  }

  const selectEffort = (values: AssociatedValueOption[]) => {
    if(JSON.stringify(selectedEffort) !== JSON.stringify(values) && !validateValues(selectedRoles, false && selectedRoles.length !== 0)) {
      setSelectedEffort(values);
      setCanSave(true);
    }
  }

  const selectRoles = (values: AssociatedValueOption[]) => {
    setSelectedRoles(values);
    if (JSON.stringify(values) !== JSON.stringify(selectedRoles)) {
      setIsForceOpen(true);
      setEffortOptions(values.map((elem: AssociatedValueOption) => ({ ...elem, value: 1 })));
    } 
    setCanSave(false);
  }

  const convertRoles = (): AssociatedValueOption[] => {
    if (!taskMemberRoles) {
      return taskRoles.map((e) => (
        {
          id: e.id,
          name: e.name,
          value: e.price
        }
      ));
    }

    return taskRoles.map(e => {
      const modified = taskMemberRoles.find(mr => e.id === mr.roleId);
      if (!!modified) {
        return {
          id: e.id,
          name: e.name,
          value: modified.price
        };
      }
      return {
        id: e.id,
        name: e.name,
        value: e.price
      };
    });
  }

  const convertEffort = (): AssociatedValueOption[] => {
    if (!taskMemberRoles) return [];

    return taskMemberRoles.map(e => ({
      id: e.roleId,
      name: e.name,
      value: e.personDays
    }));
  }

  const isInvalidRoles = (option: AssociatedValueOption) => {
    return !option.value || !validatePrice(option.value, 10000);
  }

  const validateRoles = () => {
    return !roleOptions?.find((el: AssociatedValueOption) => isInvalidRoles(el));
  };

  const isInvalidEffort = (option: AssociatedValueOption) => {
    return !option.value || +option.value <= 0 || !validatePd(option.value);
  }

  const validateEffort = () => {
    return !effortOptions?.find((el: AssociatedValueOption) => isInvalidEffort(el));
  };

  return (
    <>
      <S.wrapper disabled={isMemberRole ? 1 : 0}>
        {name && !showInput ? (
          <S.title>
            <S.titleLabel onClick={() => setShowInput(true)}>{taskName}
              <S.taskColors>
                <S.taskColor key={id} color={colorPicker()} />
              </S.taskColors>
            </S.titleLabel>
            <Deliverables value={taskDeliverables} setValue={setTaskDeliverables} isSaved={saved} />
          </S.title>
        ) : (
            <>
              <S.taskColors inputActive>
                <S.taskColor key={id} color={colorPicker()} />
              </S.taskColors>
              <S.taskEdit
                ref={inputRef}
                value={taskName}
                onChange={(e) => setTaskName(e.target.value)}
              />
            </>
        )}
        <S.fields>
          <S.roleEffortDropdown hasValue={selectedRoles.length > 0}>
            <AssociatedValueDropdown
              isInvalid={isInvalidRoles}
              label="Roles"
              checkbox
              labelColor="#0A0A33"
              options={roleOptions}
              setOptions={setRoleOptions}
              values={selectedRoles}
              setValues={selectRoles}
              initials={convertRoles()}
              unit={currency}
              config={{ numeric: true, step: 2 }}
              validate={validateRoles}
              preselect={preselected}
              disabled={isMemberRole}
            />
          </S.roleEffortDropdown>
          <S.roleEffortDropdown hasValue={selectedEffort.length > 0}>
            <AssociatedValueDropdown
              isInvalid={isInvalidEffort}
              label="Effort"
              labelColor="#0A0A33"
              options={effortOptions}
              setOptions={setEffortOptions}
              values={selectedEffort}
              setValues={selectEffort}
              unit={'PD'}
              config={{ numeric: true, step: 2 }}
              validate={validateEffort}
              forceOpen={isForceOpen}
              setForceOpen={setIsForceOpen}
              disabled={isMemberRole}
            />
          </S.roleEffortDropdown>
          <MethodDays personDays={sumPersonDays} disabled />
          <S.actions>
            <MethodComment
              comment={commentText}
              handleComment={setCommentText}
              isComment={!!comment?.content}
            />
            <MethodDate
              from={startDate}
              to={endDate}
              onSave={updateDates}
            />
            <img
              src={deleteIcon}
              alt="Delete"
              onClick={showDeleteModal}
            />
            <S.saveImage
              disabled={!canSave}
              src={saveIcon}
              alt="Save"
              onClick={() => onSave()}
            />
          </S.actions>
        </S.fields>
      </S.wrapper>
      <SimpleModal
        isOpen={deleteModal}
        onDismiss={closeDeleteModal}
        confirm={onDelete}
        disabled={isDeleting}
      >
        <p>Are you sure that you want to delete this custom task?</p>
      </SimpleModal>
    </>
  );
};

export default PhaseTask;
