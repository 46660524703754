import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';

export const droparea = styled.div<Props>(({ disabled }) => [
  css`
    ${tw`h-304 w-full
        flex items-end justify-center 
        text-18 font-semibold text-center md:mr-30
        border-2 rounded-8 border-dashed border-gray6 border-opacity-50 
        `};
    background-color: #f2f5f8;
    object-fit: cover;
  `, 
  disabled && 
  css`
    ${tw`pointer-events-none`}
  `
]);

export const actions = styled.div(() => [
  css`
    ${tw`flex items-center flex-col mt-20 pb-36`}

      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    p {
      ${tw`text-textBlack text-18 font-semibold leading-24 w-356 text-center mb-36`}
    }

    span {
      ${tw`mt-8 text-14 leading-22 font-semibold text-textBlack text-opacity-75`}
    }
  `
]);

export const preview = styled.div(() => [
  css`
    ${tw`relative h-304 w-full outline-none`}

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

    svg {
      ${tw`absolute cursor-pointer rounded-4 bg-darkBlue
      p-5`}
      bottom: 3.5rem;
      right: 3.1rem;
      box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 15%);

      path {
        fill: #fff;
      }
    }
  `
]);
