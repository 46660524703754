import React, { useState, useEffect } from 'react';
// import TopBar from '../../components/TopBar';
import { DropdownCbOption } from '../../types/util.types';
import { HeaderWrapper, HeaderTop } from '../../components/Header/StyledHeader';
import * as S from './create-service';
import {
  MemberRole,
  MultipleInputs,
  Qualities
} from '../../store/method-service/types';
import { MultipleSearchInputs } from '../../components/Inputs';
import Button from '../../components/Button';
import { createServicePackage } from '../../store/method-service/actions';
import NameField from '../CreateMethod/NameField';
import PhaseLevel from '../CreateMethod/MethodLevel';
import MemberField from './MemberField';
import ComplexityField from './ComplexityField';
import CommentField from '../CreateMethod/CommentField';
import { toastUtil } from '../../utils/toast.util';
import { routePaths } from '../../utils/routes.util';

const CreateService = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [selectedLevel, setSelectedLevel] = useState<DropdownCbOption[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<MemberRole[]>([]);

  const [comment, setComment] = useState<string>('');

  const [multipleInputs, setMultipleInputs] = useState<MultipleInputs[]>([
    { method: null, methodOptions: [] }
  ]);

  const isRoleDisabled = multipleInputs.find((f) => f.method?.id);

  const isButtonDisabled = multipleInputs.some(
    (f) => f.method === null || typeof f.method === 'string'
  );

  const [combinedValues, setCombinedValues] = useState({
    selectedRoles: [],
    standard: {
      personDays: 0,
      price: 0
    },
    high: {
      personDays: 0,
      price: 0
    },
    extreme: {
      personDays: 0,
      price: 0
    },
    responsive_design: {
      personDays: 0,
      price: 0
    }
  });

  useEffect(() => {
    const reduce = multipleInputs.reduce(
      (acc: any, current: MultipleInputs) => {
        if (current.method === null || typeof current.method === 'string') {
          return acc;
        }
        current.method?.qualities.forEach((el: Qualities) => {
          acc[el.type.toLowerCase()].personDays += el.personDays;
          acc[el.type.toLowerCase()].price += el.price;
        });

        current.method?.memberRoles.forEach((role: MemberRole) => {
          const roleExists = acc.selectedRoles.find(
            (el: MemberRole) => el.memberRoleId === role.memberRoleId
          );
          if (!roleExists) {
            acc.selectedRoles.push(role);
            return;
          }

          acc.selectedRoles = acc.selectedRoles.map((r: any) => {
            if (r.memberRoleId === role.memberRoleId) {
              return {
                ...r,
                personDays: r.personDays + role.personDays
              };
            }
            return r;
          });
        });
        return acc;
      },
      {
        selectedRoles: [],
        standard: {
          personDays: 0,
          price: 0
        },
        high: {
          personDays: 0,
          price: 0
        },
        extreme: {
          personDays: 0,
          price: 0
        },
        responsive_design: {
          personDays: 0,
          price: 0
        }
      }
    );

    setCombinedValues(reduce);

    // eslint-disable-next-line
  }, [multipleInputs]);

  useEffect(() => {
    setTimeout(() => {
      setMultipleInputs([]);

      setMultipleInputs([{ method: null, methodOptions: [] }]);
    }, 1);
  }, [selectedLevel]);

  const create = async () => {
    try {
      setSubmitting(true);

      const res = await createServicePackage(
        name,
        selectedLevel,
        multipleInputs,
        comment
      );
      setSubmitting(false);
      toastUtil('success', res.data.meta.message);
      setName('');
      setSelectedLevel([]);
      setComment('');
      setMultipleInputs([]);
      setMultipleInputs([{ method: null, methodOptions: [] }]);
      setCombinedValues({
        selectedRoles: [],
        standard: {
          personDays: 0,
          price: 0
        },
        high: {
          personDays: 0,
          price: 0
        },
        extreme: {
          personDays: 0,
          price: 0
        },
        responsive_design: {
          personDays: 0,
          price: 0
        }
      });
    } catch (error) {
      setSubmitting(false);
    }
  };

  const isDisabled = !name || !selectedLevel.length;
  // !multipleInputs.find((m: any) => m.method?.id);

  const methodLevel = selectedLevel.find((l) => l.id);

  return (
    <>
      {/* <TopBar /> */}
      <HeaderWrapper>
        <HeaderTop>
          <h2>Creating Service Package</h2>
          <Button small to={routePaths.SERVICES} secondary>
            Back to services
          </Button>
        </HeaderTop>
      </HeaderWrapper>
      <S.wrapper>
        <S.section>
          <S.title>Package information</S.title>
          <NameField
            value={name}
            setValue={setName}
            maxWidth="35rem"
            label="Package name"
          />
          <PhaseLevel
            selected={selectedLevel}
            onAdd={setSelectedLevel}
            label="Package level"
            // disableWhenSelected
          />
          <MemberField
            selectedRoles={combinedValues.selectedRoles}
            label="Member role"
            disabled={!isRoleDisabled}
          />
        </S.section>
        <S.section>
          <S.title> Include method</S.title>

          <MultipleSearchInputs
            setMultipleInputs={setMultipleInputs}
            multipleInputs={multipleInputs}
            isDisabled={isButtonDisabled}
            selectedRoles={selectedRoles}
            setSelectedRoles={setSelectedRoles}
            apiVariable={methodLevel?.id}
            isAllDisabled={!methodLevel}
          />
        </S.section>
        <S.section>
          <S.title>Complexity</S.title>
          <ComplexityField
            complexity={combinedValues.standard}
            title="Standard"
            disableAll
          />
          <ComplexityField
            complexity={combinedValues.high}
            title="High"
            disableAll
          />
          <ComplexityField
            complexity={combinedValues.extreme}
            title="Extreme"
            disableAll
          />
          <ComplexityField
            complexity={combinedValues.responsive_design}
            title="Responsive design"
            disableAll
          />
        </S.section>
        <S.section>
          <S.title>Comment field</S.title>
          <CommentField
            setValue={setComment}
            value={comment}
            placeholder="Description"
            maxWidth="100%"
            service
          />
        </S.section>
        <S.actions>
          <Button
            secondary
            onClick={create}
            disabled={submitting || isDisabled}
          >
            Create service
          </Button>
        </S.actions>
      </S.wrapper>
    </>
  );
};

export default CreateService;
