import React, { FunctionComponent } from 'react';
import Button from '../../Button';
import Modal from '../Modal';

import * as S from './simple-modal';

interface Props {
  isOpen: boolean;
  onDismiss: any;
  confirm: any;
  disabled?: boolean | undefined;
}

const SimpleModal: FunctionComponent<Props> = ({
  children,
  isOpen,
  onDismiss,
  confirm,
  disabled
}) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <S.body>
        {children}
        <div>
          <Button secondary onClick={onDismiss} disabled={disabled}>
            Cancel
          </Button>
          <Button onClick={confirm} disabled={disabled}>
            Confirm
          </Button>
        </div>
      </S.body>
    </Modal>
  );
};

export default SimpleModal;
