import React, { useContext, useState } from 'react';
import Actions from '../../../components/Actions';
import envelopeIcon from '../../../images/envelope.svg';
import phoneIcon from '../../../images/phone.svg';
import { deleteTeamMember } from '../../../store/members/members.actions';
import { ProjectMembersType } from '../../../store/projects/projects.types';
import { ProgressContext } from '../Progress';
import * as S from './project-members';
import useGlobal from '../../../store';
import { isSuperAdmin, isAdmin } from '../../../utils';
import { convertImageURI } from '../../../utils/images.utils';
import SimpleModal from '../../../components/Modal/SimpleModal/SimpleModal';
import { ReactSVG } from 'react-svg';

interface Props {
  data: ProjectMembersType;
}

const ProjectMemberItem = ({ data }: Props) => {
  const { updateProject, project: { id } } = useContext(ProgressContext);
  const [user] = useGlobal((state) => state.auth.currentUser);

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const deleteMember = async () => {
    const res = await deleteTeamMember(id, data.id);
    if (!!res) updateProject(res);
    closeDeleteModal();
  }

  const toggleOptions = () => {
    setShowOptions(prev => !prev);
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  }

  return (
    <S.item>
      <S.itemHeader>
        {data.imageUrl ? (
          <img src={convertImageURI(data.imageUrl)} alt="" />
        ) : (
          <span>
            {`${data.lastName.substring(0, 2)}${data.firstName.substring(
              0,
              2
            )}`}
          </span>
        )}
      </S.itemHeader>
      <S.itemBody>
        <h4>
          {data.firstName} {data.lastName}
        </h4>
        {/* <S.itemMain>
                            <span>Role Description Placeholder</span>
                            <span>Phases Placeholder</span>
                    </S.itemMain> */}
        <S.itemFooter>
          {data.phone && (
            <span>
              <ReactSVG src={phoneIcon} />
              <span>{data.phone}</span>
            </span>
          )}
          <span>
            <ReactSVG src={envelopeIcon} />
            <span>{data.email}</span>
          </span>
        </S.itemFooter>
        {(isSuperAdmin(user) || isAdmin(user)) &&
          <Actions
            toggle={toggleOptions}
            open={showOptions}
            close={() => setShowOptions(false)}
          >
            <div onClick={() => setShowDeleteModal(true)}>Remove team member</div>
          </Actions>
        }
      </S.itemBody>
      <SimpleModal
        isOpen={showDeleteModal}
        onDismiss={closeDeleteModal}
        confirm={deleteMember}
      >
        <p>Are you sure you want to remove this team member?</p>
      </SimpleModal>
    </S.item>
  );
};

export default ProjectMemberItem;
