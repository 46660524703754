import React from 'react';
import { SetStateType } from '../../../types/util.types';
import * as S from './comment-field';

interface Props {
  value: string;
  setValue: SetStateType<string>;
  bulletList?: boolean;
  placeholder: string;
  maxWidth?: string;
  service?: boolean;
  disabled?: boolean;
}

const CommentField = ({
  value,
  setValue,
  bulletList,
  placeholder,
  ...rest
}: Props) => {
  const bullet = '•';
  const bulletSpace = `${bullet} `;

  const handleInput = (evt: any) => {
    if (!bulletList) {
      setValue(evt.target.value);
      return;
    }
    const { keyCode, target } = evt;
    const { selectionStart, value } = target;

    if (keyCode === 13) {
      target.value = [...value]
        .map((item, index) =>
          index === selectionStart - 1 ? `\n${bulletSpace}` : item
        )
        .join('');

      target.selectionStart = selectionStart + bulletSpace.length;
      target.selectionEnd = selectionStart + bulletSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletSpace}${value}`;
    }
    setValue(target.value);
  };

  const handleInput2 = (evt: any) => {
    setValue(evt.target.value);
  };

  return (
    <S.wrapper {...rest}>
      {bulletList ? (
        <textarea
          onKeyUp={handleInput}
          placeholder={placeholder}
          onChange={handleInput2}
          value={value}
        />
      ) : (
        <textarea
          onChange={handleInput}
          placeholder={placeholder}
          value={value}
        />
      )}
    </S.wrapper>
  );
};

export default CommentField;
