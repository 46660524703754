import React, { useEffect, useState, useCallback, useRef  } from 'react';
import { getProjects } from '../../store/projects/projects.actions';
import { Project } from '../../store/projects/projects.types';
import {
  HeaderWrapper,
  HeaderTop,
  HeaderBottom
} from '../../components/Header/StyledHeader';
import ViewType from '../../components/ViewType';
import TopBar from '../../components/TopBar';
import Pager from '../../components/Pager';
import ProjectProposalCard from './ProjectProposalCard';
import CardLoader from '../../components/CardLoader';
import * as S from './client-page';
import sortIcon from '../../images/order.svg';
import { NUMBER_OF_ITEMS_FOR_PAGINATION, PLACEHOLDER_ARRAY } from '../../utils/general.utils';
import Modal from '../../components/Modal';
import ChangePasswordForm from '../../components/ChangePasswordForm';
import useGlobal from '../../store';
import { text as NotAvailable } from '../../components/NoResult/no-result';

const ClientPage = () => {
  const first = useRef(true);
  const [user] = useGlobal((state) => state.auth.currentUser);
  const [listView, setListView] = useState<boolean>(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [name, setName] = useState<string>('');
  const [sort, setSort] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const fetchProjects = useCallback(async () => {
    try {
      const { totalPageNumber, content } = await getProjects({
        page,
        size: NUMBER_OF_ITEMS_FOR_PAGINATION,
        filter: '',
        name,
        sort
      });
      setProjects(content);
      setTotalPageNumber(totalPageNumber);
      setIsLoading(false)
    } catch (error) {
      console.warn(error);
      setIsLoading(false)
    }
  }, [name, sort, page]);

  const toggleSort = () => setSort(!sort);

  useEffect(() => {
    if (first.current) return;
    page === 0 ? fetchProjects() : setPage(0);

    return () => {
      first.current = false
    }
  }, [name, sort, page, fetchProjects]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    if(user?.resetPasswordRequired) setOpenModal(true);
    // eslint-disable-next-line
  }, [user?.resetPasswordRequired])

  const closeModal = async () => {
    await fetchProjects();
    setOpenModal(false);
  }

  return (
    <>
      <TopBar setSearch={setName} placeholder="Search by title" />
      <HeaderWrapper>
        <HeaderTop>
          <h2>Project proposals</h2>
        </HeaderTop>
        <HeaderBottom>
          <S.headerActions>
            <span onClick={toggleSort}>
              <img src={sortIcon} alt="" />
              {sort ? 'Ascending' : 'Descending'}
            </span>
            <ViewType setList={setListView} isListView={listView} />
          </S.headerActions>
        </HeaderBottom>
      </HeaderWrapper>
      {projects.length > 0 && <S.projectProposalsList listView={listView}>
        {
          isLoading ? (
            PLACEHOLDER_ARRAY.map((p:any, i:number) => (
              <CardLoader key={i}/>
            ))
          ): (
            projects?.map((p: Project) => (
              <ProjectProposalCard
                key={p.id}
                {...p}
                listView={listView}
                onChangeCallback={fetchProjects}
              />
            ))
          )
        }
      </S.projectProposalsList>
      }
      {!projects.length && !name ?
        <NotAvailable>No projects available at this moment</NotAvailable> :
        name && <NotAvailable>No results were found for '{name}'</NotAvailable>
      }
      {totalPageNumber > 1 && (
        <Pager page={page} setPage={setPage} pageCount={totalPageNumber} />
      )}

      <Modal isOpen={openModal} onDismiss={() => {}}>
        <ChangePasswordForm closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default ClientPage;
