import React, { useState, FormEvent, useContext } from 'react';
import { useParams } from 'react-router-dom';
import NewSearchInput from '../../../components/Inputs/NewSearchInput';
import Button from '../../../components/Button/Button';
import { addPhase } from '../../../store/projects/projects.actions';
import { phaseOverview } from '../../../store/phase/phase.actions';
import { PhaseType } from '../../../store/phase/phase.types';
import { ProgressContext } from '../Progress';
import * as S from './project-phases';

interface Props {
  closeModal: () => void;
}

interface Params {
  id: string;
}

const AddPhase = ({ closeModal }: Props) => {
  const { updateProject } = useContext(ProgressContext);
  const { id } = useParams<Params>();
  const [title, setTitle] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const searchPhase = async () => {
    const { content } = await phaseOverview({ title });
    return content;
  };

  const confirm = async (evt: FormEvent) => {
    evt.preventDefault();
    if (submitting) return;
    try {
      setSubmitting(true);
      const data = await addPhase(title, id);
      updateProject(data);
      closeModal();
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <S.modalWrapper>
      <h3>Add phase</h3>
      <form onSubmit={confirm}>
        <NewSearchInput
          setValue={(value: string) => setTitle(value)}
          selectValue={(item: PhaseType) => setTitle(item.name)}
          value={title}
          label="Phase"
          labelProps={['name']}
          onSearch={searchPhase}
        />
        <Button secondary type="submit" disabled={!title}>
          Confirm
        </Button>
      </form>
    </S.modalWrapper>
  );
};

export default AddPhase;
