import tw, { styled, css } from 'twin.macro';
import { Dropdown } from '../../../components/Actions/actions-component';

export const wrapper = styled.div(
  ({
    theme: {
      media: {
        breakpoints: { smMax }
      }
    }
  }) => [
    css`
      ${tw`w-full`};
      max-width: 16rem;

      ${Dropdown} {
        ${tw`gap-15`}
        div {
          ${tw`py-0`}
        }
      }

      @media screen and (max-width: ${smMax}px) {
        max-width: none;
      }
    `
  ]
);
