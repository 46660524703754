import React, { useState } from 'react';
import { RoleType } from '../../../store/roles/roles.types'
import RoleItem from './RoleItem';
import Modal from '../../../components/Modal';
import * as S from './roles-list';
import useTaskRoles from '../../../hooks/useTaskRoles';
import EditRole from './EditRole';
import { removeRole } from '../../../store/roles/roles.action';
import SimpleModal from '../../../components/Modal/SimpleModal/SimpleModal';

const List = () => {
  const { taskRoles, setTaskRoles, fetchRoles } = useTaskRoles();

  const [activeEdit, setActiveEdit] = useState<boolean>(false);
  const [edit, setEdit] = useState<RoleType>();
  const [activeDelete, setActiveDelete] = useState<boolean>(false);
  const [deleteRole, setDeleteRole] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  
  const closeEditModal = () => {
    setActiveEdit(false);
  };

  const showEditModal = () => {
    setActiveEdit(true);
  };

  const onEdit = (role: RoleType) => {
    setEdit(role);
    showEditModal();
  };

  const onSuccess = async (data: RoleType) => {
    await fetchRoles()
    closeEditModal();
  };

  const deleteRoleHandler = async () => {
    setIsDeleting(true);
    await removeRole(deleteRole!);
    setTaskRoles(taskRoles.filter((role: RoleType) => role.id !== deleteRole));
    closeDeleteModal();
    setIsDeleting(false);
  };

  const showDeleteModal = (id: number) => {
    setActiveDelete(true);
    setDeleteRole(id);
  };

  const closeDeleteModal = () => {
    setActiveDelete(false);
    setDeleteRole(null);
  };

  return (
    <>
      <S.wrapper>
        <S.row>
          <S.roleName>Name</S.roleName>
          <S.rolePrice>Price</S.rolePrice>
        </S.row>
        {taskRoles?.map((role: RoleType) =>
          <RoleItem
            onEdit={onEdit}
            onDelete={showDeleteModal}
            role={role}
            key={role.id}
          />
        )}
      </S.wrapper>

      <Modal isOpen={activeEdit} onDismiss={closeEditModal}>
        <EditRole onSuccess={onSuccess} role={edit!} />
      </Modal>

      <SimpleModal
        isOpen={activeDelete}
        onDismiss={closeDeleteModal}
        confirm={deleteRoleHandler}
        disabled={isDeleting}
      >
        <p>Are you sure that you want to to delete this role?</p>
      </SimpleModal>
    </>
  );
};

export default List;