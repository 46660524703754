import React from 'react'
import useGlobal from "../../../store";
import { RoleType } from '../../../store/roles/roles.types';
import { isSuperAdmin } from "../../../utils";
import images from '../../../utils/images.utils'
import * as S from './roles-list';

interface Props {
  role: RoleType;
  onEdit: (role: RoleType) => void;
  onDelete: (id: number) => void;
}

const RoleItem = ({ onEdit, onDelete, role }: Props) => {
  const [user] = useGlobal((state) => state.auth.currentUser);

  return (
    <S.row>
      <S.roleName indented>
        {role.name}
      </S.roleName>
      <S.rolePrice indented>
        <span>{role?.price.toFixed(2)} EUR</span>
        {
          isSuperAdmin(user) &&
          <S.imgWrap>
            <S.rolesActionImage
              src={images.edit}
              alt="Edit Role"
              onClick={() => onEdit(role)}
            />
            <S.rolesActionImage
              src={images.delete}
              alt="Delete Role"
              onClick={() => onDelete(role.id)}
            />
          </S.imgWrap>
        }
      </S.rolePrice>
    </S.row>
  )
}

export default RoleItem
