import tw, { styled, css } from 'twin.macro';

export const form = styled.form(() => [
  css`
    ${tw`w-730 ml-125 mt-40`}
  `
]);

export const formBody = styled.div(() => [
  css`
    ${tw`px-95 pt-27 pb-95 bg-white w-730 h-full`}
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  `
]);

export const inputWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col`}

    h2 {
      ${tw`text-40 font-semibold leading-46 text-textBlack mb-35`}
    }
  `
]);

export const editField = styled.input(() => [
  css`
    ${tw`border-2 border-darkBlue outline-none pl-10 py-5 pr-10 mb-24`}
  `
]);

export const inputFields = styled.div(() => [
  css`
    ${tw`flex flex-col`}
  `
]);

export const priceWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col`}

    h3 {
      ${tw`text-32 font-semibold leading-36 text-textBlack mb-28`}
    }
  `
]);

export const priceFields = styled.div(() => [
  css`
    ${tw`pt-28 border-t-2 border-b-2 border-gray9`};
    > div:nth-child(2) {
      ${tw`flex gap-28`}
    }
  `
]);

export const footer = styled.div(() => [
  css`
    ${tw`flex items-start self-end w-full`}
  `
]);

export const footerActions = styled.div(() => [
  css`
    ${tw`flex flex-col justify-end w-full sm:flex-row mt-24`};

    button {
      ${tw`w-full sm:w-auto sm:first:mr-24  first:mb-24 sm:first:mb-0`}
    }
  `
]);
