import React, { useState, useEffect, FormEvent, useContext } from 'react';
import { useParams } from 'react-router-dom';
import NewSearchInput from '../../../components/Inputs/NewSearchInput';
import Button from '../../../components/Button/Button';
import { searchMethod } from '../../../store/method-service/actions';
import {
  addMethodToPhase,
  addMethodToProjectServicePackage
} from '../../../store/projects/projects.actions';
import { ProjectMethod } from '../../../store/projects/projects.types';
import { ProgressContext } from '../Progress';

import * as S from './project-phases';

interface Props {
  closeModal: () => void;
  categories: string;
  phaseId: number;
  servicePackageId?: number;
  level?: string;
}

interface Params {
  id: string;
}

const labelFormat = {
  level: {
    STARTER: 'STARTER',
    LIGHTHOUSE: 'LIGHTHOUSE',
    TEASER: 'TEASER',
    LEAN: 'LEAN',
    WIZARD: 'WIZARD',
    DESIGN_TO_COST: 'DESIGN TO COST'
  }
};

const AddMethod = ({
  closeModal,
  categories,
  phaseId,
  level,
  servicePackageId
}: Props) => {
  const { updateProject } = useContext(ProgressContext);
  const { id } = useParams<Params>();
  const [name, setName] = useState<string>('');
  const [selected, setSelected] = useState<ProjectMethod | null>(null);

  const onSearch = async (searchTerm: string) => {
    return await searchMethod(searchTerm, categories, level);
  };

  const confirm = async (evt: FormEvent) => {
    evt.preventDefault();
    const data = servicePackageId
      ? await addMethodToProjectServicePackage(
        id,
        phaseId,
        servicePackageId,
        selected!
      )
      : await addMethodToPhase(id, phaseId, selected!);

    if (!!data) {
      updateProject(data);
      closeModal();
    }
  };

  useEffect(() => {
    if (name && selected && name !== selected?.name) setSelected(null);
  }, [name, selected]);

  return (
    <S.modalWrapper>
      <h3>Add method to {servicePackageId ? 'service package' : 'phase'}</h3>
      <form onSubmit={confirm}>
        <NewSearchInput
          setValue={(value: string) => setName(value)}
          selectValue={(item: ProjectMethod) => {
            setName(item.name);
            setSelected(item);
          }}
          value={name}
          label="Enter method"
          labelProps={['name', 'level']}
          labelPropFormat={labelFormat}
          onSearch={onSearch}
        />
        <Button secondary type="submit" disabled={!selected}>
          Confirm
        </Button>
      </form>
    </S.modalWrapper>
  );
};

export default AddMethod;
