import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const wrapper = styled.div(
  ({
    theme: {
      media: {
        breakpoints: { lgMin }
      }
    }
  }) => [
    css`
      ${tw`flex flex-col  bg-darkBlue text-white`};
      height: 5rem;

      > img {
        ${tw`mt-auto mb-80 mx-auto`}
        width: 11.8rem;
      }

      @media screen and (min-width: ${lgMin}px) {
        ${tw`h-auto`}
        min-width: 37.5rem;
        max-width: 37.5rem;
      }

      @media screen and (min-width: 320px) and (max-width: 575px) {
        height: 8rem;
        min-width: 37.5rem;
      }

      @media print {
        ${tw`hidden`}
      }
    `
  ]
);

export const header = styled.div<Props>(
  ({
    theme: {
      media: {
        breakpoints: { lgMin }
      }
    }
  }) => [
    css`
      ${tw`flex items-center justify-between lg:justify-center text-24 font-semibold lg:leading-32 py-5 px-10`};
      min-height: 5rem;

      @media screen and (min-width: ${lgMin}px) {
        min-height: 7.2rem;
      }

      a {
        ${tw`text-24 font-bold sm:font-semibold lg:leading-32 pt-10 pb-10 sm:px-10 text-white hover:text-white`}
        font-family: 'GTSuperText-Black', 'Titillium Web';

        @media screen and (min-width: 320px) and (max-width: 575px) {
          width: 25rem;
          margin-right: auto;
          margin-left: 1rem;
        }
      }
    `
  ]
);

export const headerLogo = styled.span(() => [
  css`
    ${tw`p-5 mb-10 sm:hidden`}
    width: 6rem;
    height: 6rem;
  `
]);

export const userMenuWrapper = styled.div(
  ({
    theme: {
      typography: { sectionSubtitleSmall }
    }
  }) => [
    css`
      ${tw`flex lg:flex-col items-center px-10 lg:pb-30 lg:py-40 min-w-25 lg:min-w-full`};

      > div:first-of-type {
        ${tw`flex items-center justify-center w-36 h-26 lg:w-60 lg:h-60 mb-10 lg:mb-30 bg-white overflow-hidden text-textBlack font-semibold lowercase text-6xl`}
        border-radius: 0.4rem;
        img {
          ${tw`w-full h-full`}
        }
      }

      > span:first-of-type {
        ${sectionSubtitleSmall}
        ${tw`hidden lg:block mb-20 `}
      }
    `
  ]
);

export const userMenuActions = styled.div<Props>(({ active }) => [
  css`
    ${tw`relative z-10 flex flex-col items-center justify-center w-full text-3xl font-semibold`}

    > span {
      ${tw`flex items-center cursor-pointer mb-10`}

      > div {
        ${tw`ml-2`}
      }

      svg {
        ${tw`h-6 `}
        transition: all .5s;

        path {
          fill: white;
        }
      }
    }
  `,
  active &&
    css`
      > span {
        svg {
          ${tw`transform rotate-180`}
        }
      }
    `
]);

export const userMenuDropdown = styled.div(
  ({
    theme: {
      media: {
        breakpoints: { lgMin }
      }
    }
  }) => [
    css`
      ${tw`absolute flex flex-col gap-15 py-15 border-2 border-green bg-gray-800 `}
      top: 50%;
      max-width: 25.5rem;
      border-radius: 2px;

      span,
      a {
        ${tw`px-15 text-gray-200 hover:text-green cursor-pointer`};
      }
    `
  ]
);

export const navigation = styled.div<Props>(
  ({
    theme: {
      media: {
        breakpoints: { lgMin }
      }
    }
  }) => [
    css`
      ${tw`w-full lg:my-40`};

      a {
        ${tw`flex items-center px-20  lg:px-20 text-white hover:text-green text-18 lg:text-4xl font-semibold`}
        height: 4rem;
        &:hover path {
          ${tw`fill-green`}
        }

        @media screen and (min-width: ${lgMin}px) {
          height: 5.4rem;
        }
        &.active {
          ${tw`text-green`}
            path {
              ${tw`fill-green`}
            }
        }

        > div {
          ${tw`mr-15`}
        }
      }

      svg {
        path {
          fill: white;
        }
      }
    `
  ]
);

export const navigationMenu = styled.div<Props>(({ isOpen }) => [
  css`
    /* ${tw`z-50 bg-pink`}; */
    ${tw`flex flex-wrap justify-center lg:top-0 absolute  w-screen lg:static lg:w-auto p-10 lg:p-0 lg:h-auto bg-darkBlue  z-50`}
    list-style-type: none;
    top: 5rem;
    right: ${isOpen ? '0' : '100vw'};
    transition: right 300ms ease;

    > img:last-of-type {
      ${tw`self-center py-10 lg:self-auto max-w-7 lg:max-w-full w-36`}
    }

    @media screen and (min-width: 320px) and (max-width: 575px) {
      top: 7.5rem;

      > img:last-of-type {
        display: none;
      }
    }
  `
]);

export const hamburger = styled.div(() => [
  css`
    ${tw`block lg:hidden`};

    svg {
      width: 40px;
      height: 36px;
      path {
        fill: white;
      }
    }
  `
]);
