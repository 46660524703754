import tw, { styled, css } from 'twin.macro';

export const filtersWrapper = styled.div(() => [
  css`
    ${tw` flex flex-wrap items-center my-25 mx-10 md:mx-30 text-textBlack font-semibold text-24`};

    > span {
      ${tw`mr-20`}
    }
  `
]);

export const title = styled.h3(() => [
  css`
    ${tw`mx-10 md:mx-30 mb-30 text-40 leading-46 font-semibold text-textBlack`};
  `
]);

export const listWrapper = styled.div(() => [
  css`
    ${tw`mx-10 md:mx-30 grid gap-12  text-white text-center`};

    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    @media screen and (max-width: 380px) {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  `
]);
