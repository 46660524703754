import React from 'react';
// import { AllServices } from '../../../store/method-service/types';
import { Wrapper } from './StyledDetails';

import Method from './Method';
import Service from './Service';

interface Props {
  open: boolean;
  isMethod: boolean;
  data: any;
  fetchAllServices: () => Promise<void>;
}

const Details = ({ open, isMethod, data, fetchAllServices }: Props) => {
  return (
    <>
      {open && (
        <Wrapper open={open}>
          {isMethod ? (
            <Method {...data} fetchAllServices={fetchAllServices} />
          ) : (
            <Service {...data} fetchAllServices={fetchAllServices} />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Details;
