import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';
import StyledCbRadio from '../../../components/Inputs/checkbox-radio';

export const wrapper = styled.div(() => [css`
  div:nth-child(4) {
    div {
      div {
        ${tw`gap-10 pt-6`}
      }
    }
  }
`]);

export const form = styled.form(() => [
  css`
    ${tw`flex flex-col w-full `};

    button {
      ${tw`self-end `}
    }
  `
]);

export const dropdownPlaceholder = styled.div(() => [
  css`
    ${tw`flex items-center `};
    > div {
      ${tw`mr-10`}
    }
  `
]);
export const avatarWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col md:flex-row  items-center md:justify-start w-full mt-40 flex-wrap `}
    > img {
      ${tw`w-full mb-0`}
      max-width: 25rem;
      object-fit: cover;
      object-position: 0 30%;
    }
  `
]);
export const actions = styled.div(() => [
  css`
    ${tw`inline-flex flex-col md:self-end mt-10`}

    span {
      ${tw`block w-full mt-3 text-14 leading-22 font-semibold text-textBlack`}
    }
  `
]);

export const droparea = styled.div<Props>(({ preview }) => [
  css`
    ${tw`flex flex-col items-center justify-center w-full h-full border-2 border-darkBlue border-dashed outline-none text-18 font-semibold text-center md:mr-30 cursor-pointer`};
    max-width: 25rem;
    height: 14rem;
    background: rgba(166, 188, 209, 0.15);
    border-radius: 8px;
    max-height: 14rem;

    > img {
      ${tw`h-full w-full`}
      object-fit: contain;
    }

    div {
      ${tw`flex flex-col items-center p-20`}

      img {
        ${tw`mt-10`}
      }
    }

    p {
      ${tw`text-15 tracking-normal`}
    }
  `,
  preview &&
  css`
      ${tw`border-0 px-0`}
      img {
        ${tw`w-full mt-0`}
      }
    `
]);

export const projectCategory = styled.div(() => [
  css`
    ${tw`flex`};
    ${StyledCbRadio} {
      &:before {
        ${tw`block h-16 mr-15 bg-green`}
        content: '';
        width: 0.4rem;
      }

      &:nth-child(2) {
        &:before {
          ${tw` bg-purple`}
        }
      }
      &:nth-child(3) {
        &:before {
          ${tw` bg-yellow`}
        }
      }
      &:nth-child(4) {
        &:before {
          ${tw` bg-blue`}
        }
      }
      &:nth-child(5) {
        &:before {
          ${tw` bg-pink`}
        }
      }
    }
  `
]);

export const price = styled.div(() => [
  css`
    ${tw`pt-28 border-t-2 border-b-2 border-gray9`};
    div:nth-child(2) {
      ${tw`flex gap-28`}
    }
  `
]);
