import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGlobal from '../../store';
import { getProgress } from '../../store/projects/projects.actions';
import { ProjectProgress } from '../../store/projects/projects.types';
import * as S from './progress-page';
import ProjectHeader from './ProjectHeader';
import ProjectCategories from './ProjectCategories';
import ProjectDuration from './ProjectDuration';
import ProjectMembers from './ProjectMembers';
import ExternalCost from './ExternalCost';
import ProjectPhases from './ProjectPhases';
import HeaderImage from './HeaderImage';
import Button from '../../components/Button';
import { isMemberRole } from '../../utils/index';
import { routePaths } from '../../utils/routes.util';

interface Params {
  id: string;
}

interface ProgressContext {
  updateProject: (data: ProjectProgress) => void;
  project: ProjectProgress;
  isMemberRole?: boolean;
}

export const ProgressContext = React.createContext<ProgressContext>({
  updateProject: (data) => { },
  project: {
    duration: null,
    imageUrl: '',
    name: '',
    phases: [],
    projectMembers: [],
    status: '',
    externalCosts: [],
    client: null,
    events: [],
    projectCategories: [],
    id: 0,
    currency: ''
  },
  isMemberRole: false
});

const Progress = () => {
  const { id } = useParams<Params>();
  const [user] = useGlobal((state) => state.auth.currentUser);
  const [data, setData] = useState<ProjectProgress | null>(null);

  useEffect(() => {
    (async () => {
      const res = await getProgress(id);
      !!res && setData(res);
    })();
  }, [id]);

  if (!data) return null;

  return (
    <ProgressContext.Provider
      value={{
        updateProject: setData,
        project: data,
        isMemberRole: isMemberRole(user)
      }}
    >
      <HeaderImage />
      <ProjectHeader />
      <ProjectCategories categories={data.projectCategories} />
      <ProjectDuration />
      <ProjectPhases />
      <ExternalCost />
      <ProjectMembers />
      <S.buttonWrapper>
        <Button
          to={`${routePaths.PROJECTS}/${id}/summary`}
          disabled={data.client === null || isMemberRole(user)}
        >
          I want to see Summary
        </Button>
      </S.buttonWrapper>
    </ProgressContext.Provider>
  );
};

export default Progress;
