import React, { useState, Fragment, useEffect } from 'react';
import { MemberRole } from '../../../store/method-service/types';
import * as DropdownS from '../../../components/Dropdown/dropdown-component';
import { SetStateType } from '../../../types/util.types';
import Actions from '../../../components/Actions';
import * as S from './member-field';
import MemberRow from './MemberRow';
import useGlobal from '../../../store';

interface Props {
  selectedRoles: MemberRole[];
  setSelectedRoles: SetStateType<MemberRole[]>;
  label: string;
  title?: string;
  disabled?: boolean;
  details?: boolean;
}

const MemberField = ({
  selectedRoles,
  setSelectedRoles,
  label,
  title,
  disabled,
  details
}: Props) => {
  const [roles] = useGlobal((state) => state.services.memberRoles);

  const newRoles = details && disabled ? selectedRoles : roles;
  const [open, setOpen] = useState<boolean>(false);

  const [selected, setSelected] = useState<MemberRole[]>(selectedRoles || []);

  const toggle = () => setOpen(!open);
  const close = () => {
    if (open) setOpen(false);
    setSelectedRoles(selected);
  };

  useEffect(() => {
    setSelected(selectedRoles);
  }, [selectedRoles]);

  return (
    <S.wrapper>
      {title && <S.title>{title}</S.title>}
      <DropdownS.wrapper open={open}>
        <Actions toggle={toggle} open={open} close={close} dropdown scrollable>
          {newRoles &&
            newRoles.length > 0 &&
            newRoles.map((role: MemberRole) => (
              <MemberRow
                key={role.memberRoleId}
                role={role}
                setSelected={setSelected}
                selected={selected}
                disabled={disabled}
              />
            ))}
        </Actions>
        <DropdownS.label
          hasValue={!!selectedRoles.length}
          open={open}
          labelColor="#0A0A33"
        >
          {label}
        </DropdownS.label>
        <DropdownS.items>
          {selectedRoles?.map((item: MemberRole, index: number) => {
            return (
              <Fragment key={item.memberRoleId}>
                {item.name} ({item.personDays} pd)
                {index < selectedRoles.length - 1 && ',\u00A0'}
              </Fragment>
            );
          })}
        </DropdownS.items>
      </DropdownS.wrapper>
    </S.wrapper>
  );
};

export default MemberField;
