import React, { useState, ChangeEvent, FormEvent, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import * as S from './external-cost';
import Button from '../../../components/Button/Button';
import TextInput from '../../../components/Inputs/TextInput';
import {
  createExternalCost,
  editExternalCost
} from '../../../store/projects/projects.actions';
import { ExternalCost, ExternalCostType } from '../../../store/projects/projects.types';
import { validatePrice } from '../../../utils/price.utils';
import ClickableText from '../../../components/ClickableText/ClickableText';
import addIcon from '../../../images/add-2.svg';
import removeIcon from '../../../images/delete.svg';

interface Params {
  id: string;
}

interface Props {
  onSuccess: (costs: ExternalCostType | ExternalCostType[]) => void;
  cost?: ExternalCostType | null;
}

const AddCost = ({ onSuccess, cost }: Props) => {
  const { id } = useParams<Params>();
  const [externalCosts, setExternalCosts] = useState<ExternalCost[]>([{
    name: cost?.name || '',
    price: cost?.price.toString() || ''
  }]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const changeHandler = (option: any, index: number, event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === 'price' && !validatePrice(value, 100000)) return;

    const newState = externalCosts.map((obj: any, ind) => {
      if (ind === index) {
        return { ...obj, [name]: value };
      }
      return obj;
    });

    setExternalCosts(newState);
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      setSubmitting(true);
      const data = cost
        ? await editExternalCost(id, cost.id, externalCosts[0].name, externalCosts[0].price)
        : await createExternalCost(id, externalCosts);
      onSuccess(data);
    } catch {
      setSubmitting(false);
    }
  };

  const isDisabled =
    externalCosts.some(elem => elem.name.trim() === '' || elem.price === '' || +elem.price === 0) ||
    submitting;

  const addCost = (event: FormEvent) => {
    event.preventDefault();
    setExternalCosts([...externalCosts, { name: "", price: "" }])
  }

  const deleteExternalCost = (index: number) => {
    const updatedExternalCosts = [...externalCosts];
    updatedExternalCosts.splice(index, 1);
    setExternalCosts(updatedExternalCosts);
  };

  return (
    <S.modalWrapper>
      <h3>{cost ? 'Edit' : 'Add'} external cost</h3>
      <S.form onSubmit={onSubmit}>
        <S.inputWrapper canDelete={externalCosts.length > 1}>
          {externalCosts && externalCosts.map((elem: ExternalCost, index: number) =>
            <Fragment key={index}>
              <TextInput
                value={elem.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  changeHandler(elem, index, e);
                }}
                name="name"
                label="Cost name"
                disableAutoComplete
                key={index}
              />
              <TextInput
                value={elem.price.replace(',', '')}
                name="price"
                label="Price"
                disableAutoComplete
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  changeHandler(elem, index, e);
                }}
              />
              {externalCosts.length > 1 &&
                <img
                  onClick={() => deleteExternalCost(index)}
                  src={removeIcon}
                  alt="Remove External cost"
                />
              }
            </Fragment>
          )}
        </S.inputWrapper>
        {!cost &&
          <ClickableText
            icon={addIcon}
            onClick={addCost}
          >
            Add more costs
          </ClickableText>
        }
        <Button
          type="submit"
          secondary
          disabled={isDisabled}
        >
          Confirm
        </Button>
      </S.form>
    </S.modalWrapper>
  );
};

export default AddCost;
