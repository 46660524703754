export const NUMBER_OF_ITEMS_FOR_PAGINATION = 12;
export const PLACEHOLDER_ARRAY = Array.from(Array(NUMBER_OF_ITEMS_FOR_PAGINATION).keys())

export const colorPicker = (disciplineName?: string) => {
    switch (disciplineName) {
        case 'UX Design': return '#ed0c6e';
        case 'Industrial Design': return '#3cd52e';
        case 'Software Development': return '#00a7e1';
        case 'Professional Services': return '#da70d6';
        case 'Service Design': return '#ffc222';
        default:
            return '#b2b2b2';
    }
}

export const parsedDeliverables = (d: any) => {
    return d?.reduce(
        (acc: string, current: any) => {
            const line = `• ${current.name}\n`;
            acc += line;
            return acc;
        },
        ''
    )
}
