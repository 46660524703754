import tw, { styled, css } from 'twin.macro';
// import { StyledProps as Props } from '../../types/util.types';

export const wrapper = styled.div(() => [
  css`
    ${tw`flex flex-col w-full text-textBlack justify-center pb-120 pt-80 xl:pt-0 px-10 md:px-0`}
  `
]);
export const formTitle = styled.div(() => [
  css`
    max-width: 540px;
  `
]);

export const title = styled.h1(() => [
  css`
    ${tw`flex `};
  `
]);

export const subtitle = styled.div(
  ({
    theme: {
      typography: { sectionSubtitle, sectionSubtitleSmall },
      media: {
        breakpoints: { smMax }
      }
    }
  }) => [
    css`
      ${sectionSubtitle}
      ${tw`mb-80 font-normal leading-44 whitespace-pre-line`};

      @media screen and (max-width: ${smMax}px) {
        ${sectionSubtitleSmall}
        ${tw`text-4xl mb-80 font-normal leading-44 whitespace-pre-line tracking-normal`};
      }

      span {
        ${tw`font-bold`}
      }
    `
  ]
);

export const form = styled.form(() => [
  css`
    ${tw`flex flex-col `};
    max-width: 540px;
  `
]);

export const formActions = styled.div(() => [
  css`
    ${tw`flex flex-col justify-between  h-58`}

    button {
      ${tw`self-end`};
    }
    a {
      ${tw`self-end `}
    }
  `
]);

export const bottomActions = styled.div(
  ({
    theme: {
      typography: { p, pSmall }
    }
  }) => [
    css`
      ${p}
      ${tw`flex justify-end items-center p-20 `};

      > div {
        ${tw`mr-20`}
      }

      span {
        ${tw`text-2xl md:text-4xl cursor-pointer tracking-normal`}
      }
    `
  ]
);
