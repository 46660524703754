import React, { FunctionComponent } from 'react';
import * as S from './auth-wrapper';
import logo from '../../images/logo-green.svg';
import { ReactSVG } from 'react-svg';

const AuthWrapper: FunctionComponent = ({ children }) => {
  return (
    <S.wrapper>
      <S.intro>
        <h2>Clever Project Composer</h2>
        <p>Lets design your project together!</p>
        <S.logo>
          <ReactSVG src={logo} />
        </S.logo>
      </S.intro>
      <S.content>{children}</S.content>
    </S.wrapper>
  );
};

export default AuthWrapper;
