import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import ClientForm from '../../../components/ClientForm';
import { attachClient } from '../../../store/projects/projects.actions';
import { SearchMemberItem } from '../../../store/members/members.types';
import { ReactComponent as ClientIcon } from '../../../images/icon-client.svg';
import closeIcon from '../../../images/close-x.svg';

interface Props {
  onSuccess: (client: any) => void;
  onClose: () => void;
}

interface Params {
  id: string;
}

const NewClient = ({ onSuccess, onClose }: Props) => {
  const { id } = useParams<Params>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<SearchMemberItem | null>(
    null
  );

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const data = await attachClient(selectedUser!.id!, id);
      onSuccess(data);
    } catch {
      setSubmitting(false);
    }
  };

  return (
    <>
      <img src={closeIcon} alt="" onClick={onClose} />
      <ClientForm
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        title="Create Client"
      />
      <Button disabled={!selectedUser || submitting} onClick={onSubmit}>
        <ClientIcon /> Create Client
      </Button>
    </>
  );
};

export default NewClient;
