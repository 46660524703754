import React from 'react';
import { SetStateType } from '../../types/util.types';
import * as S from './view-type';
import card from '../../images/card-view.svg';
import list from '../../images/list-view.svg';
import { ReactSVG } from 'react-svg';

interface Props {
  setList: SetStateType<boolean>;
  isListView: boolean;
}

const ViewType = ({ isListView, setList }: Props) => (
  <S.wrapper isListView={isListView}>
    <ReactSVG src={card} onClick={() => setList(false)} />
    <ReactSVG src={list} onClick={() => setList(true)} />
  </S.wrapper>
);

export default ViewType;
