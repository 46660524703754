import api from "../../config/api.config";
import { handleErrors } from "../../utils/error.utils";
import { GetRoles, CreateRole, RoleType } from "./roles.types";
import { toastUtil } from '../../utils/toast.util';

export const getRoles: GetRoles = async () => {
  try {
    const response = await api(`/roles`);
    return response.data;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const createRole: CreateRole = async ({
  name,
  price
}) => {
  try {
    const roleData = {
      name: name,
      price: price
    };
    const {
      data: {
        data,
        meta: { message }
      }
    } = await api.post(`/roles`, roleData);
    toastUtil('success', message);
    return data;
  } catch (error) {
    handleErrors(error);
  }
}

export const editRole = async (
  currentName: string,
  name: string,
  price: number
): Promise<RoleType[]> => {
  try {
    const {
      data: {
        data: { roles },
        meta: { message }
      }
    } = await api.put(
      `/roles/${currentName}`,
      {
        name,
        price
      }
    );
    toastUtil('success', message);
    return roles;
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};

export const removeRole = async (
  roleId: number
) => {
  try {
    const {
      data: {
        meta: { message }
      }
    } = await api.delete(
      `/roles/${roleId}`
    );
    toastUtil('success', message);
  } catch (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
};