import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../../types/util.types';
import { StyledButton } from '../../../components/Button/styles';

export const wrapper = styled.div<Props>(({ isResended }) => [
  css`
    ${tw`flex flex-col px-25 py-15 flex-wrap items-center  text-textBlack `};
    border-radius: 4px;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.15);
  `,
  isResended &&
    css`
      ${tw`border-2 border-solid border-green`}
    `
]);

export const header = styled.div(() => [
  css`
    ${tw`flex flex-wrap justify-between items-center w-full text-gray1 text-18 font-semibold`};

    span {
      ${tw`flex items-center text-textBlack`}

      img {
        ${tw`mr-10`}
      }
    }
  `
]);

export const body = styled.div(() => [
  css`
    ${tw`w-full my-10 text-textBlack text-32 leading-36 font-semibold text-left`};
  `
]);

export const footer = styled.div<Props>(({ resent, revoke }) => [
  css`
    ${tw`flex justify-between items-end w-full min-h-4  mt-auto text-gray1 text-18 leading-28 font-semibold break-all`};

    p {
      ${tw`mr-10 whitespace-no-wrap overflow-hidden`}
      text-overflow: ellipsis;
    }

    ${StyledButton} {
      ${tw`flex-shrink-0`}
    }
  `,
  revoke &&
    css`
    ${StyledButton} {
      ${tw`bg-error`}
    }
  `,
  resent &&
    css`
      span {
        ${tw`flex gap-5 text-green items-center`}

        svg {
          ${tw`w-24 h-24`}
          path {
            ${tw`fill-green`}
          }
        }
      }
    `
]);

export const modalWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col w-full p-20 sm:p-30 bg-white border-2 border-darkBlue`}
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    min-height: 594px;
    max-width: 730px;
  `
]);

export const modalContent = styled.div(() => [
  css`
    ${tw`pt-40 pb-10`};

    > div {
      &:not(:last-of-type) {
        ${tw`flex-row border-b-2 border-gray9 `};
      }
    }
  `
]);

export const modalItem = styled.div<Props>(
  ({
    theme: {
      typography: { sectionSubtitleSmall }
    }
  }) => [
    css`
      ${tw`flex flex-col text-textBlack py-15 flex-wrap md:flex-no-wrap `};
      > div {
        ${tw`flex flex-wrap md:flex-no-wrap`}
      }
      p {
        ${sectionSubtitleSmall}
        ${tw`text-15 md:text-24 min-w-20 md:min-w-30 m-0 break-all`}
      }
    `
  ]
);
export const title = styled.div(() => [
    css`
      ${tw`flex flex-col text-textBlack items-center md:flex-row m-0 text-15 sm:text-20 md:text-32 text-center md:text-left`};

      svg {
        ${tw`mr-20`}
        height: 60px;
        width: 70px;

        path {
          ${tw`fill-green`}
        }
      }
    `
  ]
);

export const buttonContainer = styled.div(() => [
  css`
    ${tw`flex flex-col sm:flex-row self-end mt-auto justify-between max-w-2xl w-full`};

    button {
      ${tw`text-20 md:text-22`}
    }

    button:last-of-type {
      ${tw`mt-20 sm:m-0`}
    }
  `
]);
