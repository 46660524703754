const xsMax = 639;
const smMin = 640;
const smMax = 767;
const mdMin = 768;
const mdMax = 1023;
const lgMin = 1024;
const lgMax = 1279;
const xl = 1280;

const breakpoints = {
  xsMax,
  smMin,
  smMax,
  mdMin,
  mdMax,
  lgMin,
  lgMax,
  xl
};

export default { breakpoints };
