import React, { useState, useContext, ChangeEvent, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  ProjectProgressPhase,
  ProjectServicePackages
} from '../../../store/projects/projects.types';
import {
  removeSpFromPhase,
  removeMethod,
  renamePhase
} from '../../../store/projects/projects.actions';
import { ProgressContext } from '../Progress';
import * as S from './project-phases';
import PhaseService from './PhaseService';
import PhaseMethod from '../PhaseMethod';
import images from '../../../utils/images.utils';
import useOnClickOutside from 'use-onclickoutside';
import saveIcon from '../../../images/icon-save.svg';
import { isAlphanumeric } from '../../../utils';
import PhaseTask from '../PhaseTask';
import PhaseItemModalWrapper from './PhaseItemModalWrapper';
import SimpleModal from '../../../components/Modal/SimpleModal/SimpleModal';

interface Props {
  onDelete: (id: number) => void;
  phase: ProjectProgressPhase;
  index: number;
  categories: string;
}

interface Params {
  id: string;
}

type DeleteMethodType = {
  methodId: number | null;
  serviceId: number | null;
};

const PhaseItem = ({ phase, index, onDelete, categories }: Props) => {
  const { updateProject, isMemberRole } = useContext(ProgressContext);
  const { id } = useParams<Params>();
  const [activeDelete, setActiveDelete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteSpId, setDeleteSpId] = useState<number | null>(null);
  const [deleteMethodData, setDeleteMethodData] = useState<DeleteMethodType>({
    methodId: null,
    serviceId: null
  });
  const [deleteMethodModal, setDeleteMethodModal] = useState<boolean>(false);
  const [phaseName, setPhaseName] = useState<string>(phase.name);
  const [showInput, setShowInput] = useState<boolean>(false);
  const inputRef = useRef(null);
  const [canSave, setCanSave] = useState<boolean>(false);
  const { id: projectId } = useParams<Params>();
  

  const onDeleteSp = async () => {
    try {
      setIsDeleting(true);
      const data = await removeSpFromPhase(id, phase.id, deleteSpId!);
      setActiveDelete(false);
      updateProject(data);
      setIsDeleting(false);
    } catch {
      setIsDeleting(false);
    }
  };

  const onDeleteMethod = async () => {
    try {
      setIsDeleting(true);
      const data = await removeMethod(
        id,
        phase.id,
        deleteMethodData.methodId!,
        deleteMethodData.serviceId
      );
      setDeleteMethodModal(false);
      updateProject(data);
      setIsDeleting(false);
    } catch {
      setIsDeleting(false);
    }
  };
  
  const showDeleteModal = (id: number) => {
    setActiveDelete(true);
    setDeleteSpId(id);
  };

  const closeDeleteModal = () => {
    setActiveDelete(false);
  };

  const showDeleteMethod = (methodId: number, serviceId: number | null) => {
    setDeleteMethodModal(true);
    setDeleteMethodData({ methodId, serviceId });
  };

  const closeDeleteMethod = () => {
    setDeleteMethodModal(false);
    setDeleteMethodData({ methodId: null, serviceId: null });
  };

  const onPhaseClick = () => {
    if (isMemberRole) return;
    setShowInput(true);
  };

  useOnClickOutside(inputRef, () => {
    setShowInput(false);
  });

  useEffect(() => {
    //@ts-ignore
    if (showInput && inputRef.current) inputRef.current!.select();
    if (!showInput && !phaseName.trim()) setPhaseName(phase.name);
    // eslint-disable-next-line
  }, [showInput]);

  useEffect(() => {
    if (phase.name !== phaseName) {
      setCanSave(true);
    }
    if (!phaseName) setCanSave(false);
    // eslint-disable-next-line
  }, [phaseName]);

  const updatePhaseName = (evt: ChangeEvent<HTMLInputElement>) => {
    const newPhaseName = evt.target.value;
    if (isAlphanumeric(newPhaseName)) setPhaseName(newPhaseName);
  };

  const onEditPhase = () => {
    return renamePhase(projectId, phase.id, phaseName);
  };

  const onSave = async () => {
    if (!canSave) return;
    try {
      const editData = await onEditPhase();
      const dataObject = { ...editData };
      updateProject(dataObject);
      setCanSave(false);
      setShowInput(false);
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <>
      <PhaseItemModalWrapper
        phase={phase}
        categories={categories}
      >
        <S.itemHeader>
          {phase.name && !showInput ? (
            <h4 onClick={onPhaseClick}>
              Phase {index + 1} - {phaseName}
            </h4>
          ) : (
            <S.phaseEdit
              ref={inputRef}
              value={phaseName}
              onChange={updatePhaseName}
            />
          )}
          <S.phaseActions>
            <S.savePhase
              disabled={!canSave}
              src={saveIcon}
              alt="Save Phase"
              onClick={() => onSave()}
            />
            <S.deletePhase
              src={images.delete}
              alt="Remove Phase"
              onClick={() => onDelete(phase.id)}
              disabled={isMemberRole ? 1 : 0}
            />
          </S.phaseActions>
        </S.itemHeader>
        {phase.methodsServicePackages.map((s: ProjectServicePackages) => {
          switch (s.phaseItemType) {
            case 'SERVICE_PACKAGE':
              return (
                <PhaseService
                  key={s.id}
                  data={s}
                  onDelete={showDeleteModal}
                  categories={categories}
                  phaseId={phase.id}
                  showDeleteMethod={showDeleteMethod}
                />);
            case 'TASK':
              return (
                <S.phaseMethodContainer key={s.id}>
                  <PhaseTask
                    key={s.id}
                    data={s}
                    phaseId={phase.id} />
                </S.phaseMethodContainer>
              );
            default: {
              return (
                <S.phaseMethodContainer key={s.id}>
                  <PhaseMethod
                    data={s}
                    onDelete={showDeleteMethod}
                    servicePackageId={null}
                  />
                </S.phaseMethodContainer>
              );
            }
          }
        })}
      </PhaseItemModalWrapper>
      <SimpleModal
        isOpen={activeDelete}
        onDismiss={closeDeleteModal}
        confirm={onDeleteSp}
        disabled={isDeleting}
      >
        <p>Are you sure that you want to delete this service package?</p>
      </SimpleModal>

      <SimpleModal
        isOpen={deleteMethodModal}
        onDismiss={closeDeleteMethod}
        confirm={onDeleteMethod}
        disabled={isDeleting}
      >
        <p>Are you sure that you want to delete this service package?</p>
      </SimpleModal>
    </>
  );
};

export default PhaseItem;
