import React from 'react';
import Button from '../../../components/Button';
import { ProjectClientType } from '../../../store/projects/projects.types';
import icon from '../../../images/download-white.svg';

import * as S from './summary-header';
import { convertImageURI } from '../../../utils/images.utils';

interface Props {
  name: string;
  imageUrl: string | null;
  clientData: ProjectClientType | null;
}

const SummaryHeader = ({ name, imageUrl, clientData }: Props) => {
  const printPreview = () => window.print();

  return (
    <S.wrapper>
      {imageUrl && <img src={convertImageURI(imageUrl)} alt={name} />}
      <S.content>
        <div>
          <h2>Project Summary</h2>
          <Button secondary onClick={printPreview}>
            <img src={icon} alt="" />
            Print Summary
          </Button>
        </div>
        {clientData && (
          <p>
            {clientData.firstName} {clientData.lastName}, this is your <br />{' '}
            personalized project example.
          </p>
        )}
      </S.content>
    </S.wrapper>
  );
};

export default SummaryHeader;
