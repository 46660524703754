import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';
import arrow from '../../images/arrow-down.svg';

const InputContent = styled.div<Props>(({ arrow, disabled, withInputs }) => [
  css`
    ${tw`flex flex-col relative w-full`};
    ${!withInputs && tw`mb-30`}

    input {
      ${tw`outline-none bg-gray-800 font-semibold  text-2xl md:text-4xl leading-32 text-gray-200 pl-10 border-2 border-gray-200`}
      transition: all 0.3s;
      min-height: 64px;
      padding: 1.6rem 1.6rem;
      ${arrow && tw`cursor-pointer`}
      &:hover {
        ${tw`bg-gray-600`}
      }
    }

    input:focus {
      ${tw`border-green bg-gray-800`}
    }
  `,
  disabled &&
    css`
      ${tw`bg-gray-600 border-none`}
      pointer-events: none;
      input {
        ${tw`border-gray-200 border-2 border-opacity-75 text-gray-200 text-opacity-75`}
      }
  
      label {
        ${tw`text-gray-200 text-opacity-75`}
      }
    `
]);

const InputDropdown = styled.div<Props>(({ show }) => [
  css`
    ${tw`absolute w-full max-w-xl z-10 py-15 border-2 border-green bg-gray-800 overflow-y-scroll`}
    ${show ? tw`block` : tw`hidden`};
    top: 7.2rem;
    left: 0;
    max-height: 40rem;
  `
]);

const NoOptions = styled.div(() => [
  css`
    ${tw`p-10 font-semibold text-22 text-gray-200 pointer-events-none`}
  `
]);
const Options = styled.div<Props>(({ withInputs }) => [
  css`
    ${tw`cursor-pointer p-10 font-semibold text-18 text-gray-200`}
    ${withInputs && tw`flex justify-between items-center`}

    > div {
        ${tw`border-gray-200 text-gray-200 bg-gray-600 opacity-75`};
      }
      
    &:hover {
      ${tw`text-green`}
    }
  `
]);

const Label = styled.label<Props>(({ hasValue, labelColor }) => [
  css`
    ${tw`absolute top-0 text-gray-400`}
    left: 1.6rem;
    transform: translateY(1.6rem);
    transition: all 0.3s;
    font-size: 2.2rem;
    line-height: 3.2rem;
    font-weight: 600;
    pointer-events: none;
  `,
  hasValue &&
    css`
      transform: translateY(0.6rem);
      font-size: 1.4rem;
      line-height: 1;
    `
]);

const Container = styled.div<Props>(({ active }) => [
  css`
    ${tw`absolute w-full h-full left-0 top-0 cursor-pointer flex justify-end items-center`};
    pointer-events: none;
    > span {
      ${tw`block bg-center bg-no-repeat bg-contain cursor-pointer `}
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 2px;
      background-image: url(${arrow});
      background-size: 90% auto;
      transition: all 0.3s;
      margin-right: 1.6rem;
      transform: ${active && 'rotate(180deg)'};
    }
  `
]);

const PersonDays = styled.div(() => [
  css`
    ${tw`relative flex items-center border-2 border-solid border-darkBlue`};
    min-width: 12.8rem;
    max-width: 12.8rem;

    div {
      ${tw`w-full pl-2 border-0 outline-none bg-transparent text-22 text-right font-semibold`}
      padding-right: 4.5rem;
    }

    span {
      ${tw`absolute text-22`}
      right: 1.5rem;
    }
  `
]);

export {
  InputContent,
  InputDropdown,
  NoOptions,
  Options,
  Label,
  Container,
  PersonDays
};
