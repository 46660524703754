import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { SetStateType } from '../../../types/util.types';
import { ExternalCostType } from '../../../store/projects/projects.types';
import { removeExternalCost } from '../../../store/projects/projects.actions';
import CostItem from './CostItem';
import AddCost from './AddCost';
import Modal from '../../../components/Modal';
import SimpleModal from '../../../components/Modal/SimpleModal/SimpleModal';

import * as S from './external-cost';

interface Props {
  costs: ExternalCostType[];
  setCosts: SetStateType<ExternalCostType[]>;
}

interface Params {
  id: string;
}

const ExternalCostsList = ({ costs, setCosts }: Props) => {
  const { id } = useParams<Params>();

  const [editItem, setEditItem] = useState<ExternalCostType | null>(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  
  const [deleteItem, setDeleteItem] = useState<number | null>(null);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onEdit = (cost: ExternalCostType) => {
    setEditItem(cost);
    setOpenEdit(true);
  };

  const deleteCost = async () => {
    try {
      setIsDeleting(true);
      await removeExternalCost(id, deleteItem!);
      setCosts(costs.filter((c: ExternalCostType) => c.id !== deleteItem));
      closeDeleteModal();
      setIsDeleting(false);
    } catch {
      setIsDeleting(false);
    }
  };

  const showDeleteModal = (id: number) => {
    setOpenDelete(true);
    setDeleteItem(id);
  };

  const closeDeleteModal = () => {
    setOpenDelete(false);
    setDeleteItem(null);
  };

  const onSuccess = (data: ExternalCostType | ExternalCostType[]) => {
    setCosts(Array.isArray(data) ? data : [...costs, data]);
    setOpenEdit(false);
  };

  return (
    <>
      {costs.length > 0 && (
        <S.listWrapper>
          {costs.map((c: ExternalCostType) => (
            <CostItem
              onDelete={showDeleteModal}
              cost={c}
              key={c.id}
              onEdit={onEdit}
            />
          ))}
        </S.listWrapper>
      )}
      <Modal isOpen={openEdit} onDismiss={() => { setOpenEdit(false) }}>
        <AddCost onSuccess={onSuccess} cost={editItem} />
      </Modal>
      <SimpleModal
        isOpen={openDelete}
        onDismiss={closeDeleteModal}
        confirm={deleteCost}
        disabled={isDeleting}
      >
        <p>Are you sure that you want to delete this external cost?</p>
      </SimpleModal>
    </>
  )
}

export default ExternalCostsList