import React from 'react';
import globalHook from 'use-global-hook';
import { AuthActions, AuthState } from './auth/auth.types';
import { ServicesState, ServicesActions } from './method-service/types';
import authInitialState from './auth/auth.state';
import servicesInitialState from './method-service/state';
import * as authActions from './auth/auth.actions';
import { getDisciplinesRolesPhases } from './method-service/actions';

import { updateProfile, updateAvatar } from './members/members.actions';
import { MembersActions } from './members/members.types';

export interface AppState {
  auth: AuthState;
  services: ServicesState;
  [key: string]: any;
}

interface Actions extends AuthActions, MembersActions, ServicesActions {}

const initialState: AppState = {
  auth: authInitialState,
  services: servicesInitialState
};

const useGlobal = globalHook<AppState, Actions>(React, initialState, {
  ...authActions,
  getDisciplinesRolesPhases,
  updateProfile,
  updateAvatar
});

export default useGlobal;
