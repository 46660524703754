import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';
import { ErrorText } from '../Inputs/StyledTextInput';

export const Form = styled.form<Props>(({ isModal }) => [
  css`
    ${tw`flex flex-col px-30 py-40  text-textBlack w-full`};

    h3 {
      ${tw`m-0 mb-30 `}
    }

    button {
      ${tw`self-end`}
    }
  `,
  isModal &&
    css`
      ${tw`bg-white border-2 border-solid border-gray-200`}
      max-width: 730px;

      > div:nth-child(2) {
        display: none;
      }

      > div:nth-child(4) {
        ${tw`pb-0 items-start`}
      }
      
      ${ErrorText} {
        ${tw`h-56`}
      }
    `
]);

export const FormInputs = styled.div<Props>(({ line }) => [
  css`
    ${tw`relative py-20 relative w-full flex flex-wrap lg:flex-no-wrap justify-between items-baseline`}

    &:last-of-type {
      ${tw`items-center`}
    }
  `,
  line &&
    css`
      ${tw`border-b-2 border-solid`}
      border-color: #e7ebec;
    `
]);

export const InputTitle = styled.div<Props>(({ alignTop }) => [
  css`
    ${tw`w-full m-0 max-w-full lg:max-w-sm text-15 lg:text-24 leading-32 font-semibold pr-5`};
  `,
  alignTop && tw`self-start`
]);

export const InputWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col w-full lg:max-w-2xl ml-auto flex-no-wrap items-start `}
  `
]);
