import React, { useEffect, useState } from 'react';
import { MemberRole } from '../../../store/method-service/types';
import CbRadio from '../../../components/Inputs/CbRadio';
import PdField from '../../../components/PdField';
import * as S from './member-field';
import { SetStateType } from '../../../types/util.types';

interface Props {
  role: MemberRole;
  setSelected: SetStateType<MemberRole[]>;
  selected: MemberRole[];
  disabled?: boolean;
}

const MemberRow = ({
  role: { name, memberRoleId, personDays: pd },
  setSelected,
  selected,
  ...rest
}: Props) => {
  const isSelected: MemberRole | undefined = selected.find(
    (r: MemberRole) => r.memberRoleId === memberRoleId
  );
  const [personDays, setPersonDays] = useState<string | number>(
    isSelected ? isSelected.personDays : pd
  );

  const handleCbChange = (evt: any) => {
    const checked = evt.target.checked;
    let updatedSelection: MemberRole[];

    !checked
      ? (updatedSelection = selected.filter(
          (r: MemberRole) => r.memberRoleId !== memberRoleId
        ))
      : (updatedSelection = [...selected, { memberRoleId, personDays, name }]);

    setSelected(updatedSelection);
  };

  useEffect(() => {
    if (isSelected) {
      const updatedSelection = selected.map((r: MemberRole) => {
        if (r.memberRoleId !== memberRoleId) return r;
        return { ...r, personDays };
      });
      setSelected(updatedSelection);
    }
    // eslint-disable-next-line
  }, [personDays]);

  return (
    <S.roles>
      <CbRadio
        name={name}
        small
        onChange={handleCbChange}
        checked={!!isSelected}
        {...rest}
      >
        {name}
      </CbRadio>
      <PdField
        value={personDays}
        setValue={setPersonDays}
        initValue={pd}
        active={!!isSelected}
        {...rest}
      />
    </S.roles>
  );
};

export default MemberRow;
