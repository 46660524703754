import React from 'react';

import { ComplexitySP } from '../../../store/method-service/types';
import TextInput from '../../../components/Inputs/TextInput';
import * as S from './complexity-field';

interface Props {
  complexity: ComplexitySP;
  title: string;
  disableAll?: boolean;
}

const ComplexityField = ({
  complexity: { personDays },
  title,
  disableAll
}: Props) => {
  return (
    <S.wrapper>
      <h4>{title}</h4>

      <TextInput
        name={`${personDays}-pd`}
        label="Persondays"
        value={personDays > 0 ? personDays : ''}
        disabled={disableAll}
      />
    </S.wrapper>
  );
};

export default ComplexityField;
