import React, { useEffect, useState, useRef } from 'react';
import { getMembers } from '../../store/members/members.actions';
import { Member } from '../../store/members/members.types';
import {
  HeaderWrapper,
  HeaderTop,
  HeaderBottom
} from '../../components/Header/StyledHeader';
import HeaderFilter, { Filters } from '../../components/Header/HeaderFilter';
import Button from '../../components/Button';
import ViewType from '../../components/ViewType';
import TopBar from '../../components/TopBar';
import Pager from '../../components/Pager';
import CardImageLoader from '../../components/CardImageLoader';
import * as S from './members-page';
import MembersItem from './MembersItem';
import owner from '../../images/project-owner-outline.svg';
import user from '../../images/user-filled.svg';
import { routePaths } from '../../utils/routes.util';
import {
  NUMBER_OF_ITEMS_FOR_PAGINATION,
  PLACEHOLDER_ARRAY
} from '../../utils/general.utils';
import NoResult from '../../components/NoResult';

const filters: Filters[] = [
  {
    label: 'All Members',
    icon: user,
    value: ''
  },
  {
    label: 'Project Owner',
    icon: owner,
    value: 'PROJECT_OWNER'
  }
];

const Members = () => {
  const first = useRef(true);
  const [listView, setListView] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [members, setMembers] = useState<Member[]>([]);
  const [name, setName] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchUsers = async () => {
    try {
      const { totalPageNumber, content } = await getMembers({
        page,
        size: NUMBER_OF_ITEMS_FOR_PAGINATION,
        filter,
        name
      });
      setTotalPageNumber(totalPageNumber);
      setMembers(content);
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (first.current) return;
    page === 0 ? fetchUsers() : setPage(0);
    // eslint-disable-next-line
  }, [filter, name]);

  useEffect(() => {
    first.current = false;
    fetchUsers();
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <TopBar setSearch={setName} placeholder="Search by name" />
      <HeaderWrapper>
        <HeaderTop>
          <h2>Members</h2>
          <Button small to={routePaths.MEMBERS_CREATE}>
            Add new member
          </Button>
        </HeaderTop>
        <HeaderBottom>
          <HeaderFilter
            selected={filter}
            setSelected={setFilter}
            list={filters}
          />
          <ViewType setList={setListView} isListView={listView} />
        </HeaderBottom>
      </HeaderWrapper>
      <S.list listView={listView}>
        {isLoading
          ? PLACEHOLDER_ARRAY.map((p: any, i: number) => (
              <CardImageLoader key={i} />
            ))
          : members.map((m: Member) => (
              <MembersItem
                key={m.id}
                {...m}
                listView={listView}
                onUpdate={fetchUsers}
              />
            ))}
      </S.list>
      {!isLoading && members.length === 0 && !!name && <NoResult searchInput={name} />}
      {totalPageNumber > 1 && (
        <Pager page={page} setPage={setPage} pageCount={totalPageNumber} />
      )}
    </>
  );
};

export default Members;
