import React from 'react';
import Dropdown from '../../../components/Dropdown';
import { DropdownCbOption, SetStateType } from '../../../types/util.types';
import * as S from './phase-field';
import useGlobal from '../../../store';

interface Props {
  selected: DropdownCbOption[];
  onAdd: SetStateType<DropdownCbOption[]>;
  disabled?: boolean;
}

const PhaseField = ({ selected, onAdd, disabled }: Props) => {
  const [phaseStore] = useGlobal((state) => state.services.phases);

  return (
    <S.wrapper>
      <Dropdown
        labelColor="#0A0A33"
        label="Phase"
        options={phaseStore}
        values={selected}
        setValues={onAdd}
        disabled={disabled}
      />
    </S.wrapper>
  );
};

export default PhaseField;
