import tw, { styled, css } from 'twin.macro';
// import { StyledProps as Props } from '../../../types/util.types';

export const content = styled.div(() => [
  css`
    ${tw`flex flex-col w-full items-center xl:items-start text-textBlack py-20 px-10 xl:py-0 xl:px-0 mr-auto`};
    max-width: 980px;

    svg {
      ${tw`self-center w-full pt-10 md:pt-40`}
      max-width:682px;
    }
  `
]);

export const formTitle = styled.div(() => [
  css`
    ${tw`flex flex-col max-w-54 items-center xl:items-start`}

    a {
      ${tw`mt-30 self-center xl:self-end`}
    }
  `
]);

export const title = styled.h1(() => [
  css`
    ${tw`flex mb-20 text-40 md:text-56 text-center lg:text-left`};
  `
]);

export const subtitle = styled.div(({ theme: { typography: { lead } } }) => [
  css`
    ${lead}
    ${tw`m-0 text-20 xl:text-32 whitespace-pre-line text-center xl:text-left leading-35 xl:leading-44`};

    span {
      ${tw`font-bold`}
    }
  `
]);

export const hint = styled.div(() => [
  css`
    ${tw`flex text-textBlack leading-22  text-18 pt-20 tracking-wider font-semibold text-center lg:text-left`}
  `
]);
