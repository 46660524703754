import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    ${tw`mt-40 mx-25 px-15 pb-40 pt-30 bg-white text-textBlack`};
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  `
]);

export const section = styled.div(() => [
  css`
    ${tw`flex flex-wrap items-start xl:px-30 xxl:px-80`};
  `
]);

export const title = styled.div(() => [
  css`
    ${tw`w-full mb-25 text-textBlack text-32 leading-36 font-semibold`};
  `
]);

export const actions = styled.div(() => [
  css`
    ${tw`flex justify-end xl:pr-20`};
  `
]);
