import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  editProject,
  getProjectStudios,
  getProjectData
} from '../../../store/projects/projects.actions';
import { searchAccounts } from '../../../store/members/members.actions';
import { ProjectDataEdit } from '../../../store/projects/projects.types';
import { validatePrice } from '../../../utils/price.utils';
import { DropdownCbOption } from '../../../types/util.types';
import { NewSearchInput } from '../../../components/Inputs';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';
import { TextInput } from '../../../components/Inputs';

import * as S from './edit-form';

interface Params {
  id: string;
}

const EditForm = () => {
  const { id } = useParams<Params>();
  const [data, setData] = useState<ProjectDataEdit | null>(null);
  const [name, setName] = useState<string>('');
  const [projectOwner, setProjectOwner] = useState<any>();
  const [studios, setStudios] = useState<DropdownCbOption[]>([]);
  const [studioOptions, setStudioOptions] = useState<DropdownCbOption[]>();
  const [dailyRate, setDailyRate] = useState<string>('');
  const [priceType] = useState<string>('Standard');
  const [currency, setCurrency] = useState<string>('');

  const [poValue, setPoValue] = useState<string>('');

  const searchOwner = async () => {
    try {
      const data = await searchAccounts(
        poValue,
        'ROLE_SUPER_ADMIN,ROLE_ADMIN'
      );
      return data;
    } catch (error) {
      console.warn(error);
    }
  };

  const changeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    if (name === 'name') setName(value);
    if (name === 'dailyRate' && ( value === '' || validatePrice(value))) setDailyRate(value);
  };

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    try {
      const res = await editProject(
        id,
        name,
        priceType,
        dailyRate,
        projectOwner,
        studios,
        currency
      );
      setData(res);
    } catch (error) {
      console.warn(error);
    }
  };

  const setDefaultData = () => {
    setName(data!.name);
    setProjectOwner(data!.projectOwner);
    setPoValue(
      `${data!.projectOwner.firstName} ${data!.projectOwner.lastName}`
    );
    setStudios(data!.studios);
    setDailyRate(data!.dailyRate);
    setCurrency(data!.currency);
  };

  useEffect(() => {
    if (!data) return;
    // eslint-disable-next-line
    setDefaultData();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    (async () => {
      try {
        const data = await Promise.all([
          getProjectData(id),
          getProjectStudios()
        ]);
        setData(data[0]);
        setStudioOptions(data[1]);
      } catch (error) {
        console.warn(error);
      }
      // eslint-disable-next-line
    })();
    // eslint-disable-next-line
  }, []);

  const isSameStudio = studios.reduce(
    (acc: boolean, el: DropdownCbOption) => {
      if (!data?.studios.find((item: DropdownCbOption) => el.id === item.id))
        return false;
      return acc;
    },
    studios.length ? true : false
  );

  const isDisabled =
    (data &&
      data?.name === name &&
      data?.dailyRate === dailyRate &&
      data?.projectOwner.id === projectOwner?.id &&
      isSameStudio) ||
    !data;

  const isDisabledSave =
    isDisabled ||
    !name ||
    !projectOwner ||
    !studios.length ||
    !dailyRate ||
    +dailyRate === 0; 

  return (
    <S.form onSubmit={onSubmit}>
      <S.formBody>
        <S.inputWrapper>
          <h2>{data?.name}</h2>
          <S.inputFields>
            <TextInput
              label="Project name"
              name="name"
              value={name}
              onChange={changeHandler}
            />
            <Dropdown
              checkbox
              labelColor="#0A0A33"
              label="Studio"
              values={studios}
              options={studioOptions}
              setValues={setStudios}
            />
            <NewSearchInput
              setValue={(value: string) => {
                setProjectOwner(null);
                setPoValue(value);
              }}
              selectValue={(item: any) => {
                setPoValue(`${item.firstName} ${item.lastName}`);
                setProjectOwner(item);
              }}
              value={poValue}
              label="Project owner"
              labelProps={['firstName', 'lastName']}
              onSearch={searchOwner}
            />
          </S.inputFields>
        </S.inputWrapper>
        <S.priceWrapper>
          <h3>Daily rate of the project</h3>
          <S.priceFields>
            <TextInput
              name="priceType"
              value={priceType}
              disabled
              label="Daily rate"
            />
            <div>
              <TextInput
                name="dailyRate"
                value={dailyRate}
                label="Daily rate price"
                onChange={changeHandler}
              />
              <TextInput
                name='currency'
                label="Currency"
                value={currency}
                disabled
              />
            </div>
          </S.priceFields>
        </S.priceWrapper>
      </S.formBody>
      <S.footer>
        <S.footerActions>
          <Button disabled={isDisabled} secondary onClick={setDefaultData}>
            Cancel Changes
          </Button>
          <Button
            type="submit"
            disabled={isDisabledSave}
          >
            Save Changes
          </Button>
        </S.footerActions>
      </S.footer>
    </S.form>
  );
};

export default EditForm;
