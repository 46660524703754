import React from 'react';
import { ReactSVG } from 'react-svg';
import { ProjectSummaryCalculationItem } from '../../../store/projects/projects.types';
import { formatPrice } from '../../../utils/price.utils';

import * as S from './summary-calculation';

interface Props {
  data: ProjectSummaryCalculationItem;
  type: string;
  unit: string;
  icon: string;
}

const SummaryCalculationItem = ({ data, type, unit, icon }: Props) => {

  const renderRoles = Object.keys(data.taskRoles)?.map((key) => (
    <div key={key}>
      <p>{key}</p>
      <p>{formatPrice(data.taskRoles[key])} {unit}</p>
    </div>
  ));

  return (
    <>
      {data.methodsCalculation > 0 && (
        <S.item>
          <ReactSVG src={icon} />
          <S.data>
            <span>
              {formatPrice(data.methodsCalculation)} {unit}
            </span>
            <span>within Service Package / Methods</span>
          </S.data>
        </S.item>
      )}
      {data.tasksCalculation > 0 && (
        <S.item>
          <ReactSVG src={icon} />
          <S.data>
            <span>
              {formatPrice(data.tasksCalculation)} {unit}
            </span>
            <span>within Custom Tasks</span>
            <S.roles>{renderRoles}</S.roles>
          </S.data>
        </S.item>
      )}
      <S.item hide={!data.externalCostCalculation || type === "Effort"}>
        <ReactSVG src={icon} />
        <S.data>
          <span>
            {data.externalCostCalculation ? formatPrice(data.externalCostCalculation) : ""} {unit}
          </span>
          <span>External Costs without VAT</span>
        </S.data>
      </S.item>
      <S.item>
        <ReactSVG src={icon} />
        <S.data>
          <span>
            {formatPrice(data.totalCalculation)} {unit}
          </span>
          <span>Total {type}</span>
        </S.data>
      </S.item>
    </>
  );
};

export default SummaryCalculationItem;
