import tw, { styled, css } from 'twin.macro';
import { Dropdown } from '../../../components/Actions/actions-component';
import { StyledProps } from '../../../types/util.types';

interface Props {
  active?: boolean;
  editable?: boolean;
  open?: boolean;
}

interface RoleBasedControlProps {
  disabled?: number;
}

export const wrapper = styled.div<RoleBasedControlProps>(({ disabled }) => [
  css`
    ${tw`relative flex items-start flex-wrap flex-col md:flex-row px-16 py-12 `};
  `,
  disabled === 1 &&
    css`
      ${tw`pointer-events-none`}

      > div:last-child {
        > div:not(:last-child) {
          ${tw`border-gray-200 border-opacity-75 text-gray-200 text-opacity-75 bg-gray-600`}

          svg {
            ${tw`opacity-75`}

            path:last-of-type {
              ${tw`fill-gray-200`}
            }
          }

          input {
            ${tw`bg-gray-600 text-gray-200 text-opacity-75`}
          }
        }
      > div:last-child {
          ${tw`opacity-50`}
        }
      }
    `
]);

export const title = styled.div(() => [
  css`
    ${tw`relative flex pt-10 flex-wrap items-center mb-15 md:mb-0 mr-auto md:max-w-440 md:min-w-440 text-textBlack font-semibold text-24 leading-32`};
  `
]);

export const methodEdit = styled.input(() => [
  css`
    ${tw`relative flex pt-10 flex-wrap items-center mb-15 md:mb-0 mr-auto 
    md:max-w-440 md:min-w-440 text-textBlack font-semibold text-24 leading-32 
    border-2 border-darkBlue outline-none pl-10 py-5 pr-10`}
  `
]);

export const titleLabel = styled.span(() => [
  css`
    ${tw`pr-50`}
    flex: 1;
  `
]);

export const fields = styled.div(() => [
  css`
    ${tw`flex flex-wrap flex-col md:flex-row items-center w-full md:w-auto ml-auto`};
  `
]);

export const actions = styled.div(() => [
  css`
    ${tw`flex items-center self-end md:self-center ml-auto`};

    > * {
      ${tw`mr-16 last:mr-0`}
    }

    > img {
      ${tw`cursor-pointer`}
    }
  `
]);

export const dateWrapper = styled.div(() => [
  css`
    ${tw`relative`};
    min-width: 2.6rem;
  `
]);

interface DateIconProps {
  disabled?: boolean;
}

export const dateIcon = styled.img<DateIconProps>(({ disabled }) => [
  css`
    ${tw`cursor-pointer`}
  `,
  disabled &&
    css`
      ${tw`opacity-50 cursor-default`}
    `
]);

export const dateDropdown = styled.div(() => [
  css`
    ${tw`absolute z-50 right--130 md:right-100 py-16 px-24 bg-gray-800 rounded-8 shadow-1 transform -translate-x-7`};
    top: 4rem;
  `
]);

export const dateHeader = styled.div(() => [
  css`
    ${tw`flex items-start justify-between items-center flex-col md:flex-row px-8`};

    > span {
      ${tw`text-32 font-semibold leading-36 text-textBlack`}
    }

    > div {
      ${tw`grid grid-cols-1 md:grid-cols-2 gap-16 mt-20 md:mt-0 w-full md:w-auto`}
    }
  `
]);

export const dateBox = styled.div(() => [
  css`
    ${tw`flex flex-col relative pt-8 border-2 border-darkBlue bg-gray-800 px-16 text-22 font-semibold text-textBlack leading-26 w-full md:w-195`};
    height: 6.4rem;

    span {
      ${tw`text-gray-400 text-14 leading-16`}
    }

    img {
      ${tw`absolute cursor-pointer`}
      width: 2.4rem;
      right: 1.6rem;
      top: 50%;
      transform: translateY(-50%);
    }
  `
]);

export const dateBody = styled.div(() => [
  css`
    ${tw`my-16 px-16 pt-8 pb-16 border-t border-b border-gray9`}

    .DayPicker {
      ${tw`w-full`}

      .DayPicker-wrapper {
        ${tw`outline-none p-0`}

        svg path {
          ${tw`fill-black`}
        }
      }

      .DayPicker-Months {
        flex-wrap: nowrap;
        ${tw`flex-col md:flex-row`}
      }

      .DayPicker-Month {
        ${tw`w-full m-0 md:last:ml-75 block`}
      }

      .DayPicker-Caption {
        ${tw`mb-8 text-24 leading-32 font-semibold text-black text-center`}
      }

      .DayPicker-Weekdays {
        ${tw`mb-12`}
      }

      .DayPicker-Weekday {
        ${tw`p-0 m-0 text-18 leading-48 text-black font-semibold`}
      }

      .DayPicker-Week {
        ${tw`mb-4 last:mb-0`}
      }

      .DayPicker-Day {
        ${tw`py-8 px-0 outline-none text-gray-200 font-semibold text-18 leading-32 cursor-pointer`}
        min-width: 4.8rem;
        max-width: 4.8rem;

        &.DayPicker-Day--outside {
          ${tw`opacity-0!`}
        }

        &.DayPicker-Day--selected {
          ${tw`text-black bg-gray-600 rounded-none`}

          &:nth-child(1), &:nth-child(7) {
            ${tw`bg-gray-600 bg-opacity-50`}
          }

          &.DayPicker-Day--disabled {
            ${tw`cursor-default text-gray-400`}
          }
        }

        &.DayPicker-Day--end,
        &.DayPicker-Day--start {
          ${tw`relative rounded-50 text-gray-800 bg-green`}
          &:after {
            ${tw`absolute bg-gray-600`}
            content: '';
            top: 0;
            left: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
          }
        }

        &.DayPicker-Day--start {
          &:after {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
          }
        }

        &.DayPicker-Day--end {
          &:after {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
          }
        }

        &.DayPicker-Day--disabled {
          ${tw`cursor-default text-gray-400`}
        }

        &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          ${tw`bg-gray-600`}
        }
      }
    }
  `
]);

export const dateFooter = styled.div(() => [
  css`
    ${tw`flex justify-end px-8`};

    > * {
      ${tw`mr-16 last:mr-0`}
    }
  `
]);

export const membersWrapper = styled.div<Props>(({ open }) => [
  css`
    ${tw`relative flex items-center justify-between w-full md:w-200 mb-15 md:mb-0 px-16  border-2 border-darkBlue text-22 text-textBlack font-semibold leading-none hover:bg-gray-600`};
    transition: background-color 0.3s;
    height: 4.8rem;

    ${Dropdown} {
      width: 32rem;
      left: -2px;
      right: auto;
    }
  `,
  open && 
  css`
    ${tw`border-green hover:bg-gray-800`}
  `
]);

export const memberItem = styled.div(() => [
  css`
    ${tw`flex items-center justify-between w-full px-15 py-10 text-textBlack text-left font-semibold text-18`};

    span {
      opacity: 0.5;
    }
  `
]);

export const complexity = styled.div<Props>(({ active }) => [
  css`
    ${tw`relative flex items-center justify-between w-full md:w-245 mb-15 md:mb-0 px-16  md:ml-30 border-2 border-darkBlue text-22 text-textBlack font-semibold leading-none hover:bg-gray-600`};
    transition: background 0.3s;
    height: 4.8rem;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    ${Dropdown} {
      ${tw`py-15 gap-15`}
      width: 24.5rem;
    }
  `,
  active &&
  css`
    ${tw`border-green hover:bg-gray-800`}
  `
]);

export const complexityItem = styled.div(() => [
  css`
    ${tw`flex items-center justify-between w-full px-15 text-textBlack text-left font-semibold hover:text-green cursor-pointer text-18`};

    &:hover {
      svg {
        path {
          ${tw`fill-green`}
        }
      }
    }
  `
]);

export const pd = styled.div<Props>(({ editable }) => [
  css`
    ${tw`relative flex items-center justify-end w-full md:max-w-160 md:min-w-160 mb-15 md:mb-0 md:ml-30 md:mr-30 border-2 border-darkBlue text-22 text-textBlack font-semibold leading-none`};
    height: 4.8rem;

    > span {
      ${tw`absolute`}
      right: 2.2rem;
    }

    input {
      ${tw`relative flex items-center pr-50 pl-45 w-full h-full outline-none text-22 text-textBlack font-semibold leading-none text-right`}
    }

    &:hover input {
      ${tw`bg-gray-600`}
      transition: background 0.3s;
    }

    &:focus-within {
      ${tw`border-green`}
    }

    &:focus-within input {
      ${tw`bg-gray-800`}
    }
  `,
  editable &&
  css`
    ${tw`border-gray-200 border-opacity-75 text-gray-200 text-opacity-75 bg-gray-600`}

  input {
    ${tw`text-gray-200 text-opacity-75`}
  }
  `
]);

export const commentWrapper = styled.div(() => [
  css`
    ${tw`relative`};
    min-width: 3.2rem;

    > img {
      ${tw`cursor-pointer`}
    }
  `
]);

export const commentBox = styled.div(() => [
  css`
    ${tw`absolute w-255 z-30 bg-gray10 right-100 py-12 pl-12 pr-9 rounded-4 border border-darkBlue border-2 transform -translate-x-7`};
    top: -2.2rem;
    min-height: 10.6rem;

    img {
      ${tw`absolute z-10 cursor-pointer first:cursor-default`};
      left: 1.2rem;
      top: 1.2rem;
    }

    img:nth-of-type(2) {
      ${tw`absolute -mt-9 -mr-6`};
      top: 1.2rem;
      right: 1.2rem;
      left: auto;
    }

    img:nth-of-type(3) {
      ${tw`absolute  -mt-9 -mr-6`};
      right: 1.2rem;
      bottom: 1.2rem;
      left: auto;
      top: auto;
    }
  `
]);

export const iconCommentWrap = styled.div(({ active }: Props) => [
  css`
    ${tw`cursor-pointer`}
  `,
  active &&
    css`
      ${tw`bg-gray6`}
    `
]);

export const commentContent = styled.div(() => [
  css`
    ${tw`h-84 pr-32 pl-36`};
  `
]);

export const commentLog = styled.p(() => [
  css`
    ${tw`pl-35 pr-25 text-18 leading-28 font-semibold text-textBlack`};

    span {
      ${tw`text-green`}
    }
  `
]);

export const log = styled.div(() => [
  css`
    ${tw`absolute z-20 left-15`};

    svg {
      ${tw`cursor-pointer`}
    }

    ${commentBox} {
      ${tw` left-48 md:left-auto right-auto md:right-100`}
    }
  `
]);

export const methodColors = styled.div<StyledProps>(({ inputActive }) => [
  css`
    ${tw`flex absolute`}
    top: 50%;
    transform: translateY(-50%);
    left: -4.8rem;
  `,
  inputActive && css`left: -3.2rem;`
]);

interface MethodColorProp {
  color: string;
}

export const methodColor = styled.div<MethodColorProp>(({ color }) => [
  css`
    ${tw`h-72`}
    width: 8px;

    background-color: ${color};
  `
]);

interface SaveProps {
  disabled: boolean;
}

export const saveImage = styled.img<SaveProps>(({ disabled }) => [
  disabled &&
    css`
      ${tw`opacity-50 cursor-default!`}
    `
]);
