import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const wrapper = styled.div<Props>(({ isListView }) => [
  css`
    ${tw`flex`};

    > div:first-child path {
      transition: all 0.3s;
      cursor: pointer;
      ${isListView ? css`${tw`fill-gray-400`}` : css`${tw`fill-darkBlue`}`}
    }

    > div:last-child path {
      transition: all 0.3s;
      cursor: pointer;
      ${isListView ? css`${tw`fill-darkBlue`}` : css`${tw`fill-gray-400`}`}
    }
  `
]);
