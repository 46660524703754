import { useEffect, useCallback, useRef } from 'react';

export const useInterval = (
  callback: () => void,
  timeout: number = 0,
): () => void => {
    const timeoutIdRef = useRef<any>();
    const cancel = useCallback(
        () => {
            const timeoutId = timeoutIdRef.current;
            if (timeoutId) {
                timeoutIdRef.current = undefined;
                clearTimeout(timeoutId);
            }
        },
        [timeoutIdRef],
    );

    useEffect(
        () => {
            timeoutIdRef.current = setTimeout(callback, timeout);
            return cancel;
        },
        [callback, timeout, cancel],
    );

    return cancel;
}