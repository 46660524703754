import React, { useState } from 'react';
import 'twin.macro';
import Actions from '../../../components/Actions';
import { AllServices } from '../../../store/method-service/types';
import {
  duplicateMethod,
  duplicateServicePackage
} from '../../../store/method-service/actions';
import { toastUtil } from '../../../utils/toast.util';
import * as S from './list-item';
// import { DropdownCbOption, SetStateType } from '../../../types/util.types';
import Details from '../Details';
import { ReactSVG } from 'react-svg';
import arrow from '../../../images/arrow-down.svg';
import DisciplineColors from './DisciplineColors';
import { capitalizeString } from '../../../utils/index';

interface Props extends AllServices {
  onChangeCallback: () => Promise<any>;
  fetchAllServices: () => Promise<void>;

  // services: DropdownCbOption[];
  // setServices: SetStateType<DropdownCbOption[]>;
}

const ListItem = ({
  id,
  name,
  level,
  qualities,
  complexities,
  disciplines,
  serviceType,
  fetchAllServices,
  ...rest
}: Props) => {
  const pickedQuality =
    qualities?.find((q: any) => q.picked) ||
    complexities?.find((q: any) => q.picked);
  const checkType = serviceType === 'METHOD' ? false : true;

  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const toggleDetails = () => {
    setDetailsOpen(!detailsOpen);
    setIsEdit(false);
  };
  const toggleEdit = () => {
    setDetailsOpen(true);
    setIsEdit(true);
    close();
  };
  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => setOpen(!open);
  const close = () => {
    if (open) setOpen(false);
  };

  const action = checkType ? duplicateServicePackage : duplicateMethod;

  const duplicate = async () => {
    try {
      const res = await action(id);
      fetchAllServices();
      close();
      toastUtil('success', res.data.meta.message);
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <S.wrapper>
      <S.listContent>
        <S.disciplineAndTitle>
          <DisciplineColors disciplines={disciplines} />

          <S.title>
            {name}
            <span>.{level.toLowerCase()}</span>
          </S.title>
        </S.disciplineAndTitle>

        <div>{capitalizeString(pickedQuality?.type.toLowerCase())}</div>
        <div>{pickedQuality?.personDays} pd</div>
        <div tw="flex justify-center pl-0!">
          <S.methodSp sp={checkType}>{checkType ? 'SP' : 'M'}</S.methodSp>
        </div>
        <S.footer>
          <S.svg detailsOpen={detailsOpen}>
            <ReactSVG src={arrow} onClick={toggleDetails} />
          </S.svg>

          <Actions toggle={toggle} open={open} close={close} listView={true}>
            <S.action onClick={duplicate}>
              Duplicate {checkType ? 'service package' : 'method'}
            </S.action>
            <S.action onClick={toggleEdit}>
              Edit {checkType ? 'service package' : 'method'}
            </S.action>
          </Actions>
        </S.footer>
      </S.listContent>
      <Details
        open={detailsOpen}
        isMethod={!checkType}
        fetchAllServices={fetchAllServices}
        data={{
          isEdit,
          id,
          name,
          level,
          qualities,
          complexities,
          disciplines,
          setIsEdit,
          ...rest
        }}
      />
    </S.wrapper>
  );
};

export default ListItem;