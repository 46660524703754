import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';

export const ButtonWrapper = styled.div<Props>(
  ({ disabled }) => [
    css`
      ${tw`flex gap-13 items-center cursor-pointer text-22 text-textBlack hover:text-green`}

      path {
        ${tw`fill-black`}
      }

      &:hover path {
        ${tw`fill-green`}
      }

      button {
        ${tw`outline-none font-semibold`}
      }
    `,
    disabled && 
    css`
      ${tw`pointer-events-none text-gray-200 text-opacity-75`}

      path {
        ${tw`fill-gray-200 opacity-75`}
      }
    `
  ]
);


