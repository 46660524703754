import React, { useState, useEffect, useRef } from 'react';
import { DropdownCbOption, SetStateType } from '../../../../types/util.types';
import { Wrapper, Section, Title, Actions } from './StyledService';
import {
  MemberRole,
  SPMemberRole,
  MultipleInputs,
  Qualities,
  AllServices
} from '../../../../store/method-service/types';
import { MultipleSearchInputs } from '../../../../components/Inputs';
import Button from '../../../../components/Button';
import { editServicePackage } from '../../../../store/method-service/actions';
import NameField from '../../../CreateMethod/NameField';
import PhaseLevel from '../../../CreateMethod/MethodLevel';
import MemberField from '../../../CreateService/MemberField';
import ComplexityField from '../../../CreateService/ComplexityField';
import CommentField from '../../../CreateMethod/CommentField';
import { toastUtil } from '../../../../utils/toast.util';

interface Props extends AllServices {
  isEdit: boolean;
  setIsEdit: SetStateType<boolean>;
  fetchAllServices: () => Promise<void>;
}

const Service = ({
  id,
  name: spName,
  level,
  comment: spComment,
  methods,
  isEdit,
  setIsEdit,
  fetchAllServices
}: Props) => {
  const first = useRef(true);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [name, setName] = useState<string>(spName);
  const [selectedLevel, setSelectedLevel] = useState<DropdownCbOption[]>([
    { id: level, name: `.${level.toLowerCase()}` }
  ]);
  const [selectedRoles, setSelectedRoles] = useState<SPMemberRole[]>([]);

  const [comment, setComment] = useState<string>(spComment || '');

  const newMethods = methods?.map((method) => {
    return {
      method,
      methodOptions: []
    };
  });

  const [multipleInputs, setMultipleInputs] = useState<MultipleInputs[]>(
    newMethods || [{ method: null, methodOptions: [] }]
  );

  const isRoleDisabled = multipleInputs.find((f) => f.method?.id);

  const isButtonDisabled = multipleInputs.some(
    (f) => f.method === null || typeof f.method === 'string'
  );

  const [combinedValues, setCombinedValues] = useState({
    selectedRoles: [],
    standard: {
      personDays: 0,
      price: 0
    },
    high: {
      personDays: 0,
      price: 0
    },
    extreme: {
      personDays: 0,
      price: 0
    },
    responsive_design: {
      personDays: 0,
      price: 0
    }
  });

  useEffect(() => {
    const reduce = multipleInputs.reduce(
      (acc: any, current: MultipleInputs) => {
        if (current.method === null || typeof current.method === 'string') {
          return acc;
        }
        current.method?.qualities.forEach((el: Qualities) => {
          acc[el.type.toLowerCase()].personDays += el.personDays;
          acc[el.type.toLowerCase()].price += el.price;
        });

        current.method?.memberRoles.forEach((role: MemberRole) => {
          const roleExists = acc.selectedRoles.find(
            (el: MemberRole) => el.memberRoleId === role.memberRoleId
          );
          if (!roleExists) {
            acc.selectedRoles.push(role);
            return;
          }

          acc.selectedRoles = acc.selectedRoles.map((r: any) => {
            if (r.memberRoleId === role.memberRoleId) {
              return {
                ...r,
                personDays: r.personDays + role.personDays
              };
            }
            return r;
          });
        });
        return acc;
      },
      {
        selectedRoles: [],
        standard: {
          personDays: 0,
          price: 0
        },
        high: {
          personDays: 0,
          price: 0
        },
        extreme: {
          personDays: 0,
          price: 0
        },
        responsive_design: {
          personDays: 0,
          price: 0
        }
      }
    );
    setCombinedValues(reduce);

    // eslint-disable-next-line
  }, [multipleInputs]);

  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }
    setMultipleInputs([]);
    setSelectedRoles([]);
  }, [selectedLevel]);

  useEffect(() => {
    if (!multipleInputs.length) {
      setMultipleInputs([{ method: null, methodOptions: [] }]);
    }
  }, [multipleInputs]);

  const edit = async () => {
    try {
      setSubmitting(true);
      const res = await editServicePackage(
        name,
        selectedLevel,
        multipleInputs,
        comment,
        id
      );
      fetchAllServices();
      setSubmitting(false);
      setIsEdit(false);
      toastUtil('success', res.data.meta.message);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const isDisabled = !name || !selectedLevel.length;
  // !multipleInputs.find((m: any) => m.method?.id);

  const methodLevel = selectedLevel.find((l) => l.id);

  return (
    <Wrapper>
      <Section>
        <Title>Package information</Title>
        <NameField
          value={name}
          setValue={setName}
          maxWidth="35rem"
          label="Package name"
          disabled={!isEdit}
        />
        <PhaseLevel
          selected={selectedLevel}
          onAdd={setSelectedLevel}
          label="Package level"
          disabled={!isEdit}
        />
        <MemberField
          selectedRoles={combinedValues.selectedRoles}
          label="Member role"
          disabled={!isRoleDisabled}
        />
      </Section>
      <Section>
        <Title> Include method</Title>

        <MultipleSearchInputs
          setMultipleInputs={setMultipleInputs}
          multipleInputs={multipleInputs}
          isDisabled={isButtonDisabled}
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          apiVariable={methodLevel?.id}
          isAllDisabled={!isEdit}
        />
      </Section>
      <Section>
        <Title>Complexity</Title>
        <ComplexityField
          complexity={combinedValues.standard}
          title="Standard"
          disableAll
        />
        <ComplexityField
          complexity={combinedValues.high}
          title="High"
          disableAll
        />
        <ComplexityField
          complexity={combinedValues.extreme}
          title="Extreme"
          disableAll
        />
        <ComplexityField
          complexity={combinedValues.responsive_design}
          title="Responsive design"
          disableAll
        />
      </Section>
      <Section>
        <Title>Comment field</Title>
        <CommentField
          setValue={setComment}
          value={comment}
          placeholder="Description"
          maxWidth="100%"
          service
          disabled={!isEdit}
        />
      </Section>
      {isEdit && (
        <Actions>
          <Button secondary onClick={edit} disabled={submitting || isDisabled}>
            Submit
          </Button>
        </Actions>
      )}
    </Wrapper>
  );
};

export default Service;
