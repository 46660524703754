import tw, { styled, css } from 'twin.macro';

export const Wrapper = styled.div(() => [
  css`
    ${tw`mx-25 px-15 pb-40 pt-30 bg-white text-textBlack w-full`};
    max-width: 90vw;
  `
]);

export const Section = styled.div(() => [
  css`
    ${tw`flex flex-wrap items-start xl:px-30`};
  `
]);

export const Title = styled.div(() => [
  css`
    ${tw`w-full mb-25 text-textBlack text-32 leading-36 font-semibold`};
  `
]);

export const Actions = styled.div(() => [
  css`
    ${tw`flex justify-end xl:pr-20`};
  `
]);
