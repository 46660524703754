import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AuthWrapper from '../../components/AuthWrapper';
import LoginForm from './LoginForm';
import { Client } from '../../store/auth/auth.types';
import { isClientAction } from '../../store/auth/auth.actions';
import LinksExpiration from './LinksExpiration';
import useGlobal from '../../store';
import * as S from './login-page';
import Button from '../../components/Button';
import { ReactSVG } from 'react-svg';
import azureAD from '../../images/auth/azure-ad.svg';
import { AzureADOAuth2Context } from '../../providers/azure-ad.oauht2.provider';
import { loginRequest } from '../../config/azure-ad.config';

const Login = () => {
  const [, login] = useGlobal(
    () => null,
    (actions) => actions.login
  );
  let location = useLocation();
  let history = useHistory();
  const token = location.search.split('=')[1];

  const { instance } = useContext(AzureADOAuth2Context);

  const [isClient, setIsClient] = useState<Client | null>(null);
  const [decoding, setDecoding] = useState<boolean>(!!token);
  const [linkType, setLinkType] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    let mounted = true;
    const getUserData = async () => {
      try {
        setDecoding(true);
        const userData = await isClientAction(token);
        if (!!userData.password) {
          login(userData.email, userData.password);
          history.push("/projects");
        }
        setIsClient(userData);
        setDecoding(false);
      } catch (error) {
        setLinkType(error.meta.type);
        setErrorMsg(error.meta.message);
        setIsClient(null);
        setDecoding(false);
      }
    };
    if (token && mounted) {
      getUserData();
      return () => {
        mounted = false;
      };
    }
    
    // eslint-disable-next-line
  }, [token]);

  return (
    <AuthWrapper>
      {linkType ? (
        <LinksExpiration linkType={linkType} errorMsg={errorMsg} />
      ) : (
        <S.options>
          <LoginForm data={{ token, isClient, decoding }} />
          <S.other>
            <p>Other options:</p>
            <Button
              secondary
              onClick={() => { instance?.loginRedirect(loginRequest); }}
            >
              <ReactSVG src={azureAD} />
              Azure AD
            </Button>
          </S.other>
        </S.options>
      )}
    </AuthWrapper>
  );
};

export default Login;
