import * as Yup from 'yup';

export default Yup.object().shape({
  currentPassword: Yup.string().required('Password is required'),
  password: Yup.string()
    .min(10, 'Password has to be longer than 10 characters')
    .required('New Password is required')
    .matches(
      /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])[\w~@#$%^&*+=`|{}:;!.?()[\]-]{10,}$/,
      {
        message:
          'Password must include at least one uppercase letter, at least one lowercase letter, and at least one number'
      }
    ),
  repeatedPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords are not the same')
    .required('Password confirmation is required')
});
