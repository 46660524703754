const typography = {
  hero: `
  font-size: 8rem;
  line-height: 8rem;
  font-weight: bold;
  `,
  title: `
  margin-bottom: 5.6rem;
  font-size: 5.6rem;
  line-height: 5.6rem;
  font-weight: bold;
  `,
  subtitle: `
  margin-bottom: 4rem;
  font-size: 4.8rem;
  line-height: 4.8rem;
  font-weight: bold;
  `,
  sectionTitle: `
  margin-bottom: 3.2rem;
  font-size: 4rem;
  line-height: 4.6rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  `,
  sectionSubtitle: `
  margin-bottom: 2.4rem;
  font-size: 3.2rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 3.6rem;
  `,
  sectionSubtitleSmall: `
  margin-bottom: 2.4rem;
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3.2rem;`,
  lead: `
  font-size: 3.2rem;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 4.4rem;
  `,
  p: `
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 2.8rem;
  `,
  pSmall: `
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 2.2rem;
  `
};

export default typography;
