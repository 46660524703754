import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    ${tw`px-10 md:px-30 py-40`};

    h3 {
      ${tw`text-textBlack font-bold text-48 font-bold tracking-normal `}
    }
  `
]);
