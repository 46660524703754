import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';
import {
  Dropdown,
  Wrapper as actionsWrapper
} from '../../components/Actions/actions-component';
import arrow from '../../images/arrow-down.svg';

export const wrapper = styled.div(() => [
  css`
    ${tw`px-10 md:px-30 py-40`};

    h3 {
      ${tw`text-textBlack font-bold text-48 font-bold tracking-normal `}
    }
  `
]);

export const headerActions = styled.div(() => [
  css`
    ${tw`flex items-center relative ml-auto hover:text-green py-10`};
    ${actionsWrapper} {
      width: 30px;
    }

    span path {
      ${tw`fill-black`}
    }

    &:hover span path {
      ${tw`fill-green`}
    }

    & > div span {
      ${tw`bg-black`}
      -webkit-mask: url('${arrow}');
      mask: url('${arrow}');
      mask-repeat: no-repeat;
      mask-position: center;
      background-image: none;
      transition: transform 0.3s;
    }

    &:hover > div span {
      ${tw`bg-green`}
    }

    ${Dropdown} {
      width: 150px;
      right: 0;
    }

    span {
      ${tw`flex gap-10 items-center mr-20 cursor-pointer text-18 font-semibold pl-20 `}
    }
  `
]);

export const buttonContainer = styled.div<Props>(() => [
  css`
    ${tw`flex flex-col lg:flex-row gap-10`};

    a {
      ${tw`my-10 lg:my-0`}
    }
  `
]);

