import tw, { styled, css } from 'twin.macro';
import { StyledButton } from '../../../components/Button/styles';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div<Props>(() => [
  css`
    ${tw`bg-white pb-30  `}
    max-width: 1504px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  `
]);

export const row = styled.div(() => [
  css`
    ${tw`flex items-center border-b-2 border-gray9`};
    height: 6.4rem;
    > span {
      &:first-child {
        ${tw`border-r-2 border-gray9 pr-5`}
        min-width: 57rem;
      }
    }
  `
]);

export const roleName = styled.span<Props>(
  ({
    theme: {
      typography: { sectionSubtitleSmall }
    }
    , indented }) => [
    css`
      ${sectionSubtitleSmall}
      ${tw`w-full mb-0 text-textBlack p-10`}
    `,
      indented && css` ${tw`pl-20`}`
    ]
)

export const rolePrice = styled(roleName)`
    display: grid;
    grid-template-columns: 1fr 3fr;
`

export const form = styled.form(() => [
  css` ${tw` w-full`} `
]);

export const inputWrapper = styled.div(() => [
  css`
    ${tw`
        grid grid-cols-2 gap-16
        w-full
        mt-30
     `}
  `
]);

export const imgWrap = styled.span(() => [
  css`${tw`flex pl-20`}`
]);

export const rolesActionImage = styled.img(() => [
  css`${tw`cursor-pointer pl-10`}`
]);

export const modalWrapper = styled.div(() => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue w-730 max-w-full p-32`}

    h3 {
      ${tw`text-textBlack text-32 leading-36 font-semibold mb-0`}
    }
    ${StyledButton} {
      ${tw`ml-auto mt-26`}
    }
  `
]);
