import tw, { styled, css } from 'twin.macro';
// import { StyledProps as Props } from '../../types/util.types';

export const wrapper = styled.div(() => [
  css`
    ${tw`mx-30 mt-40 mb-80`};

    h3 {
      ${tw`text-textBlack text-48 leading-none mb-28`};
    }
  `
]);

export const content = styled.div(() => [
  css`
    ${tw`grid grid-cols-1 sm:grid-cols-2 xxl:grid-cols-4 gap-30`};
  `
]);

export const categories = styled.div(() => [
  css`
    ${tw`bg-gray-800 flex items-start border-l-8 border-yellow rounded-4 shadow-discipline pt-27 pl-32 pb-5 pr-5`}
    height: 13rem;

    &:nth-child(2) {
      ${tw`border-pink`}
    }

    &:nth-child(3) {
      ${tw`border-green`}
    }

    &:nth-child(4) {
      ${tw`border-blue`}
    }

    &:nth-child(5) {
      ${tw`border-purple`}
    }

    span {
      ${tw`text-32 leading-36 text-textBlack font-semibold`}
    }

    svg {
      ${tw`mr-18`};
      min-width: 2.8rem;
      max-width: 2.8rem;
      transform: translateY(0.5rem);
      path {
        ${tw`fill-black`}
      }
    }
  `
]);
