import tw, { styled, css } from 'twin.macro';
import { Link } from 'react-router-dom';
import { Dropdown } from '../../../components/Actions/actions-component';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div<Props>(({ listView }) => [
  css`
    ${tw`flex items-center bg-white text-textBlack`};

    ${Dropdown} {
      ${tw`gap-15 p-15`}
      div, a {
        ${tw`w-full text-gray-200 hover:text-green text-left font-semibold cursor-pointer`};
      }
    }
  `,
  !listView &&
    css`
      ${tw`flex-col items-start px-20 py-15`}
      border-radius: 4px;
      box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.15);

      div {
        ${Dropdown} {
          ${tw`
          left-auto
          right-100
          -translate-x-4
          `}
        }
      }
    `,
  listView &&
    css`
      ${tw` items-center flex-wrap px-20 py-15`}

      &:not(:last-child) {
        ${tw`border-b-2 border-gray9`}
      }
    `
]);

export const title = styled(Link)<Props>(({ list }) => [
  css`
    ${tw`text-32 leading-36 font-semibold text-textBlack hover:text-textBlack text-left break-all`}
    
    &:hover {
      ${tw`text-green`}
    }
  `,
  list === 0 && tw`mb-50`
]);

export const footer = styled.div<Props>(({ listView }) => [
  css`
    ${tw`flex items-center`};

    svg {
      ${tw`cursor-pointer mr-3`}
      path {
        ${tw`fill-black`}
      }
    }

    svg:hover {
      path {
        ${tw`fill-green`}
      }
    }
  `,
  !listView &&
    css`
      ${tw`self-end mt-auto`};
    `,
  listView &&
    css`
      ${tw`ml-auto`}
    `
]);

export const reactivateModal = styled.div(() => [
  css`
    ${tw`flex flex-col items-start bg-gray10 border-2 border-darkBlue max-w-full py-32 px-20`}
    width: 48rem;

    p {
      ${tw`text-textBlack text-32 leading-36 font-semibold mb-0`}
    }

    div {
      ${tw`flex justify-end w-full mt-28`}

      button {
        ${tw`first:mr-15`}
      }
    }
  `
]);