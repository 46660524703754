import { FormFieldType } from './../../types/form.types';

interface Values {
  currentPassword: string;
  password: string;
  repeatedPassword: string;
}

export const initialValues: Values = {
  currentPassword: '',
  password: '',
  repeatedPassword: ''
};

export const formModel: FormFieldType[] = [
  {
    name: 'currentPassword',
    type: 'password',
    title: 'Current password',
    line: true
  },
  {
    name: 'password',
    type: 'password',
    title: 'New password*',
    line: true,
    tips: `Your password must be a minimum of ten characters and must include
      at least one uppercase letter, at least one lowercase letter, and at
      least one number.`
  },
  {
    name: 'repeatedPassword',
    type: 'password',
    title: 'Confirm new password'
  }
];
