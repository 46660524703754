import React, { useState, useContext } from 'react';
import {ProjectProgress} from '../../../store/projects/projects.types';
import EventForm from './EventForm';
import Modal from '../../../components/Modal';
import addIcon from '../../../images/add-2.svg';
import { ProgressContext } from '../Progress';

import * as S from './add-event';
import ClickableText from '../../../components/ClickableText';

const AddEvent = () => {
    const {updateProject, project: {duration}} = useContext(ProgressContext);
    
    const [active, setActive] = useState<boolean>(false)

    const showModal = () => {
        setActive(true)
    }

    const onSuccess = (data: ProjectProgress) => {
        updateProject(data)
        setActive(false)
    }

    const closeModal = () => {
        setActive(false)
    }

    return (
        <>
            <S.addEventWrap>
                <ClickableText onClick={showModal} icon={addIcon}>Add Event</ClickableText>
            </S.addEventWrap>
            <Modal isOpen={active} onDismiss={closeModal}>
                <EventForm onSuccess={onSuccess} startDate={duration?.startDate} endDate={duration?.endDate} />
            </Modal>
        </>
    )
}

export default AddEvent;

