import tw, { styled, css } from 'twin.macro';

export const modalWrapper = styled.div(() => [
  css`
    ${tw`flex w-730 bg-white rounded-2 border-white p-32`}
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);
    height: 48rem;

    > img {
      ${tw`absolute w-10 h-10 cursor-pointer`}
      top: 2rem;
      right: 2rem;
    }
  `
]);

export const modalContent = styled.div(() => [
  css`
    ${tw`flex flex-col`}

    h4 {
      ${tw`text-32 font-semibold leading-36 text-textBlack`}
    }

    button {
      ${tw`ml-auto mt-32`}
    }
  `
]);

interface ModalDropProps {
  hasImage: boolean;
}

export const modalDrop = styled.div<ModalDropProps>(({ hasImage }) => [
  css`
    ${tw`relative outline-none flex flex-col justify-center items-center 
    bg-gray6 bg-opacity-15 rounded-8 border-2 
    border-dashed border-darkBlue 
    overflow-hidden
    border-opacity-50`}
    width: 66.6rem;
    min-height: 29.6rem;
    height: 29.6rem;

    p {
      ${tw`text-textBlack text-18 font-semibold leading-28 w-356 text-center mb-45`}
    }
  `,
  hasImage &&
    css`
      ${tw`border-none`}
    `
]);

export const droppedImage = styled.img(() => [
  css`
    ${tw`max-h-full w-full `}
    object-fit: contain;
  `
]);
