import tw, { styled, css } from 'twin.macro';

export const wrapper = styled.div(() => [
  css`
    ${tw`flex flex-col`};

    > img {
      ${tw`w-full mb-30 rounded-4`}
      max-width: 25rem;
    }
  `
]);

export const actions = styled.div(() => [
  css`
    button {
      ${tw`self-start`}
    }

    span {
      ${tw`block w-full mt-3 text-14 leading-22 font-semibold text-textBlack`}
    }
  `
]);
