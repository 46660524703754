import React, { useState, useEffect, useContext } from 'react';
import Button from '../../../components/Button';
import { useDropzone } from 'react-dropzone';
import { ProgressContext } from '../Progress';
import { uploadProjectImage } from '../../../store/projects/projects.actions';
import Modal from '../../../components/Modal';
import PictureCropper from '../../../components/PictureCropper/PictureCropper';
import { ReactComponent as EditIcon } from '../../../images/edit.svg';

import uploadWhite from '../../../images/upload-white.svg';

import * as S from './header-image';
import { convertImageURI } from '../../../utils/images.utils';

const HeaderImage = () => {
  const [projectImagePreview, setProjectImagePreview] = useState<any>(null);
  const [active, setActive] = useState<boolean>(false);
  const {
    project: { id: projectId, imageUrl },
    isMemberRole,
    updateProject,
    project
  } = useContext(ProgressContext);

  const onCropSuccess = async (file: any) => {
    try {
      const data = await uploadProjectImage({ projectId, file });
      updateProject({ ...project, imageUrl: data });
    } catch {
      console.warn('Image upload failed');
    }
  };

  const showModal = () => {
    setActive(true);
  };

  const closeModal = () => {
    setActive(false);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/png, image/jpeg',
    noClick: true,
    onDropRejected: () => {
      setProjectImagePreview(null);
    },
    onDropAccepted: (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result;
        setProjectImagePreview(url);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  });

  useEffect(() => {
    if (projectImagePreview) showModal();
  }, [projectImagePreview]);

  useEffect(() => {
    if (!active) setProjectImagePreview(null);
  }, [active]);

  return (
    <>
      {imageUrl ? (
        <S.preview {...getRootProps({ refKey: 'innerRef' })}>
          <img src={convertImageURI(imageUrl)} alt="" />
          {!isMemberRole && <EditIcon onClick={showModal} />}
          <input {...getInputProps()} />
        </S.preview>
      ) : (
        <S.droparea {...getRootProps({ refKey: 'innerRef' })} disabled={isMemberRole}>
          <input {...getInputProps()} />
          <S.actions>
            <p>Drop project image here, or select a file from your computer</p>
            <Button secondary small onClick={open} disabled={isMemberRole}>
              <img src={uploadWhite} alt="" />
              Upload Image
            </Button>
            <span>JPG or PNG, at least 256px.</span>
          </S.actions>
        </S.droparea>
      )}
      <Modal isOpen={active} onDismiss={closeModal}>
        <PictureCropper
          title="Upload Image"
          description="Drop project image here, or select a file from your computer"
          uploadDefinition="Upload Image"
          aspect={16 / 3}
          onClose={closeModal}
          selectedImage={projectImagePreview}
          onCropSuccess={onCropSuccess}
        />
      </Modal>
    </>
  );
};

export default HeaderImage;
