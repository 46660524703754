import React from 'react';
import Button from '../../components/Button';

import MemberForm from './MemberForm';
import {
  HeaderWrapper,
  HeaderTop,
  HeaderBottom
} from '../../components/Header/StyledHeader';
import * as S from './add-member';
// import TopBar from '../../components/TopBar';
import { routePaths } from '../../utils/routes.util';

const AddMember = () => {
  return (
    <>
      {/* <TopBar
        setSearch={setName}
        placeholder="Search by title or description"
      /> */}
      <HeaderWrapper>
        <HeaderTop>
          <h2>New member</h2>
          <Button small secondary to={routePaths.MEMBERS}>
            Back to members
          </Button>
        </HeaderTop>
        <HeaderBottom>
          <p>Please fill in all fields.</p>
        </HeaderBottom>
      </HeaderWrapper>
      <S.wrapper>
        <MemberForm />
      </S.wrapper>
    </>
  );
};

export default AddMember;
