import React, { ChangeEvent } from 'react';
import TextInput from '../../../components/Inputs/TextInput';
import { validatePd } from '../../../utils/personDays.utils';
import { SetStateType } from '../../../types/util.types';
import * as S from './number-field';

interface Props {
  title: string;
  label: string;
  value: string;
  setValue: SetStateType<string>;
  minValidNumber: number;
  disabled?: boolean;
}

const NumberField = ({ title, label, value, setValue, disabled }: Props) => {
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (!validatePd(evt.target.value)) return;
    setValue(evt.target.value);
  };

  const handleBlur = (evt: ChangeEvent<HTMLInputElement>) => {
    let val = evt.target.value;
    if (val.endsWith('.')) setValue(val.substring(0, val.length - 1));
    if (!val) setValue('');
  };

  return (
    <S.wrapper>
      <S.title>{title}</S.title>
      <TextInput
        label={label}
        name={title + label}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      />
    </S.wrapper>
  );
};

export default NumberField;
