import { phaseItemModalsAction, phaseItemModalsState } from "./phase-item.types";

export const phaseItemModalsReducer = (state: phaseItemModalsState, action: phaseItemModalsAction) => {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        [action.payload as string]: true
      }
    case 'CLOSE':
      return { 
        sp: false,
        method: false,
        ct: false
      }
    default:
      return state;
  }
};