import tw, { styled, css } from 'twin.macro';
import { Dropdown } from '../../../components/Actions/actions-component';
import { StyledProps as Props } from '../../../types/util.types';

export const wrapper = styled.div<Props>(
  ({
    theme: {
      typography: { sectionSubtitleSmall }
    }
  }) => [
    css`
      ${sectionSubtitleSmall}
      ${tw`flex flex-col items-center  bg-white text-textBlack m-0 items-center mr-10 border-b-2 border-gray9 w-full`};
      min-height: 6.4rem;
      ${Dropdown} {
        ${tw`px-0 py-15 text-22`}
      }
    `
  ]
);
export const listContent = styled.div<Props>(
  ({
    theme: {
      typography: { sectionSubtitleSmall }
    }
  }) => [
    css`
      ${sectionSubtitleSmall}
      ${tw`flex items-center w-full bg-white text-textBlack m-0 items-center border-b-2 border-gray9 `};

      &:last-child {
        ${tw`border-0`}
      }
      > div {
        &:not(:first-child) {
          ${tw`w-full pl-15 text-darkBlue5 pr-30`}
          min-width:25rem
        }
        &:last-child {
          ${tw`w-full pr-20`}
          min-width:13rem
        }
      }
    `
  ]
);

export const title = styled.p<Props>(
  ({
    theme: {
      typography: { sectionSubtitleSmall }
    }
  }) => [
    css`
      ${sectionSubtitleSmall}
      ${tw` m-0 ml-40 text-left w-full py-15`}

    span {
        ${tw`ml-3 text-whiteBlue`}
      }
    `
  ]
);

export const footer = styled.div(() => [
  css`
    ${tw`flex items-center justify-end relative ml-auto mr-10`};

    img {
      ${tw`cursor-pointer mr-3`}
    }
  `
]);

export const action = styled.div(() => [
  css`
    ${tw`w-full p-15 text-gray-200 hover:text-green text-left font-semibold cursor-pointer`};
    transition: all 0.3s;
  `
]);

export const discipline = styled.div<Props>(
  ({ serviceDesign, industrialDesign, softwareDevelopment, uxDesign, professionalServices }) => [
    css`
      ${tw`flex absolute left-0 h-full cursor-help`};
      div {
        display: none;
        width: 0.8rem;
        &:first-child {
          ${tw`hidden bg-pink`}
        }
        &:nth-child(2) {
          ${tw`hidden bg-green`}
        }
        &:nth-child(3) {
          ${tw`hidden bg-yellow`}
        }
        &:nth-child(4) {
          ${tw`hidden bg-blue`}
        }
        &:nth-child(5) {
          ${tw`hidden bg-purple`}
        }
      }
    `,

    uxDesign &&
      css`
        div {
          &:first-child {
            ${tw`block `}
          }
        }
      `,
    industrialDesign &&
      css`
        div {
          &:nth-child(2) {
            ${tw`block `}
          }
        }
      `,

    serviceDesign &&
      css`
        div {
          &:nth-child(3) {
            ${tw`block `}
          }
        }
      `,
    softwareDevelopment &&
      css`
        div {
          &:nth-child(4) {
            ${tw`block `}
          }
        }
      `,
    professionalServices &&
      css`
        div {
          &:nth-child(5) {
            ${tw`block `}
          }
        }
      `
  ]
);

export const disciplineAndTitle = styled.div(() => [
  css`
    ${tw`flex items-center w-full relative`};
    min-width: 59rem;
  `
]);

export const methodSp = styled.div<Props>(({ sp }) => [
  css`
    ${tw`flex justify-center text-textBlack`};

    min-width: 40px;
  `,
  sp
    ? css`
        ${tw`bg-green6`}
      `
    : css`
        ${tw`bg-gray2`}
      `
]);

export const svg = styled.div<Props>(({ detailsOpen }) => [
  css`
    ${tw`cursor-pointer`}
    transform: ${detailsOpen && 'rotate(180deg)'};
    transition: all 0.5s;
  `
]);

export const colorInfo = styled.div<Props>(
  ({
    open,
    serviceDesign,
    industrialDesign,
    softwareDevelopment,
    uxDesign,
    professionalServices
  }) => [
    css`
      ${tw`hidden flex-col absolute min-h-full justify-center `};
      top: 50%;
      transform: translateY(-50%);
      left: 4rem;
      z-index: 10;
      background-color: white;
      border: 1px solid black;
      min-width: 20rem;

      > div {
        ${tw`text-18 font-semibold px-10 py-5 leading-28 tracking-wider`};

        &:before {
          ${tw`block h-12 mr-15 `}
          content: '';
          width: 0.4rem;
        }

        &:first-child {
          ${tw`hidden`}
          &:before {
            ${tw`hidden bg-pink`}
          }
        }
        &:nth-child(2) {
          ${tw`hidden`}
          &:before {
            ${tw`hidden bg-green`}
          }
        }
        &:nth-child(3) {
          ${tw`hidden`}
          &:before {
            ${tw`hidden bg-yellow`}
          }
        }
        &:nth-child(4) {
          ${tw`hidden`}
          &:before {
            ${tw`hidden bg-blue`}
          }
        }
        &:nth-child(5) {
          ${tw`hidden`}
          &:before {
            ${tw`hidden bg-purple`}
          }
        }
      }
    `,
    open &&
      css`
        ${tw`flex`}
      `,
    uxDesign &&
      css`
        div {
          &:first-child {
            ${tw`flex `}
            &:before {
              ${tw`flex`}
            }
          }
        }
      `,
    industrialDesign &&
      css`
        div {
          &:nth-child(2) {
            ${tw`flex `};
            &:before {
              ${tw`flex`}
            }
          }
        }
      `,

    serviceDesign &&
      css`
        div {
          &:nth-child(3) {
            ${tw`flex `};
            &:before {
              ${tw`flex`}
            }
          }
        }
      `,
    softwareDevelopment &&
      css`
        div {
          &:nth-child(4) {
            ${tw`flex `};
            &:before {
              ${tw`flex`}
            }
          }
        }
      `,
    professionalServices &&
      css`
        div {
          &:nth-child(5) {
            ${tw`flex `};
            &:before {
              ${tw`flex`}
            }
          }
        }
      `
  ]
);
