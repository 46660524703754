import React from 'react';
import { ReactComponent as Icon } from '../../../images/user-outline.svg';
import {
  ProjectSummaryPhaseType,
  ProjectSummaryMethodServiceType,
  ProjectClientType
} from '../../../store/projects/projects.types';

import * as S from './summary-phases';

interface Props {
  data: ProjectSummaryPhaseType[];
  client: ProjectClientType | null;
}

const SummaryPhases = ({ data, client }: Props) => {
  return (
    <S.wrapper>
      <h2>
        Dear {client?.firstName} {client?.lastName}, this is your personalized
        project example
      </h2>
      <h3>Project Phases with Service Packages and Deliverables</h3>
      <S.phaseList>
        {data.map((p: ProjectSummaryPhaseType, i: number) =>
          i > 6 ? null : (
            <div>
              <S.phaseItem>
                <S.serviceTitle>
                  <S.phaseTitle>{p.name}</S.phaseTitle>
                  <S.deliverableTitle>Deliverables</S.deliverableTitle>
                </S.serviceTitle>
                {p.methodsServicePackages.map(
                  (s: ProjectSummaryMethodServiceType) => (
                    <S.serviceItem key={s.id}>
                      <p>
                        <S.methodColors></S.methodColors>
                        {s.name}
                        <S.personDayItem>
                          {s.personDays.toFixed(2)} pd
                        </S.personDayItem>
                      </p>
                      <S.deliverableItem>
                        {s.deliverables
                          .map((d) => d.name)
                          .filter((name) => name)
                          .join('; ')}
                      </S.deliverableItem>
                    </S.serviceItem>
                  )
                )}
                <S.itemFooter>
                  <Icon />
                  {p.methodsServicePackages
                    .reduce(
                      (acc: number, el: ProjectSummaryMethodServiceType) =>
                        (acc += el.personDays),
                      0
                    )
                    .toFixed(2)}{' '}
                  pd
                </S.itemFooter>
              </S.phaseItem>
            </div>
          )
        )}
      </S.phaseList>
    </S.wrapper>
  );
};

export default SummaryPhases;
