import React from 'react';
import {
  HeaderWrapper,
  HeaderTop,
  HeaderBottom
} from '../../components/Header/StyledHeader';
import Button from '../../components/Button';
import EditForm from './EditForm';

const EditProject = () => {
  return (
    <>
      {/* <TopBar placeholder="Search by title or description" /> */}
      <HeaderWrapper>
        <HeaderTop>
          <h2>Edit Project Data</h2>
          <Button small secondary to="/">
            Back to projects
          </Button>
        </HeaderTop>
        <HeaderBottom>
          <p>Please fill in all fields.</p>
        </HeaderBottom>
      </HeaderWrapper>
      <EditForm />
    </>
  );
};

export default EditProject;
