import tw, { styled, css } from 'twin.macro';

export const summaryWrapper = styled.div(() => [
  css`
    ${tw`relative max-w-8xl mx-auto print:max-w-none print:w-full
    bg-gray12
    `};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);

    @media print {
      box-shadow: none;
    }

    @media screen and (max-width: 575px) and (min-width: 320px) {
      width: 37rem;
    }
  `
]);

export const summaryButtons = styled.div(() => [
  css`
    ${tw`relative w-full sm:w-full mx-auto sm:px-30 print:hidden mt-30 sm:mt-40 flex justify-end`};

    @media screen and (max-width: 575px) and (min-width: 320px) {
      width: 37rem;
    }
  `
]);
