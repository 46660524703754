import React from 'react';
import * as S from './noauth';

const Unauthorized = () => {
    return (
        <S.wrapper>
           Unauthorized
        </S.wrapper>
    )
}

export default Unauthorized;